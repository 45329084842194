import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NbAuthService, NbTokenService } from '@nebular/auth';
import { NbDialogRef } from '@nebular/theme';

@Component({
    selector: 'token-expiration-alert-modal',
    templateUrl: './token-expiration-alert-modal.component.html',
    styleUrls: ['./token-expiration-alert-modal.component.scss'],
})
export class TokenExpirationAlertModalComponent implements OnInit {
    countdownTime: Date;

    countdownSeconds: number;
    timerDisplay: string = '5:00';

    interval: any;

    initialTitle: string;
    constructor(protected dialogRef: NbDialogRef<boolean>, private authService: NbAuthService, private tokenService: NbTokenService, private router: Router) {}

    ngOnInit(): void {
        this.initialTitle = document.title;
        this.countdownSeconds = 300;
        this.startCountdown();
    }

    refresh() {
        clearInterval(this.interval);
        document.title = this.initialTitle;
        if (this.countdownSeconds == 0) {
            this.dialogRef.close(false);
            return;
        }
        
        this.dialogRef.close(true);
        return;
    }

    dismissAndLogout() {
        this.dialogRef.close(false);
    }

    startCountdown() {
        this.interval = setInterval(() => {
            if (this.countdownSeconds > 0) {
                this.countdownSeconds = this.countdownSeconds - 1;
                this.timerDisplay = this.transformSeconds(this.countdownSeconds);

                if (this.countdownSeconds % 3 == 0) {
                    document.title = 'Sesijas beigas! ' + this.timerDisplay;
                } else {
                    document.title = this.initialTitle;
                }
            } else {
                document.title = this.initialTitle;
                this.logout();
            }
        }, 1000);
    }

    transformSeconds(countdownValue: number) {
        const minutes: number = Math.floor(countdownValue / 60);
        const seconds: number = countdownValue - minutes * 60;

        var secondsToDisplay;
        if (seconds < 10) {
            secondsToDisplay = seconds == 0 ? '00' : '0' + seconds;
        } else {
            secondsToDisplay = seconds;
        }

        return minutes + ':' + secondsToDisplay;
    }

    clearTitleInterval() {
        clearInterval(this.interval);
    }

    async logout() {
        this.clearTitleInterval();
        const token = await this.tokenService.get().toPromise();
        this.authService.logout(token.getOwnerStrategyName()).subscribe(() => {
            this.router.navigate(['auth/login']);
        });
    }
}

import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { LocalDataSource } from 'ng2-smart-table';

import { getDeepFromObject } from './helpers';
import { SubmissionFilter } from '@amdb/drone/shared/models/submission-filter.model';
import { ExportHistoryFilter } from '@amdb/drone/shared/models/export-history-filter.model';
import { RegionFilters } from '@amdb/drone/shared/models/region-filter.model';
import { RestrictionConditionFilter } from '@amdb/drone/shared/models/restriction-condition-filter.model';


export class AmdbServerDataSource extends LocalDataSource {

    customFilterParams: HttpParams;

    protected conf: any;
    protected onStartingChangeSource = new Subject<any>();
    protected lastRequestCount: number = 0;

    constructor(protected http: HttpClient, conf: any | {} = {}) {
        super();

        this.conf = conf;

        if (!this.conf.endPoint) {
            throw new Error('At least endPoint must be specified as a configuration of the server data source.');
        }
    }

    setCustomSubmissionsFilter(submissionFilter: SubmissionFilter): void {
        this.customFilterParams = new HttpParams();
        this.customFilterParams = this.customFilterParams.append('onlyUnapproved', (!!submissionFilter.onlyUnapproved).toString());
        this.customFilterParams = this.customFilterParams.append('onlyAutoSubmitted',(!!submissionFilter.onlyAutoSubmitted).toString());
        this.customFilterParams = this.customFilterParams.append('onlyFromBgkis',(!!submissionFilter.onlyFromBgkis).toString());
        this.customFilterParams = this.customFilterParams.append('onlyFromDrz',(!!submissionFilter.onlyFromDrz).toString());
        this.customFilterParams = this.customFilterParams.append('inJson',(!!submissionFilter.inJson).toString());
        this.customFilterParams = this.customFilterParams.append('notInJson',(!!submissionFilter.notInJson).toString());
        if (submissionFilter.name) {
            this.customFilterParams = this.customFilterParams.append('name', (submissionFilter.name).toString());
        }
        if (submissionFilter.identifier) {
            this.customFilterParams = this.customFilterParams.append('identifier', (submissionFilter.identifier).toString());
        }
        if (submissionFilter.description) {
            this.customFilterParams = this.customFilterParams.append('description', (submissionFilter.description).toString());
        }
        if (submissionFilter.caaRestrictionAreaCode) {
            this.customFilterParams = this.customFilterParams.append('caaRestrictionAreaCode', (submissionFilter.caaRestrictionAreaCode).toString());
        }
    }

    setCustomExportHistoryFilter(exportHistoryFilter: ExportHistoryFilter): void {
        this.customFilterParams = new HttpParams();
        this.customFilterParams = this.customFilterParams.append('onlyDynamic', (!!exportHistoryFilter.onlyDynamic).toString());
        this.customFilterParams = this.customFilterParams.append('onlyStatic', (!!exportHistoryFilter.onlyStatic).toString());
        this.customFilterParams = this.customFilterParams.append('onlyValid', (!!exportHistoryFilter.onlyValid).toString());
        if(exportHistoryFilter.fileName) {
            this.customFilterParams = this.customFilterParams.append('fileName', (exportHistoryFilter.fileName).toString());
        }
        if(exportHistoryFilter.filterDate) {
            this.customFilterParams = this.customFilterParams.append('filterDate', (exportHistoryFilter.filterDate.toLocaleString()));
        }
    }

    setCustomRegionFilter(regionFilter: RegionFilters): void {
        this.customFilterParams = new HttpParams();
        this.customFilterParams = this.customFilterParams.append('name', (regionFilter.name).toString());
    }

    setCustomRestrictionConditionFilter(restrictionConditionFilter: RestrictionConditionFilter): void {
        this.customFilterParams = new HttpParams();
        this.customFilterParams = this.customFilterParams.append('enabled', restrictionConditionFilter.enabled);
        this.customFilterParams = this.customFilterParams.append('onlyParent', restrictionConditionFilter.onlyParent);
    }

    onStartingChange(): Observable<any> {
        return this.onStartingChangeSource.asObservable();
    }

    count(): number {
        return this.lastRequestCount;
    }

    getElements(): Promise<any> {
        this.onStartingChangeSource.next({});
        return this.requestElements()
            .pipe(map(res => {
                this.lastRequestCount = this.extractTotalFromResponse(res);
                this.data = this.extractDataFromResponse(res);
                return this.data;
            })).toPromise();
    }

    /**
     * Extracts array of data from server response
     * @param res
     * @returns {any}
     */
    protected extractDataFromResponse(res: any): Array<any> {
        const rawData = res.body;
        const data = !!this.conf.dataKey ? getDeepFromObject(rawData, this.conf.dataKey, []) : rawData;

        if (data instanceof Array) {
            return data;
        }

        throw new Error(`Data must be an array.
    Please check that data extracted from the server response by the key '${this.conf.dataKey}' exists and is array.`);
    }

    /**
     * Extracts total rows count from the server response
     * Looks for the count in the heders first, then in the response body
     * @param res
     * @returns {any}
     */
    protected extractTotalFromResponse(res: any): number {
        if (res.headers.has(this.conf.totalKey)) {
            return +res.headers.get(this.conf.totalKey);
        } else {
            const rawData = res.body;
            return getDeepFromObject(rawData, this.conf.totalKey, 0);
        }
    }

    protected requestElements(): Observable<any> {
        const httpParams = this.createRequesParams();
        return this.http.get(this.conf.endPoint, { params: httpParams, observe: 'response' });
    }

    protected createRequesParams(): HttpParams {
        let httpParams = new HttpParams();

        httpParams = this.addSortRequestParams(httpParams);
        httpParams = this.addFilterRequestParams(httpParams);
        httpParams = this.addCustomFilterParams(httpParams);
        return this.addPagerRequestParams(httpParams);
    }

    protected addSortRequestParams(httpParams: HttpParams): HttpParams {
        if (this.sortConf) {
            this.sortConf.forEach((fieldConf) => {
                httpParams = httpParams.set(this.conf.sortFieldKey, fieldConf.field);
                httpParams = httpParams.set(this.conf.sortDirKey, fieldConf.direction.toUpperCase());
            });
        }

        return httpParams;
    }

    protected addFilterRequestParams(httpParams: HttpParams): HttpParams {

        if (this.filterConf.filters) {
            this.filterConf.filters.forEach((fieldConf: any) => {
                if (fieldConf['search']) {
                    httpParams = httpParams.set(this.conf.filterFieldKey.replace('#field#', fieldConf['field']), fieldConf['search']);
                }
            });
        }

        return httpParams;
    }

    protected addCustomFilterParams(httpParams: HttpParams): HttpParams {
        if (!this.customFilterParams) {
            return httpParams;
        }
        this.customFilterParams.keys().forEach(key => {
            const val = this.customFilterParams.get(key);
            httpParams = httpParams.set(key, val);
        });
        return httpParams;
    }

    protected addPagerRequestParams(httpParams: HttpParams): HttpParams {

        if (this.pagingConf && this.pagingConf['page'] && this.pagingConf['perPage']) {
            httpParams = httpParams.set(this.conf.pagerPageKey, this.pagingConf['page']);
            httpParams = httpParams.set(this.conf.pagerLimitKey, this.pagingConf['perPage']);
        }

        return httpParams;
    }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { User, CommandResult } from '@amdb/drone/shared/models';

@Injectable({ providedIn: 'root' })
export class UsersService {
  constructor(private httpClient: HttpClient) { }

  getAllUsers(): Promise<User[]> {
    return this.httpClient.get<User[]>('/api/v1/admin/users').toPromise();
  }

  toggleDisabled(userId: number, status: boolean): Promise<CommandResult<boolean>> {
    if (status) {
      return this.httpClient.post<CommandResult<boolean>>(`/api/v1/admin/users/${userId}/enable`, null).toPromise();
    }
    return this.httpClient.post<CommandResult<boolean>>(`/api/v1/admin/users/${userId}/disable`, null).toPromise();
  }

  toggleIsAuthorityAdmin(userId: number, status: boolean): Promise<CommandResult<boolean>> {
    if (status) {
      return this.httpClient.post<CommandResult<boolean>>(`/api/v1/admin/users/${userId}/authority-admin`, null).toPromise();
    }
    return this.httpClient.post<CommandResult<boolean>>(`/api/v1/admin/users/${userId}/not-authority-admin`, null).toPromise();
  }

  toggleIsAutoSubmitter(userId: number, status: boolean): Promise<CommandResult<boolean>> {
    if(status) {
      return this.httpClient.post<CommandResult<boolean>>(`/api/v1/admin/users/${userId}/set-auto-submitter`, null).toPromise();
    }
    return this.httpClient.post<CommandResult<boolean>>(`/api/v1/admin/users/${userId}/unset-auto-submitter`, null).toPromise();
  }

  toggleIsSubmitter(userId: number, status: boolean): Promise<CommandResult<boolean>> {
    if(status) {
      return this.httpClient.post<CommandResult<boolean>>(`/api/v1/admin/users/${userId}/set-submitter`, null).toPromise();
    }
    return this.httpClient.post<CommandResult<boolean>>(`/api/v1/admin/users/${userId}/unset-submitter`, null).toPromise();
  }

  toggleExcludeFromMailing(userId: number, status: boolean): Promise<CommandResult<boolean>> {
    if (status) {
      return this.httpClient.post<CommandResult<boolean>>(`/api/v1/admin/users/${userId}/exclude-from-mailing`, null).toPromise();
    }
    return this.httpClient.post<CommandResult<boolean>>(`/api/v1/admin/users/${userId}/include-in-mailing`, null).toPromise();
  }

  toggleGetMeesageNotifications(userId: number, status: boolean): Promise<CommandResult<boolean>> {
    if (status) {
      return this.httpClient.post<CommandResult<boolean>>(`/api/v1/admin/users/${userId}/get-meesage-notifications`, null).toPromise();
    }
    return this.httpClient.post<CommandResult<boolean>>(`/api/v1/admin/users/${userId}/dont-get-meesage-notifications`, null).toPromise();
  }

  resendInvitation(userId: number): Promise<CommandResult<boolean>> {
    return this.httpClient.post<CommandResult<boolean>>(`/api/v1/admin/users/${userId}/resend-invitation`, null).toPromise();
  }

  editUserInfo(user: User): Promise<CommandResult<boolean>> {
    return this.httpClient.post<CommandResult<boolean>>(`/api/v1/admin/users/${user.id}/edit-info`, user).toPromise();
  }
}

import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Ng2SmartTableModule } from 'ng2-smart-table';

import { OneColumnLayoutComponent } from './layouts/one-column.layout';
import { ThemeModule } from '@amdb/drone/@theme/theme.module';
import { UserListComponent } from './components/user-list/user-list.component';
import { ClassifierPipe, ErrorCodePipe } from './pipes';
import { UpsertAuthorityComponent, MapComponent } from './components';
import { AuthorityContactsComponent } from './components/authority-contacts/authority-contacts.component';
import { DatesValidatorDirective } from './directives/dates-validator.directive';
import { MaxValueDirective } from './directives/max-value.directive';
import { MinValueDirective } from './directives/min-value.directive';
import { NextActionsModalComponent } from './components/next-actions-modal/next-actions-modal.component';
import { DmsCoordValidatorDirective } from './directives/dms-coord-validator.directive';
import { StartEndTimeValidatorDirective } from './directives/start-end-time-validator.directive';
import { AirspaceVolumeLimitsDirective } from './directives/airspace-volume-limits.directive';
import { InfoDialogComponent } from './components/info-dialog/info-dialog.component';
import { ExportHistoryComponent } from './components/export-history/export-history.component';
import { ExportHistoryListComponent } from './components/export-history/export-history-list/export-history-list.component';
import { ExportHistoryActionsComponent } from './components/export-history/export-history-actions/export-history-actions.component';
import { TokenExpirationAlertModalComponent } from './components/token-expiration-alert-modal/token-expiration-alert-modal.component';
import { StartEndEventValidatorDirective } from './directives/start-end-event-validator.directive';

const COMPONENTS = [
    OneColumnLayoutComponent,
    UserListComponent,
    AuthorityContactsComponent,
    ClassifierPipe,
    ErrorCodePipe,
    UpsertAuthorityComponent,
    MapComponent,
    AuthorityContactsComponent,
    NextActionsModalComponent,
    InfoDialogComponent,
    ExportHistoryComponent,
    ExportHistoryListComponent,
    ExportHistoryActionsComponent
];

const DIRECTIVES = [
    DatesValidatorDirective,
    MaxValueDirective,
    MinValueDirective,
    DmsCoordValidatorDirective,
    StartEndTimeValidatorDirective,
    StartEndEventValidatorDirective,
    AirspaceVolumeLimitsDirective
];

const MODULES = [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    Ng2SmartTableModule
];

@NgModule({
    imports: [
        ThemeModule,
        ...MODULES
    ],
    exports: [
        ...MODULES,
        ...COMPONENTS,
        ...DIRECTIVES
    ],
    declarations: [
        ...COMPONENTS,
        ...DIRECTIVES,
        TokenExpirationAlertModalComponent
    ],
})
export class SharedModule { }

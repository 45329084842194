import { Component, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'to-fly-authority-edit-confirm-modal',
  templateUrl: './to-fly-authority-edit-confirm-modal.component.html',
  styleUrls: ['./to-fly-authority-edit-confirm-modal.component.scss']
})
export class ToFlyAuthorityEditConfirmModalComponent implements OnInit {

  constructor(protected ref: NbDialogRef<ToFlyAuthorityEditConfirmModalComponent>) { }

  ngOnInit(): void {
  }

  submit() {
    this.ref.close(true);
  }

  dismiss() {
    this.ref.close(false);
  }

}

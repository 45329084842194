export interface Classifier {
    id: number;
    parentId: number;
    enabled: boolean;
    name: string;
    description: string;
}

export enum ClassifierType {
    authorityRole = 1,
    groupTypes = 10,
}

export enum AuthorityRoles {
    Originator = 101,
    Importer = 102,
    Publisher = 103,
}

export enum UomDimensionTypes {
    M = 1201,
    FT = 1202,
}

export enum AuthorityPurposes {
    Authorization = 1401,
    Notification = 1402,
    Information = 1403,
}

export enum RestrictionTypes {
    Prohibited = 301,
    ReqAuthorisation = 302,
    Conditional = 303,
    NoRestriction = 304,
}

export enum YesNoType {
    Yes = 401,
    No = 402,
}

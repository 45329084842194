import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NbAuthModule, NbPasswordAuthStrategy, NbAuthJWTToken, NB_AUTH_TOKEN_INTERCEPTOR_FILTER } from '@nebular/auth';
import { NbSecurityModule, NbRoleProvider } from '@nebular/security';

import { throwIfAlreadyLoaded } from './module-import-guard';
import { tokenInterceptionFilter } from './security/tokenInterceptionFilter';
import { RoleProviderService } from './security/role-provider.service';
import { AmdbModules, AmdbActions } from './amdb-modules.model';

export const NB_CORE_PROVIDERS = [
    ...NbAuthModule.forRoot({
        strategies: [
            NbPasswordAuthStrategy.setup({
                name: 'email',
                token: {
                    class: NbAuthJWTToken,
                    key: 'data.token'
                },
                baseEndpoint: '/api/v1/auth',
                login: {
                    endpoint: '/login',
                    redirect: {
                        success: null
                    },
                    defaultErrors: ['Autorizācijas kļūda! Lūdzu veiciet autorizācijas procesu no jauna, ievadot lietotāja vārdu un paroli'],
                },
                register: {
                    endpoint: '/register',
                    redirect: {
                        success: '/auth/registration-confirmation-sent',
                        failure: null,
                    }
                },
                logout: {
                    endpoint: '/sign-out',
                    redirect: {
                        success: '/auth/login'
                    }
                },
                requestPass: {
                    endpoint: '/request-pass',
                    redirect: {
                        success: null
                    },
                    defaultMessages: ['Saite paroles atjaunošanai ir nosūtīta uz Jūsu e-pasta adresi'],
                },
                resetPass: {
                    endpoint: '/reset-pass',
                    defaultMessages: ['Parole veiksmīgi nomainīta'],
                },
                refreshToken: {
                    endpoint: '/refresh-token'
                },
                errors: {
                    key: 'errors',
                }
            }),
        ],
        forms: {
            register: {
                terms: true
            },
            requestPassword: {
                redirectDelay: 500,
                strategy: 'email',
                showMessages: {
                    success: true,
                    error: true,
                }
            },
            validation: {
                password: {
                    minLength: 6,
                    maxLength: 50
                }
            }
        },
    }).providers,

    NbSecurityModule.forRoot({
        accessControl: {
            anyUser: {
                view: [AmdbModules.Submissions]
            },
            superAdmin: {
                view: [AmdbModules.AdminUsers, AmdbModules.AdminAuthorities, AmdbModules.AdminClassifiers, AmdbModules.AdminLogs, AmdbModules.FailedRequests, AmdbModules.AdminHelpFiles, AmdbModules.AdminExportHistory, AmdbModules.DataGroups, AmdbModules.RestrictionConditions]
            },
            authorityAdmin: {
                parent: 'anyUser',
                view: [AmdbModules.AuthorityProfile]
            },
            exportHistoryViewer: {
                parent: 'anyUser',
                view: [AmdbModules.ExportHistory]
            },
            originator: {
                parent: 'anyUser',
                view: [/*AmdbModules.Messages*/],
              action: [/*AmdbActions.Submit,*/ AmdbActions.StartMessageThread]  // SUBMIT action commented out for now, might be needed to re-add later
            },
            importer: {
                parent: 'anyUser',
                view: [AmdbModules.Regions, /*AmdbModules.Messages*/],
                action: [AmdbActions.ApproveImport, AmdbActions.DeclineImport, AmdbActions.Process, AmdbActions.CloseMessageThread]
            },
            publisher: {
                parent: 'anyUser',
                action: [AmdbActions.ApprovePublish, AmdbActions.DeclinePublish, AmdbActions.Process]
            }
        },
    }).providers,
    {
        provide: NbRoleProvider, useClass: RoleProviderService
    },
    {
        provide: NB_AUTH_TOKEN_INTERCEPTOR_FILTER,
        useValue: tokenInterceptionFilter
    }
];

@NgModule({
    imports: [
        CommonModule,
    ],
    exports: [
        NbAuthModule
    ],
    declarations: []
})
export class CoreModule {
    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
        throwIfAlreadyLoaded(parentModule, 'CoreModule');
    }

    static forRoot(): ModuleWithProviders<CoreModule> {
        return <ModuleWithProviders<CoreModule>>{
            ngModule: CoreModule,
            providers: [
                ...NB_CORE_PROVIDERS,
            ],
        };
    }
}

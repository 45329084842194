import { TokenExpirationAlertModalComponent } from '@amdb/drone/shared/components/token-expiration-alert-modal/token-expiration-alert-modal.component';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NbAuthService, NbTokenService } from '@nebular/auth';
import { NbDialogRef, NbDialogService } from '@nebular/theme';

@Injectable({
    providedIn: 'root',
})
@Injectable()
export class TokenExpirationAlertService {
    secondsElapsed = 0;
    interval: any;

    tokenAlertDialog: NbDialogRef<TokenExpirationAlertModalComponent>;

    constructor(private tokenService: NbTokenService, private dialogService: NbDialogService, private authService: NbAuthService, private router: Router) {}

    async start() {
        // make first check, so token is being checked on every reload
        if (!window.location.href.includes('auth')) {
            const token = await this.tokenService.get().toPromise();
            this.checkToken(token);
        }

        // set token check to repeat every 10 seconds
        this.interval = setInterval(async () => {
            const token = await this.tokenService.get().toPromise();
            this.checkToken(token);
        }, 10000);
    }

    checkToken(token) {
        if (!window.location.href.includes('auth')) {
            if (!this.checkTokenExpired(token)) {
                // if token has expired
                clearInterval(this.interval);
                this.tokenAlertDialog = this.dialogService.open(TokenExpirationAlertModalComponent, { hasBackdrop: true, closeOnBackdropClick: false, closeOnEsc: false });
                this.tokenAlertDialog.onClose.subscribe(refreshToken => {
                    if (refreshToken) {
                        // do the token reload, restart the token check
                        this.authService.refreshToken(token.getOwnerStrategyName(), token).subscribe(response => {
                            if (response.isSuccess()) {
                                this.start();
                            } else {
                                // logout
                                this.logout(token);
                            }
                        });
                    } else {
                        // logout
                        this.tokenAlertDialog.componentRef.instance.clearTitleInterval();
                        this.logout(token);
                    }
                });
            }
        }
    }

    checkTokenExpired(token: any) {
        if (token.getPayload()) {
            if (new Date(token.getPayload().exp * 1000) < new Date()) {
                return false;
            }
            return true;
        }
    }

    logout(token: any) {
        this.authService.logout(token.getOwnerStrategyName()).subscribe(() => {
            this.router.navigate(['auth/login']);
        });
    }
}

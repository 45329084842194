<table class="table table-light table-striped table-hover">
  <thead>
    <tr>
      <th>Vārds</th>
      <th>E-pasts</th>
      <th>Telefons</th>
      <th>Kontaktpersonu pievienošanas tiesības</th>
      <th>Aktīvs</th>
      <th>Epastu saņemšana</th>
      <th>Ziņojumu notifikācijas</th>
      <th *ngIf="authority.isAutoSubmitter">Automātiskas zonas publicēšanas iespējas</th>
      <th *ngIf="authority.isSubmitter">Ģeogrāfisko zonu iesniegšana</th>
      <th>Darbības</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let user of users">
      <td>{{ user.firstName + ' ' + user.lastName }}</td>
      <td class="break">{{ user.email }}</td>
      <td class="break">{{ user.phone }}</td>
      <td>
        <ngx-switcher class="flex-1"
                      *ngIf="user.isRegistrationConfirmed && user.id != selfUserId"
                      [firstValue]="false"
                      [secondValue]="true"
                      [firstValueLabel]="switcherFirstValueLabel"
                      [secondValueLabel]="switcherSecondValueLabel"
                      [value]="user.isAuthorityAdmin"
                      (valueChange)="toggleIsAuthorityAdmin(user)"></ngx-switcher>
      </td>
      <td>
        <ngx-switcher class="flex-1"
                      *ngIf="user.isRegistrationConfirmed && user.id != selfUserId"
                      [firstValue]="false"
                      [secondValue]="true"
                      [firstValueLabel]="switcherFirstValueLabel"
                      [secondValueLabel]="switcherSecondValueLabel"
                      [value]="!user.disabled"
                      (valueChange)="toggleDisabled(user)"></ngx-switcher>
      </td>
      <td>
        <ngx-switcher class="flex-1"
                      *ngIf="user.isRegistrationConfirmed"
                      [firstValue]="false"
                      [secondValue]="true"
                      [firstValueLabel]="switcherFirstValueLabel"
                      [secondValueLabel]="switcherSecondValueLabel"
                      [value]="!user.excludeFromMailing"
                      (valueChange)="toggleExcludeFromMailing(user)"></ngx-switcher>
      </td>
      <td>
        <ngx-switcher class="flex-1"
                      *ngIf="user.isRegistrationConfirmed"
                      [firstValue]="false"
                      [secondValue]="true"
                      [firstValueLabel]="switcherFirstValueLabel"
                      [secondValueLabel]="switcherSecondValueLabel"
                      [value]="user.getMeesageNotifications"
                      (valueChange)="toggleGetMeesageNotifications(user)"></ngx-switcher>
      </td>
      <td *ngIf="authority.isAutoSubmitter">
        <ngx-switcher class="flex-1"
                      *ngIf="user.isRegistrationConfirmed"
                      [firstValue]="false"
                      [secondValue]="true"
                      [firstValueLabel]="switcherFirstValueLabel"
                      [secondValueLabel]="switcherSecondValueLabel"
                      [value]="user.isAutoSubmitter"
                      (valueChange)="toggleIsAutoSubmitter(user)"></ngx-switcher>
      </td>
      <td *ngIf="authority.isSubmitter">
        <ngx-switcher class="flex-1"
                      *ngIf="user.isRegistrationConfirmed"
                      [firstValue]="false"
                      [secondValue]="true"
                      [firstValueLabel]="switcherFirstValueLabel"
                      [secondValueLabel]="switcherSecondValueLabel"
                      [value]="user.isSubmitter"
                      (valueChange)="toggleIsSubmitter(user)"></ngx-switcher>
      </td>
      <td>
        <button *ngIf="!user.isRegistrationConfirmed"
                class="btn btn-info btn-action p-2"
                nbTooltip="Nosūtīt ielūgumu atkārtoti"
                (click)="resendInvitation(user)">
          <i class="fa fa-paper-plane"></i>
        </button>
      </td>
    </tr>
  </tbody>
</table>

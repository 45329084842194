import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AmdbServerDataSource } from './amdb-server.data-source';

@Injectable({providedIn: 'root'})
export class ServerDataSourceService {
    constructor(private httpClient: HttpClient) { }

    getServerDataSource(dataSourceUri: string): AmdbServerDataSource {
        return new AmdbServerDataSource(this.httpClient, {
            endPoint: dataSourceUri,
            totalKey: 'total',
            dataKey: 'data',

            sortFieldKey: 'orderField',
            pagerPageKey: 'page',
            sortDirKey: 'orderDir',
            pagerLimitKey: 'pageSize'
        });
    }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Classifier, CommandResult } from '@amdb/drone/shared/models';

@Injectable({
    providedIn: 'root',
})
export class ClassifiersService {
    constructor(private http: HttpClient) {}

    getAuthorityRoles(): Promise<Classifier[]> {
        return this.http.get<Classifier[]>('/api/v1/classifiers/authority-roles').toPromise();
    }

    getSubmissionPurposes(): Promise<Classifier[]> {
        return this.http.get<Classifier[]>('/api/v1/classifiers/submission-purposes').toPromise();
    }

    getUasZoneVersionReasons(): Promise<Classifier[]> {
        return this.http.get<Classifier[]>('/api/v1/classifiers/uas-zone-version-reasons').toPromise();
    }

    getZoneTypes(): Promise<Classifier[]> {
        return this.http.get<Classifier[]>('/api/v1/classifiers/zone-types').toPromise();
    }

    getRegions(): Promise<Classifier[]> {
        return this.http.get<Classifier[]>('/api/v1/classifiers/regions').toPromise();
    }

    getUomDimensions(): Promise<Classifier[]> {
        return this.http.get<Classifier[]>('/api/v1/classifiers/uom-dimensions').toPromise();
    }

    getWeekDays(): Promise<Classifier[]> {
        return this.http.get<Classifier[]>('/api/v1/classifiers/weekdays').toPromise();
    }

    getSunriseSunsetEvents(): Promise<Classifier[]> {
        return this.http.get<Classifier[]>('/api/v1/classifiers/sr-ss').toPromise();
    }

    getAuthorityPurposes(): Promise<Classifier[]> {
        return this.http.get<Classifier[]>('/api/v1/classifiers/authority-purposes').toPromise();
    }

    getAirspaceVolumeLimitVerticalReferences(): Promise<Classifier[]> {
        return this.http.get<Classifier[]>('/api/v1/classifiers/airspace-volume-limit-vertical-references').toPromise();
    }

    getAirspaceVolumeCoordinateSystems(): Promise<Classifier[]> {
        return this.http.get<Classifier[]>('/api/v1/classifiers/airspace-volume-coordinate-systems').toPromise();
    }

    getYesNo(): Promise<Classifier[]> {
        return this.http.get<Classifier[]>('/api/v1/classifiers/yes-no').toPromise();
    }

    getUasZoneVersionRestrictions(): Promise<Classifier[]> {
        return this.http.get<Classifier[]>('/api/v1/classifiers/uas-zone-version-restrictions').toPromise();
    }

    getDataGroups(): Promise<Classifier[]> {
        return this.http.get<Classifier[]>('/api/v1/classifiers/data-groups').toPromise();
    }

    addDataGroup(group: Classifier): Promise<CommandResult<boolean>> {
        return this.http.post<CommandResult<boolean>>('/api/v1/classifiers/new-group', group).toPromise();
    }

    editDataGroup(group: Classifier): Promise<CommandResult<boolean>> {
        return this.http.post<CommandResult<boolean>>('/api/v1/classifiers/edit-group', group).toPromise();
    }

    deleteDataGroup(groupId: number): Promise<CommandResult<boolean>> {
        return this.http.post<CommandResult<boolean>>('/api/v1/classifiers/delete-group', groupId).toPromise();
    }

    getElements(): Promise<Classifier[]> {
        return this.http.get<Classifier[]>('/api/v1/classifiers/elements').toPromise();
    }

    getRestrictionConditionTypes(): Promise<Classifier[]> {
        return this.http.get<Classifier[]>('/api/v1/classifiers/restriction-condition-types').toPromise();
    }
}

<nb-card class="dialog-card">
  <nb-card-header>Pamācības faili</nb-card-header>
  <nb-card-body>
    <div *ngIf="helpFiles">
      <button class="btn btn-link"
              (click)="downloadFile(file.id)"
              *ngFor="let file of helpFiles">{{ file.fileName }}</button>
    </div>
  </nb-card-body>
  <nb-card-footer>
    <button class="btn btn-success button-full"
            type="submit"
            (click)="close()">Aizvērt</button>
  </nb-card-footer>
</nb-card>
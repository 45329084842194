import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { NbAuthService, NbAuthJWTToken } from '@nebular/auth';
import { NbRoleProvider } from '@nebular/security';


@Injectable()
export class RoleProviderService implements NbRoleProvider {

    constructor(private authService: NbAuthService) {
    }

    getRole(): Observable<string[]> {
        return this.authService.onTokenChange()
            .pipe(
                map((token: NbAuthJWTToken) => {
                    if (!token.isValid()) {
                        return ['anyUser'];
                    }

                    const payload = token.getPayload();
                    if (!payload) {
                        return ['anyUser'];
                    }

                    return token.getPayload()['roles'] || ['anyUser'];
                }),
            );
    }
}

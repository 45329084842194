import { Directive, Input } from '@angular/core';
import { AbstractControl, Validator, NG_VALIDATORS, ValidationErrors, FormControl } from '@angular/forms';

@Directive({
  selector: '[airspaceVolumeLimitsValidator]',
  providers: [{
    provide: NG_VALIDATORS, 
    useExisting: AirspaceVolumeLimitsDirective,
    multi: true
  }]
})
export class AirspaceVolumeLimitsDirective implements Validator {
  @Input('airspaceVolumeLimitsValidator')
  secondControl: AbstractControl;

  @Input()
  isLower: boolean;

  @Input()
  isUpper: boolean;


  validate(control: AbstractControl<any, any>): ValidationErrors {
    if(!control || !this.secondControl) {
      return;
    }

    if(control.value && this.secondControl.value) {
      if(this.isLower) {
        if(control.value >= this.secondControl.value) {
          return { 'airspaceVolumeLimits': true };
        } else {
          if(this.secondControl.value) {
            this.secondControl.setErrors(null);
          }
        }
      }

      if(this.isUpper) {
        if(control.value <= this.secondControl.value) {
          return { 'airspaceVolumeLimits': true };
        } else {
          if(this.secondControl.value) {
            this.secondControl.setErrors(null);
          }
        }
      }
    }
    return null;
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseLayer } from '@amdb/drone/shared/models';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import olGroup from 'ol/layer/Group'
import XYZ from 'ol/source/XYZ';
import TileLayer from 'ol/layer/Tile';
import BingMaps from 'ol/source/BingMaps';
import GeoJSON from 'ol/format/GeoJSON';
import TileWMS from 'ol/source/TileWMS';
import ImageWMS from 'ol/source/ImageWMS';
import ImageLayer from 'ol/layer/Image';

interface LayerSettings {
    source: any;
    title: string;
    type?: string;
}

@Injectable({ providedIn: 'root' })
export class BaseLayersService {
    constructor(private httpClient: HttpClient) { }

    getAllBaseLayers(): Promise<BaseLayer[]> {
        return this.httpClient.get<BaseLayer[]>('/api/v1/base-layers').toPromise();
    }

    getActiveBaseLayers(): Promise<BaseLayer[]> {
        return this.httpClient.get<BaseLayer[]>('/api/v1/base-layers/active').toPromise();
    }

    addBaseLayer(baseLayer: BaseLayer): Promise<void> {
        return this.httpClient.post<void>('/api/v1/base-layers', baseLayer).toPromise();
    }

    updateBaseLayer(baseLayerId: number, baseLayer: BaseLayer): Promise<void> {
        return this.httpClient.put<void>('/api/v1/base-layers/' + baseLayerId, baseLayer).toPromise();
    }

    deleteBaseLayer(baseLayerId: number): Promise<void> {
        return this.httpClient.delete<void>('/api/v1/base-layers/' + baseLayerId).toPromise();
    }

    async getBaseLayerGroups(): Promise<olGroup[]> {

        const baseLayerConfigs = await this.getActiveBaseLayers();
        const baseLayers: any[] = [];
        const overlayLayers: any[] = [];
        baseLayerConfigs.forEach(l => {
            if (l.layerType !== 'TileLayer' && l.layerType !== 'VectorLayer') {
                return;
            }
            let baseLayer = null;
            switch (l.sourceType) {
                case 'XYZ':
                    const xSource = new XYZ(JSON.parse(l.sourceJson));
                    const settings = {
                        source: xSource,
                        title: l.name
                    } as LayerSettings;
                    if (!l.overlay) {
                        settings.type = 'base';
                    }
                    baseLayer = new TileLayer(settings);
                    break;
                case 'BingMaps':

                    const settingsBing = {
                        source: new BingMaps(JSON.parse(l.sourceJson)),
                        title: l.name
                    } as LayerSettings;
                    if (!l.overlay) {
                        settingsBing.type = 'base';
                    }
                    baseLayer = new TileLayer(settingsBing);
                    break;
                case 'TileWMS':
                    const layerConfig = JSON.parse(l.sourceJson);
                    const settingsWms = {
                        source: new TileWMS({ ...layerConfig, crossOrigin: 'anonymous' }),
                        title: l.name
                    } as LayerSettings;
                    if (!l.overlay) {
                        settingsWms.type = 'base';
                    }
                    baseLayer = new TileLayer(settingsWms);
                    break;
                case 'ImageWMS':
                    const imageLayerConfig = JSON.parse(l.sourceJson);
                    const imageSettingsWms = {
                        source: new ImageWMS({
                            ...imageLayerConfig,
                            crossOrigin: 'anonymous',
                        }),
                        title: l.name
                    } as LayerSettings;
                    if (!l.overlay) {
                        imageSettingsWms.type = 'base';
                    }
                    baseLayer = new ImageLayer(imageSettingsWms);
                    break;
                case 'VectorSource':
                    const vectorSourceConfig = JSON.parse(l.sourceJson);
                    const vectorSoruce = new VectorSource({

                        url: vectorSourceConfig.url,
                        format: new GeoJSON()
                    });
                    baseLayer = new VectorLayer({ source: vectorSoruce });
                    break;
            }
            if (l.overlay) {
                baseLayer.setVisible(false);
                overlayLayers.push(baseLayer);
            } else {
                baseLayers.push(baseLayer);
            }
        });

        if (baseLayers[0]) {
            baseLayers[0].setVisible(true);
        }
        return [new olGroup(<any>{
            title: 'Base maps',
            layers: [...baseLayers]
        }),
        new olGroup(<any>{
            title: 'Overlay',
            layers: [...overlayLayers]
        })];
    }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'errorCode'
})
export class ErrorCodePipe implements PipeTransform {

    transform(codeId: number): string {
        const translationMap = {

            // Shp arhive upload
            1: 'Faila nosaukums ir tukšs',
            2: 'Fails ar šādu nosaukumu jau eksistē',
            3: 'Neizdevās atvērt arhīvu',
            4: 'Arhīvā nav .shp faila',

            // Shp file open
            5: 'Arhīvā nav .prj faila',
            6: 'Atribūts "gtse_uuid" nav atrasts',
            7: 'Atribūta "gtse_uuid" vērtība neatbilst UUID formātam',
            8: 'Neizdevās nolasīt ģeometriju',

            9: 'Datu grupa ir piesaistīta kādam no iesniegumiem',

            // Xy check from CSV errors
            10: 'CSV faila kolonnu nosaukumi nav pareizi definēti',
            11: 'CSV failā nav datu',
            12: 'CSV failā ir nekorekti dati',

            // Auth errors
            13: 'Nepareizs E-pasts un/vai parole',
            14: 'reCaptcha validācija neizdevās. Lūdzu mēģiniet vēlreiz',
            15: 'Autorizācijas rekvizīti nav derīgi. Lūdzu veiciet autorizācijas procesu no jauna, ievadot lietotāja vārdu un paroli.',
            16: 'Kļūda paroles atjaunošanas pieprasījumā. Lūdzu pārliecinieties, ka norādītie dati ir pareizi un veiciet paroles atjaunošanas pieprasījumu vēlreiz',
            17: 'Paroles maiņas rekvizīti nav derīgi. Lūdzu veiciet jaunu paroles maiņas pieprasījumu',
            18: 'Kļūda veicot paroles maiņu. Lūdzu mēģiniet vēlreiz',
            19: 'Nepareizi autorizācijas dati, lūdzu mēģiniet vēlreiz'
        };
        const translation = translationMap[codeId];

        if (!translation) {
            if (codeId) {
                return codeId.toString();
            } else {
                return 'Sistēmas kļūda';
            }
        }
        return translation;
    }

}

import { Component, OnInit } from '@angular/core';
import { HelpService } from '../../../@core/services/help.service';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { HelpFile } from '@amdb/drone/pages/admin/help-files/help-file.model';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'amdb-help-dialog',
  templateUrl: './help-dialog.component.html',
  styleUrls: ['./help-dialog.component.scss']
})
export class HelpDialogComponent implements OnInit {

  helpFiles: HelpFile[];

  constructor(private helpFileService: HelpService,
    protected dialogRef: NbDialogRef<void>,
    private toastrService: NbToastrService) { }

  async ngOnInit(): Promise<void> {
    this.helpFiles = await this.helpFileService.getFiles();
  }

  async downloadFile(fileId: number): Promise<void> {
    try {
      await this.helpFileService.downloadHelpFile(fileId);
    } catch (err) {
      if(err instanceof HttpErrorResponse) {
        if(err.error.errors) {
          this.toastrService.danger(err.error, 'Kļūda');
        } else {
          this.toastrService.danger('Kļūda lejupielādējot failu', 'Kļūda');
        }
      }
    }
  }

  close(): void {
    this.dialogRef.close();
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { UserProfile } from '../../pages/user-profile/user-profile.model';

@Injectable({
  providedIn: 'root'
})
export class UserProfileService {

  constructor(private http: HttpClient) { }

  getUserProfile(): Promise<UserProfile> {
    return this.http.get<UserProfile>('/api/v1/user-profile').toPromise();
  }

  updateProfile(user: UserProfile): Promise<boolean> {

    const simpleUser = {
      firstName: user.firstName,
      lastName: user.lastName,
      phone: user.phone
    };

    return this.http.put<boolean>(`/api/v1/user-profile`, simpleUser).toPromise();
  }
}

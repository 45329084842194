export const environment = {
  hmr: false,
  production: false,
  localization: {
    enabled: false,
    defaultLocale: 'lv',
    availableLanguages: [
      { code: 'lv', label: 'Latviešu'},
      { code: 'ru', label: 'Русский'}
    ]
  },
  reCaptchaSiteKey: '6LcOTrcjAAAAAKiZupPBXDn8RGMs967sAD84k6_c',
  newWorkMaxSingleFileSizeMB: 300,
  newWorkMaxMultiFileSizeMB: 30,
  newWorkMaxMultiFileCount: 5
};

import { Directive, Input } from '@angular/core';
import { AbstractControl, Validator, NG_VALIDATORS, ValidationErrors, FormControl } from '@angular/forms';

@Directive({
    selector: '[startEndEventValidator]',
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: StartEndEventValidatorDirective,
            multi: true,
        },
    ],
})
export class StartEndEventValidatorDirective implements Validator {
    @Input('startEndEventValidator')
    secondControl: AbstractControl;

    validate(control: AbstractControl<any, any>): ValidationErrors {
        if (!control || !control.value || !this.secondControl || !this.secondControl.value) {
            return null;
        }

        if (control.value == this.secondControl.value) {
            return { startEndEventInvalid: true };
        } else {
            if (this.secondControl.value) {
                this.secondControl.setErrors(null);
            }
        }

        return null;
    }
}

<div class="inline-button-container">
    <div class="break-all">
        {{value.fileName}}
    </div>
    <button nbButton 
            status="success" 
            class="sm-button float-right"
            nbTooltip="Lejupielādēt"
            (click)="downloadReport(value.id)"><i class="fa fa-download"></i></button>
        <!-- Delete button - commented out by request, might be needed to add it back later -->
        <!-- <button nbButton
                *ngIf="value.id != 1 && isSuperAdmin"
                status="danger"
                class="sm-button ms-1"
                nbTooltip="Dzēst"
                (click)="deleteRecord(value.id)"><i class="fa fa-trash"></i></button> -->
</div>

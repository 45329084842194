<nb-card size="large">
    <nb-card-header>
        <span>Ģeometrijas topoloģiskās validācijas rezultāti</span>
    </nb-card-header>
    <nb-card-body>
        <div class="data-requests-table" [nbSpinner]="loading">
            <ng2-smart-table [settings]="tableSettings" [source]="source"></ng2-smart-table>
        </div>
    </nb-card-body>
    <nb-card-footer>
        <div *ngIf="fromShp" class="mb-3">
            <span *ngIf="hasGtseUuid" class="error-message">
                Uzmanību! Konstatēts SHP atribūts "gtse_uuid". Šī zona tiks klasificēta kā uz ģeogrāfisko zonu transformēts gaisa telpas struktūras elements
            </span>
            <span *ngIf="!hasGtseUuid" class="error-message">
                Uzmanību! SHP atribūtos nav atrasts "gtse_uuid" atribūts. Šī zona netiks klasificēta kā uz ģeogrāfisko zonu transformēts gaisa telpas struktūras elements
            </span>
        </div>
        <button nbButton hero status="primary" (click)="dismiss()">Atcelt</button>
        <button nbButton hero status="success" class="ms-3" [disabled]="validationFailed" (click)="continue()">Turpināt</button>
    </nb-card-footer>
</nb-card>

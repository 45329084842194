<div class="header-container left">
  <div class="logo-containter">
    <a (click)="toggleSidebar()"
       href="#"
       class="navigation"><i class="nb-menu"></i></a>
    <div class="logo pointer"
         (click)="goToHome()">
      <i class="logo-icon nb-location ng-star-inserted"></i>
      DRZ <small class="version-number">{{ appVersion }}</small>
    </div>
  </div>
  <button class="btn btn-link ms-2"
          (click)="openHelp()">Lietotāja palīdzība un Objektu/vietas veidlapa</button>
</div>

<div class="header-container">
  <nb-actions size="medium"
              class="normal">
    <!-- <nb-action>
      <amdb-language-switcher *ngIf="isLanguageSwitcherEnabled"></amdb-language-switcher>
    </nb-action> -->
    <nb-action>
      <nb-user [nbContextMenu]="userMenu"
               [name]="user?.unique_name"></nb-user>
    </nb-action>
  </nb-actions>
</div>
import { Component, OnInit, Input } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { BaseLayer } from '@amdb/drone/shared/models';
import { BaseLayersService } from '@amdb/drone/@core/services/base-layer.service';

@Component({
    templateUrl: './base-layer-details.component.html',
    styleUrls: ['./base-layer-details.component.scss']
})
export class BaseLayerDetailsComponent implements OnInit {

    @Input()
    baseLayer: BaseLayer;

    isEditMode: boolean;
    loading: boolean;
    availableSourceTypes: string[] = ['TileWMS', 'ImageWMS', 'XYZ', 'BingMaps'];

    constructor(protected ref: NbDialogRef<BaseLayerDetailsComponent>,
        private toastrService: NbToastrService,
        private baseLayersService: BaseLayersService) { }

    ngOnInit() {
        if (this.baseLayer) {
            this.isEditMode = true;
        } else {
            this.baseLayer = {} as BaseLayer;
        }
    }

    async submitBaseLayer(): Promise<void> {
        try {
            this.loading = true;
            if (this.isEditMode) {
                await this.updateBaseLayer();
            } else {
                await this.addBaseLayer();
            }
        } catch (errorResponse) {
            if (errorResponse instanceof HttpErrorResponse) {
                this.toastrService.danger(errorResponse.error.errors, 'Kļūda');
            }
        } finally {
            this.loading = false;
        }
    }

    async addBaseLayer(): Promise<void> {
        await this.baseLayersService.addBaseLayer(this.baseLayer);
        this.ref.close(true);
    }

    async updateBaseLayer(): Promise<void> {
        await this.baseLayersService.updateBaseLayer(this.baseLayer.id, this.baseLayer);
        this.ref.close(true);
    }

    dismiss() {
        this.ref.close();
    }
}

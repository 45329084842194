import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { CommandResult, Authority, User } from '@amdb/drone/shared/models';

@Injectable({
  providedIn: 'root'
})
export class AuthorityService {

  clearAuthorityData: EventEmitter<void> = new EventEmitter<void>();

  constructor(private http: HttpClient) { }

  registerAuthority(authority: Authority): Promise<CommandResult<Authority>> {
    return this.http.post<CommandResult<Authority>>('/api/v1/admin/authorities', authority).toPromise();
  }

  updateAuthority(authority: Authority): Promise<void> {
    return this.http.put<void>('/api/v1/admin/authorities/' + authority.id, authority).toPromise();
  }

  getAuthorities(): Promise<Authority[]> {
    return this.http.get<Authority[]>('/api/v1/admin/authorities').toPromise();
  }

  getAuthority(authorityId: number): Promise<Authority> {
    return this.http.get<Authority>(`/api/v1/admin/authorities/${authorityId}`).toPromise();
  }

  getAllActiveAuthorities(): Promise<Authority[]> {
    return this.http.get<Authority[]>('/api/v1/admin/authorities/active').toPromise();
  }

  getAllDisabledAuthorities(): Promise<Authority[]> {
    return this.http.get<Authority[]>('/api/v1/admin/authorities/inactive').toPromise();
  }

  toggleDisabled(authorityId: number, status: boolean): Promise<CommandResult<boolean>> {
    if (status) {
      return this.http.post<CommandResult<boolean>>(`/api/v1/admin/authorities/${authorityId}/enable`, null).toPromise();
    }
    return this.http.post<CommandResult<boolean>>(`/api/v1/admin/authorities/${authorityId}/disable`, null).toPromise();
  }

  resendInvitation(authorityId: number): Promise<CommandResult<boolean>> {
    return this.http.post<CommandResult<boolean>>(`/api/v1/admin/authorities/${authorityId}/resend-invitation`, null).toPromise();
  }

  loadAuthorityData(authorityId: number): Promise<Authority> {
    return this.http.get<Authority>('/api/v1/admin/authorities/' + authorityId).toPromise();
  }

  getAuthoritySubmissionCount(authorityId: number): Promise<number> {
    return this.http.get<number>(`/api/v1/admin/authorities/${authorityId}/submission-count`).toPromise();
  }

  addUser(authorityId: number, user: User): Promise<void> {
    return this.http.post<void>(`/api/v1/admin/authorities/${authorityId}/users`, user).toPromise();
  }
}

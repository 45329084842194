import { Directive, Input } from '@angular/core';
import { AbstractControl, Validator, NG_VALIDATORS, ValidationErrors } from '@angular/forms';

@Directive({
  selector: '[datesValidator]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: DatesValidatorDirective,
    multi: true
  }]
})
export class DatesValidatorDirective implements Validator {

  @Input('datesValidator')
  secondControl: AbstractControl;

  @Input()
  isStartDate: boolean;

  @Input()
  isEndDate: boolean;

  validate(control: AbstractControl<any, any>): ValidationErrors {
    if(control && this.secondControl) {
      if(control.value) {
        if(this.isStartDate) {
          if(this.secondControl.value <= control.value) {
            return { 'startDateGreaterThanEndDate': true };
          } else {
            if(this.secondControl.value) {
              this.secondControl.setErrors(null);
            }
          }
        }
    
        if(this.isEndDate) {
          if(this.secondControl.value >= control.value) {
            return { 'startDateGreaterThanEndDate': true }; 
          } else {
            if(this.secondControl.value) {
              this.secondControl.setErrors(null);
            }
          }
        }
      }
    }

    return null;
  }

}

import { Injectable } from '@angular/core';
import { NbAccessChecker } from '@nebular/security';
import { take } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class AmdbAccessCheckerService extends NbAccessChecker {

    async isGrantedAny(permissions: string[], amdbModule: string): Promise<boolean> {
        for (const permission of permissions) {
            if (await this.isGranted(permission, amdbModule).pipe(take(1)).toPromise()) {
                return true;
            }
        }
        return false;
    }

    isSuperAdmin(tokenPayload: any): boolean {
        const roles: string[] = Array.isArray(tokenPayload.roles) ? tokenPayload.roles : [tokenPayload.roles];
        return roles.some(r => r === 'userAdmin' || r === 'superAdmin');
    }

    isPublisher(tokenPayload: any): boolean {
        const roles: string[] = Array.isArray(tokenPayload.roles) ? tokenPayload.roles : [tokenPayload.roles];
        return roles.some(r => r === 'publisher');
    }

    isImporter(tokenPayload: any): boolean {
        const roles: string[] = Array.isArray(tokenPayload.roles) ? tokenPayload.roles : [tokenPayload.roles];
        return roles.some(r => r === 'importer');
    }

    isAutoSubmitter(tokenPayload: any): boolean {
        const roles: string[] = Array.isArray(tokenPayload.roles) ? tokenPayload.roles : [tokenPayload.roles];
        return roles.some(r => r === 'autoSubmitter');
    }

    isSubmitter(tokenPayload: any): boolean {
        const roles: string[] = Array.isArray(tokenPayload.roles) ? tokenPayload.roles: [tokenPayload.roles];
        return roles.some(r => r === 'submitter');
    }

    isOriginator(tokenPayload: any): boolean {
        const roles: string[] = Array.isArray(tokenPayload.roles) ? tokenPayload.roles : [tokenPayload.roles];
        return roles.some(r => r === 'originator');
    }

    isReadonlySubmitter(tokenPayload: any): boolean {
        const roles: string[] = Array.isArray(tokenPayload.roles) ? tokenPayload.roles : [tokenPayload.roles];
        return roles.some(r => r === 'originator') && !roles.includes('autoSubmitter') && !roles.includes('autoImporter');
    }

    canEditAll(tokenPayload: any): boolean {
        const roles: string[] = Array.isArray(tokenPayload.roles) ? tokenPayload.roles : [tokenPayload.roles];
        return roles.some(r => r === 'importer' || r === 'publisher' || r === 'autoSubmitter');
    }

    getUserId(tokenPayload: any): number {
        return tokenPayload.nameid;
    }
}

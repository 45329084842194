import { AmdbAccessCheckerService } from '@amdb/drone/@core/security/amdb-access-checker.service';
import { AmdbServerDataSource } from '@amdb/drone/@core/services/amdb-server.data-source';
import { ServerDataSourceService } from '@amdb/drone/@core/services/server-data-source.service';
import { ExportHistoryFilter } from '@amdb/drone/shared/models/export-history-filter.model';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NbTokenService } from '@nebular/auth';
import { ExportHistoryActionsComponent } from '../export-history-actions/export-history-actions.component';
import { ExportHistory } from '../export-history.model';

@Component({
  selector: 'amdb-export-history-list',
  templateUrl: './export-history-list.component.html',
  styleUrls: ['./export-history-list.component.scss']
})
export class ExportHistoryListComponent implements OnInit {

  @Output()
  onDownloadStart: EventEmitter<any> = new EventEmitter();

  @Output()
  onDownloadEnd: EventEmitter<any> = new EventEmitter();

  @Output()
  onDeleteStart: EventEmitter<any> = new EventEmitter();

  @Output()
  onDeleteEnd: EventEmitter<any> = new EventEmitter();
  
  sourceServer: AmdbServerDataSource;
  tableSettings: any;
  loading: boolean = true;
  rootUrl: any;

  isSuperAdmin: boolean = false;

  exportHistoryFilter: ExportHistoryFilter = {
    onlyDynamic: false, 
    onlyStatic: false,
    onlyValid: false,
    fileName: '',
    filterDate: null
  } as ExportHistoryFilter;

  cols: any;
  
  constructor(private serverDataSourceService: ServerDataSourceService,              
            private amdbAccessCheckerService: AmdbAccessCheckerService,
            private tokenService: NbTokenService) { }

  async ngOnInit(): Promise<void> {
    this.loadData();
    this.rootUrl = location.protocol + '//' + location.host;
    
    const token = await this.tokenService.get().toPromise();
    this.isSuperAdmin = this.amdbAccessCheckerService.isSuperAdmin(token.getPayload());

    this.sourceServer.onStartingChange().subscribe(d => {
      setTimeout(() => {
          this.loading = true;
      });
    });

    this.sourceServer.onChanged().subscribe(d => {
        setTimeout(() => {
            this.loading = false;
        });
    });

    this.redefineSettings(this.rootUrl);
  }

  redefineSettings(rootUrl: string) {
    this.cols = {
      type: {
        title: 'Avota grupa',
        filter: false,
        valuePrepareFunction(autoZoneExport: boolean, row: ExportHistory) {
          return row.autoZoneExport ? 'Dinamiskās' : 'Statiskās';
        }
      },
      utcExportTime: {
        title: 'Spēkā no (UTC)',
        filter: false
      },
      fileCrc32Q: {
        title: 'Datnes CRC32-Q',
        filter: false,
        sort: false
      },
      user: {
        title: 'Eksportētājs',
        filter: false,
        sort: false
      },
      fileMD5: {
        title: 'Datnes MD5',
        filter: false,
        sort: false,
        width: '20rem'
      },
      crc32Q: {
        title: 'Zonu CRC32-Q',
        filter: false,
        sort: false
      },
      zoneCount: {
        title: 'Zonu skaits',
        filter: false,
        sort: false
      },
      fileName: {
        title: 'Datnes nosaukums',
        filter: false,
        width: '26rem',
        type: 'custom',
        valuePrepareFunction: (fileName: string, row: ExportHistory) => row,
        renderComponent: ExportHistoryActionsComponent,
        onComponentInitFunction: (instance) => {
          instance.isSuperAdmin = this.isSuperAdmin;
          instance.onDownloadStart.subscribe(() => {
            this.onDownloadStart.emit();
          });

          instance.onDownloadEnd.subscribe(() => {
            this.onDownloadEnd.emit();
            this.loadData();
          });

          instance.onDeleteStart.subscribe(() => {
            this.onDeleteStart.emit();
          })

          instance.onDeleteEnd.subscribe(() => {
            this.onDeleteEnd.emit();
            this.loadData();
          })
        }
      },
      downloadCount: {
        title: 'Lejupielāžu skaits',
        filter: false,
        sort: false,
        valuePrepareFunction(downloadCount: number, row: ExportHistory) {
          return downloadCount ? downloadCount : 0;
        }
      }
    }

    this.tableSettings = {
      mode: 'external',
      actions: false,
      pager: {
        perPage: 25,
        display: true
      },
      columns: this.cols,
      attr: {
        class: 'word-break'
      }
    }
  }

  loadData(): void {
    this.sourceServer = this.serverDataSourceService.getServerDataSource('/api/v1/export-history');
    if(this.exportHistoryFilter) {
      this.sourceServer.setCustomExportHistoryFilter(this.exportHistoryFilter);
    }
  }

  removeFilters() {
    if(!this.exportHistoryFilter.onlyValid){
      this.exportHistoryFilter.onlyDynamic = false;
      this.exportHistoryFilter.onlyStatic = false;
      this.exportHistoryFilter.fileName = '';
      this.exportHistoryFilter.filterDate = null;
    }
  }
}
import { Component, OnInit, ViewChild } from '@angular/core';
import { NbToastrService, NbDialogRef } from '@nebular/theme';
import { SubmissionsService } from '../../../@core/services/submissions.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorCodePipe } from '@amdb/drone/shared/pipes/error-code.pipe';

@Component({
    templateUrl: './shp-archive-picker.component.html',
    styleUrls: ['./shp-archive-picker.component.scss'],
    providers: [ErrorCodePipe]
})
export class ShpArchivePickerComponent implements OnInit {

    @ViewChild('shpArchiveSelector', { static: false }) shpArchiveSelector;

    errors: string[];
    loading: boolean;
    constructor(private toastrService: NbToastrService,
        private submissionService: SubmissionsService,
        protected ref: NbDialogRef<ShpArchivePickerComponent>,
        private errorCodeIPipe: ErrorCodePipe) { }

    ngOnInit() {
    }

    async upload() {
        this.loading = false;
        this.errors = null;
        const shpArchive = this.getShpArchiveToUpload();
        if (!shpArchive) {
            this.toastrService.danger('Nav izvēlēts arhīvs', 'Kļūda');
            return;
        }
        if (shpArchive.size > 100000000) {
            this.toastrService.danger('Arhīva izmērs pārāk liels', 'Kļūda');
            return;
        }
        const formData = new FormData();
        formData.append('file', shpArchive);
        try {
            this.loading = true;
            const uploadResult = await this.submissionService.uploadShpArchive(formData);
            this.ref.close(uploadResult);
            this.loading = false;
        } catch (err) {
            this.loading = false;
            if (err instanceof HttpErrorResponse) {
                this.errors = [];
                err.error.forEach(e => {
                    this.errors.push(this.errorCodeIPipe.transform(e));
                });

            }
        }
    }

    dismiss() {
        this.ref.close();
    }

    private getShpArchiveToUpload(): File {
        const shpArchiveBrowser = this.shpArchiveSelector.nativeElement;
        if (!shpArchiveBrowser.files ||
            !shpArchiveBrowser.files[0]) {
            return null;
        }
        return shpArchiveBrowser.files[0];
    }
}

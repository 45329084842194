import { Component, Input } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { NbToastrService } from '@nebular/theme';

import { User } from '@amdb/drone/shared/models/user.model';
import { UsersService } from '@amdb/drone/pages/admin/users/users.service';
import { Authority } from '../../models';
import { NbAuthJWTToken, NbTokenService } from '@nebular/auth';
import { Subscription } from 'rxjs';
import { AmdbAccessCheckerService } from '@amdb/drone/@core/security/amdb-access-checker.service';

@Component({
    selector: 'amdb-user-list',
    templateUrl: './user-list.component.html',
    styleUrls: ['./user-list.component.scss']
})
export class UserListComponent {

    @Input()
    users: User[];

    @Input()
    authority: Authority;

    selfUserId: any;

    tokenChangeSubscription: Subscription;

    switcherFirstValueLabel = 'Nav';
    switcherSecondValueLabel = 'Ir';

    constructor(private userService: UsersService,
        private toastrService: NbToastrService,
        protected tokenService: NbTokenService,
        private amdbAccessCheckerService: AmdbAccessCheckerService) { }

    ngOnInit() {
        this.tokenChangeSubscription = this.tokenService.tokenChange().subscribe((token: NbAuthJWTToken) => {
            if (token.isValid()) {
                this.selfUserId = this.amdbAccessCheckerService.getUserId(token.getPayload());
            }
        });
    }

    async toggleDisabled(user: User): Promise<void> {
        try {
            await this.userService.toggleDisabled(user.id, !user.disabled);
            user.disabled = !user.disabled;
        } catch (errorResponse) {
            if (errorResponse instanceof HttpErrorResponse) {
                this.toastrService.danger(errorResponse.error.errors, 'Kļūda');
            }
        }
    }

    async toggleIsAuthorityAdmin(user: User): Promise<void> {
        try {
            await this.userService.toggleIsAuthorityAdmin(user.id, !user.isAuthorityAdmin);
            user.isAuthorityAdmin = !user.isAuthorityAdmin;
        } catch (errorResponse) {
            if (errorResponse instanceof HttpErrorResponse) {
                this.toastrService.danger(errorResponse.error.errors, 'Kļūda');
            }
        }
    }

    async toggleIsAutoSubmitter(user: User): Promise<void> {
        try {
            await this.userService.toggleIsAutoSubmitter(user.id, !user.isAutoSubmitter);
            user.isAutoSubmitter = !user.isAutoSubmitter;
        } catch (errorResponse) {
            if(errorResponse instanceof HttpErrorResponse) {
                this.toastrService.danger(errorResponse.error.errors, 'Kļūda');
            }
        }
    }

    async toggleIsSubmitter(user: User): Promise<void> {
        try {
            await this.userService.toggleIsSubmitter(user.id, !user.isSubmitter);
            user.isSubmitter = !user.isSubmitter;
        } catch (errorResponse) {
            if(errorResponse instanceof HttpErrorResponse) {
                this.toastrService.danger(errorResponse.error.errors, 'Kļūda');
            }
        }
    }

    async toggleExcludeFromMailing(user: User): Promise<void> {
        try {
            await this.userService.toggleExcludeFromMailing(user.id, !user.excludeFromMailing);
            user.excludeFromMailing = !user.excludeFromMailing;
        } catch (errorResponse) {
            if (errorResponse instanceof HttpErrorResponse) {
                this.toastrService.danger(errorResponse.error.errors, 'Kļūda');
            }
        }
    }

    async toggleGetMeesageNotifications(user: User): Promise<void> {
        try {
            await this.userService.toggleGetMeesageNotifications(user.id, !user.getMeesageNotifications);
            user.getMeesageNotifications = !user.getMeesageNotifications;
        } catch (errorResponse) {
            if (errorResponse instanceof HttpErrorResponse) {
                this.toastrService.danger(errorResponse.error.errors, 'Kļūda');
            }
        }
    }

    async resendInvitation(user: User): Promise<void> {
        try {
            await this.userService.resendInvitation(user.id);
            this.toastrService.success('Ielūgums nosūtīts!', 'Info');
        } catch (errorResponse) {
            if (errorResponse instanceof HttpErrorResponse) {
                this.toastrService.danger(errorResponse.error.errors, 'Kļūda');
            }
        }
    }
}

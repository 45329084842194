import { Component, OnInit, Input } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  templateUrl: './add-group.component.html',
  styleUrls: ['./add-group.component.scss']
})
export class AddGroupComponent implements OnInit {

  @Input()
  group = {
    name: null,
    description: null
  };

  @Input()
  edit: boolean = true;

  constructor(protected ref: NbDialogRef<AddGroupComponent>) { }

  ngOnInit() {
  }

  submitGroup(): void {
    this.ref.close(this.group);
  }

  dismiss() {
    this.ref.close();
  }
}

import { HttpRequest } from '@angular/common/http';

export function tokenInterceptionFilter(req: HttpRequest<any>) {
  const noAuthRoutes: string[] = [
    'auth/login',
    'auth/refresh-token',
    'auth/request-pass',
    'auth/reset-pass',
    'auth/login-token'
  ];
  if (noAuthRoutes.some(r => req.url.includes(r))) {
    return true;
  }
  return false;
}

export enum AmdbModules {
    AdminUsers = 'admin-users',
    AdminAuthorities = 'admin-authorities',
    AdminClassifiers = 'admin-classifiers',
    AdminLogs = 'admin-logs',
    FailedRequests = 'failed-requests',
    AdminHelpFiles = 'admin-help-files',
    AdminExportHistory = 'admin-export-history',
    DataGroups = 'data-groups',

    Submissions = 'submissions',
    AuthorityProfile = 'authority-profile',
    Messages = 'messages',
    ExportHistory = 'export-history',
    Regions = 'ŗegions',
    RestrictionConditions = 'restriction-conditions'
}

export enum AmdbActions {
  //Submit = 'submit',  SUBMIT action commented out for now, might be needed to re-add later
    ApproveImport = 'approve-import',
    DeclineImport = 'decline-import',
    ApprovePublish = 'approve-import',
    DeclinePublish = 'decline-import',
    Process = 'process',
    StartMessageThread = 'start-thread',
    CloseMessageThread = 'close-thread'
}

export enum Permissions {
    View = 'view',
    Action = 'action'
}

<div class="data-requests-table"
     [nbSpinner]="loading"
     *ngIf="tableSettings">
     <div class="form-control-group col-lg-12 mt-1">
        <div class="form-control-group mb-3">
          <form (keyup.enter)="loadData()"
                #form="ngForm"
                aria-labelledby="title"
                class="form-wrapper">

                <!-- If onlyValid is not checked, then other filters can be applied -->
                <div *ngIf="!exportHistoryFilter.onlyValid" 
                    class="form-wrapper">
                    <div>
                        <label class="label me-3"
                                for="name">Nosaukums</label>
                        <input nbInput
                                [(ngModel)]=" exportHistoryFilter.fileName"
                                #fileName="ngModel"
                                id="fileName"
                                name="fileName"
                                [disabled]="exportHistoryFilter.filterDate != null || exportHistoryFilter.onlyValid">
                    </div>
                    <div>
                        <label class="label ms-3 me-3"
                               for="endDateTime">Spēkā uz</label>
                        <input nbInput
                               [(ngModel)]="exportHistoryFilter.filterDate"
                               #filterDate="ngModel"
                               name="filterDate"
                               [nbDatepicker]="pickerTo"
                               [disabled]="exportHistoryFilter.onlyValid">
                               <nb-date-timepicker #pickerTo
                                                    format="yyyy-MM-dd HH:mm"></nb-date-timepicker>
                    </div>
                    <div>
                        <div *ngIf="exportHistoryFilter.filterDate != null">
                            <div class="checkbox-container ms-3">
                                <nb-checkbox name="onlyDynamic"
                                            class="ms-2"
                                            id="onlyDynamic"
                                            [checked]="false"
                                            disabled>
                                <span>Tikai dinamiskās</span>
                                </nb-checkbox>
                                <nb-checkbox name="onlyStatic"
                                            class="ms-2"
                                            id="onlyStatic"
                                            [checked]="false"
                                            disabled>
                                <span>Tikai statiskās</span>
                                </nb-checkbox>
                            </div>
                        </div>
                        <div *ngIf="exportHistoryFilter.filterDate == null">
                            <div class="checkbox-container ms-3">
                                <nb-checkbox [(ngModel)]="exportHistoryFilter.onlyDynamic"
                                            name="onlyDynamic"
                                            class="ms-2"
                                            id="onlyDynamic">
                                <span>Tikai dinamiskās</span>
                                </nb-checkbox>
                                <nb-checkbox [(ngModel)]="exportHistoryFilter.onlyStatic"
                                            name="onlyStatic"
                                            class="ms-2"
                                            id="onlyStatic">
                                <span>Tikai statiskās</span>
                                </nb-checkbox>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- If onlyValid is checked, other filters cannot be applied -->
                <div *ngIf="exportHistoryFilter.onlyValid" 
                    class="form-wrapper">
                    <div>
                        <label class="label me-3"
                                for="name">Nosaukums</label>
                        <input nbInput
                                [(ngModel)]=" exportHistoryFilter.fileName"
                                #fileName="ngModel"
                                id="fileName"
                                name="fileName"
                                [disabled]="true">
                    </div>
                    <div>
                        <label class="label ms-3 me-3"
                               for="endDateTime">Spēkā uz</label>
                        <input nbInput
                               [(ngModel)]="exportHistoryFilter.filterDate"
                               #filterDate="ngModel"
                               name="filterDate"
                               [nbDatepicker]="pickerTo"
                               [disabled]="true">
                               <nb-date-timepicker #pickerTo
                                                    format="yyyy-MM-dd HH:mm"></nb-date-timepicker>
                    </div>
                    <div>
                        <div>
                            <div class="checkbox-container ms-3">
                                <nb-checkbox name="onlyDynamic"
                                            class="ms-2"
                                            id="onlyDynamic"
                                            [checked]="false"
                                            disabled>
                                <span>Tikai dinamiskās</span>
                                </nb-checkbox>
                                <nb-checkbox name="onlyStatic"
                                            class="ms-2"
                                            id="onlyStatic"
                                            [checked]="false"
                                            disabled>
                                <span>Tikai statiskās</span>
                                </nb-checkbox>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- onlyValid should not be available if filterDate is not null -->
                <div *ngIf="exportHistoryFilter.filterDate != null"
                    class="checkbox-container ms-3">
                    <nb-checkbox [(ngModel)]="exportHistoryFilter.onlyValid"
                                name="onlyValid"
                                class="ms-2"
                                id="onlyValid"
                                [checked]="false"
                                disabled>
                    <span>Tikai spēkā esošie</span>
                    </nb-checkbox>
                </div>

                <!-- onlyValid should be available only if filterDate is null -->
                <!-- If onlyValid is checked, removeFilters() method resets all other filter values -->
                <div class="checkbox-container ms-3"
                    *ngIf="exportHistoryFilter.filterDate == null">
                    <nb-checkbox [(ngModel)]="exportHistoryFilter.onlyValid"
                                name="onlyValid"
                                class="ms-2"
                                id="onlyValid"
                                (checkedChange)="removeFilters()">
                    <span>Tikai spēkā esošie</span>
                    </nb-checkbox>
                </div>
    
                <button type="submit"
                        class="btn btn-info"
                        style="margin-left: 1.5rem"
                        (click)="loadData()">Meklēt</button>
            </form>
          </div>
      </div>
    <ng2-smart-table [settings]="tableSettings"
                     [source]="sourceServer"></ng2-smart-table>
</div>

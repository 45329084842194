import { Directive, Input } from '@angular/core';
import { AbstractControl, Validator, NG_VALIDATORS, ValidationErrors } from '@angular/forms';

@Directive({
  selector: '[maxValue]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: MaxValueDirective,
    multi: true
  }]
})
export class MaxValueDirective implements Validator {

  @Input('maxValue')
  maxValue: number;

  @Input()
  maxCanBeEqual: boolean;
  
  validate(control: AbstractControl<any, any>): ValidationErrors {
    if(this.maxCanBeEqual) {
      if(control.value > this.maxValue) {
        return { 'maxValue': true };
      } else {
        return null;
      }
    } else {
      if(control.value > this.maxValue || control.value == this.maxValue) {
        return { 'maxValue': true };
      } else {
        return null;
      }
    }
  }
}

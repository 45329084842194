import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { saveAs } from 'file-saver';
import { HelpFile } from '@amdb/drone/pages/admin/help-files/help-file.model';

@Injectable({ providedIn: 'root' })
export class HelpService {
    constructor(private httpClient: HttpClient) { }

    getAllHelpFiles(): Promise<HelpFile[]> {
        return this.httpClient.get<HelpFile[]>('/api/v1/help').toPromise();
    }

    addNewFile(formData: FormData): Promise<void> {
        return this.httpClient.post<void>('/api/v1/help/add', formData).toPromise();
    }
    
    editFileInfo(formData: FormData, fileId: number): Promise<void> {
        return this.httpClient.post<void>(`/api/v1/help/edit/${fileId}`, formData).toPromise();
    }

    downloadHelpFile(fileId: number): Promise<void> {
        return this.httpClient.get<void>(`/api/v1/help/${fileId}`,
            { responseType: 'blob' as 'json', observe: 'response' })
            .toPromise()
            .then(res => {
                try {
                    const contentDisposition = res.headers.get('content-disposition');
                    const fileName = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
                    saveAs(res.body, fileName.replace(/^\"+|\"+$/g, ''));
                } catch {
                    console.error('Couldn\'t save file');
                }
            });
    }

    deleteHelpFile(fileId: number): Promise<void> {
        return this.httpClient.delete<void>(`/api/v1/help/delete/${fileId}`).toPromise();
    }

    getFiles(): Promise<HelpFile[]> {
        return this.httpClient.get<HelpFile[]>('/api/v1/help/authority').toPromise();
    }
}

import { AmdbAccessCheckerService } from '@amdb/drone/@core/security/amdb-access-checker.service';
import { ExportHistoryService } from '@amdb/drone/@core/services/export-history.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NbTokenService } from '@nebular/auth';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { ConfirmDialogComponent } from '../../confirm-dialog/confirm-dialog.component';
import { ExportHistory } from '../export-history.model';

@Component({
  selector: 'export-history-actions',
  templateUrl: './export-history-actions.component.html',
  styleUrls: ['./export-history-actions.component.scss']
})
export class ExportHistoryActionsComponent implements OnInit {

  @Output()
  onDownloadStart: EventEmitter<any> = new EventEmitter();

  @Output()
  onDownloadEnd: EventEmitter<any> = new EventEmitter();

  @Output()
  onDeleteStart: EventEmitter<any> = new EventEmitter();

  @Output()
  onDeleteEnd: EventEmitter<any> = new EventEmitter();

  value: ExportHistory;
  isSuperAdmin: boolean = false;

  constructor(private exportHistoryService: ExportHistoryService,
              private toastrService: NbToastrService,
              private dialogService: NbDialogService) { }

  ngOnInit(): void {
  }

  async downloadReport(id: number){
    try {
      this.onDownloadStart.emit();
      await this.exportHistoryService.downloadReportById(id);
      this.onDownloadEnd.emit();
    } catch (err) {
      this.onDownloadEnd.emit();
      if(err instanceof HttpErrorResponse) {
        if(err.error) {
          this.toastrService.danger(err.error, 'Kļūda');
        } else {
          this.toastrService.danger('Kļūda lejupielādējot failu', 'Kļūda');
        }
      }
    }
  }

  // Delete functionality - commented out by request, might be needed to add it back later
  // deleteRecord(recordId: number) {
  //   const confirmDialogRef = this.dialogService.open(ConfirmDialogComponent, {
  //     context: {
  //       confirmationMsg: `Vai tiešām dzēst failu ${this.value.fileName}?`
  //     }
  //   });

  //   confirmDialogRef.onClose.subscribe(async (response) => {
  //     if(!response) {
  //       return;
  //     }
  //     this.onDeleteStart.emit();
  //     await this.exportHistoryService.deleteExport(recordId).then(() => {
  //       this.toastrService.success('Fails veiksmīgi dzēsts');
  //       this.onDeleteEnd.emit();
  //     }).catch((error) => {
  //       this.onDeleteEnd.emit();
  //       this.toastrService.danger(error.error, 'Kļūda');
  //     });
  //   });
  // }

}

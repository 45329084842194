<nb-card size="giant" [nbSpinner]="!dataInitialized">
    <nb-card-header>
        <h5 *ngIf="edit">Labot iesniegumu</h5>
        <h5 *ngIf="!edit">Pievienot iesniegumu</h5>
    </nb-card-header>
    <nb-card-body>
        <nb-alert outline="info" role="info">
            <span>
                Dialoga logā automātiski ir aizpildīti tikai tie datu lauki, kas atbilst specifikācijai!
            </span>
            <span>Obligātie lauki ir atzīmēti ar <span class="error-message required">*</span></span>
        </nb-alert>
        <form #form="ngForm" aria-labelledby="title" class="col-md-12 col-lg-12">
            <div *ngIf="allFields">
                <div class="row">
                    <!-- JSON FILE INFORMATION COLUMN -->
                    <div class="col-md-6 col-lg-6">
                        <h6>UAS ģeogrāfiskās zonas dati</h6>
                        <div class="row">
                            <div class="form-control-group mt-1 col-sm-6">
                                <label class="label" for="uasZoneVersionName">Pieteikuma nosaukums <span class="error-message required">*</span></label>
                                <input
                                    nbInput
                                    [(ngModel)]="submission.uasZoneVersion.name"
                                    #uasZoneVersionName="ngModel"
                                    name="uasZoneVersionName"
                                    fullWidth
                                    [status]="uasZoneVersionName.touched ? (uasZoneVersionName.invalid ? 'danger' : 'success') : 'primary'"
                                    [required]="true"
                                    [maxlength]="200"
                                    [attr.aria-invalid]="uasZoneVersionName.invalid && uasZoneVersionName.touched ? true : null"
                                />
                                <ng-container *ngIf="uasZoneVersionName.invalid && uasZoneVersionName.touched">
                                    <p class="error-message" *ngIf="uasZoneVersionName.errors?.required">
                                        Lauks ir obligāts!
                                    </p>
                                </ng-container>
                            </div>

                            <div class="form-control-group mt-1 col-sm-6" *ngIf="regions">
                                <label class="label" for="uasZoneVersionRegion">Reģions</label>
                                <nb-select
                                    [(ngModel)]="submission.uasZoneVersion.regionId"
                                    #uasZoneVersionRegion="ngModel"
                                    name="uasZoneVersionRegion"
                                    fullWidth
                                    [status]="uasZoneVersionRegion.touched ? (uasZoneVersionRegion.invalid ? 'danger' : 'success') : 'primary'"
                                    [attr.aria-invalid]="uasZoneVersionRegion.invalid && uasZoneVersionRegion.touched ? true : null"
                                >
                                    <nb-option *ngFor="let region of regions" [value]="region.id">{{ region.name }}</nb-option>
                                </nb-select>
                                <ng-container *ngIf="uasZoneVersionRegion.invalid && uasZoneVersionRegion.touched">
                                    <p class="error-message" *ngIf="uasZoneVersionRegion.errors?.required">
                                        Lauks ir obligāts!
                                    </p>
                                </ng-container>
                            </div>
                        </div>

                        <div class="row">
                            <div class="form-control-group mt-1 col-sm-6" *ngIf="zoneTypes">
                                <label class="label" for="uasZoneVersionType">Zonas tipa kods <span class="error-message required">*</span></label>
                                <nb-select
                                    [(ngModel)]="submission.uasZoneVersion.typeId"
                                    #uasZoneVersionType="ngModel"
                                    name="uasZoneVersionType"
                                    fullWidth
                                    [status]="uasZoneVersionType.touched ? (uasZoneVersionType.invalid ? 'danger' : 'success') : 'primary'"
                                    [required]="true"
                                    [selected]="null"
                                    [attr.aria-invalid]="uasZoneVersionType.invalid && uasZoneVersionType.touched ? true : null"
                                >
                                    <nb-option *ngFor="let type of zoneTypes" [value]="type.id">{{ type.name }}</nb-option>
                                </nb-select>
                                <ng-container *ngIf="uasZoneVersionType.invalid && uasZoneVersionType.touched">
                                    <p class="error-message" *ngIf="uasZoneVersionType.errors?.required">
                                        Lauks ir obligāts!
                                    </p>
                                </ng-container>
                            </div>

                            <div class="form-control-group mt-1 col-sm-6" *ngIf="uasZoneVersionRestrictions">
                                <label class="label" for="uasZoneVersionRestrictionId">Zonas ierobežojuma veida tips <span class="error-message required">*</span></label>
                                <nb-select
                                    [(ngModel)]="submission.uasZoneVersion.restrictionId"
                                    (selectedChange)="updatePossibleAuthorityPurposes(true)"
                                    #uasZoneVersionRestrictionId="ngModel"
                                    name="uasZoneVersionRestrictionId"
                                    fullWidth
                                    [required]="true"
                                    [status]="uasZoneVersionRestrictionId.touched ? (uasZoneVersionRestrictionId.invalid ? 'danger' : 'success') : 'primary'"
                                    [attr.aria-invalid]="uasZoneVersionRestrictionId.invalid && uasZoneVersionRestrictionId.touched ? true : null"
                                >
                                    <nb-option *ngFor="let restriction of uasZoneVersionRestrictions" [value]="restriction.id">{{ restriction.name }}</nb-option>
                                </nb-select>
                                <ng-container *ngIf="uasZoneVersionRestrictionId.invalid && uasZoneVersionRestrictionId.touched">
                                    <p class="error-message" *ngIf="uasZoneVersionRestrictionId.errors?.required">
                                        Lauks ir obligāts!
                                    </p>
                                </ng-container>
                            </div>
                        </div>

                        <div class="row">
                            <div class="form-control-group mt-1 col-sm-6" *ngIf="uasZoneVersionReasons">
                                <label class="label" for="uasZoneVersionReason">Zonas izveidošanas pamatojums <span class="error-message required">*</span></label>
                                <nb-select
                                    [(ngModel)]="currentZoneReasons"
                                    #uasZoneVersionReason="ngModel"
                                    multiple
                                    name="uasZoneVersionReason"
                                    fullWidth
                                    (selectedChange)="setReasons($event)"
                                    [status]="uasZoneVersionReason.touched ? (uasZoneVersionReason.invalid ? 'danger' : 'success') : 'primary'"
                                    [required]="true"
                                    [attr.aria-invalid]="uasZoneVersionReason.invalid && uasZoneVersionReason.touched ? true : null"
                                >
                                    <nb-option *ngFor="let reason of uasZoneVersionReasons" [value]="reason">{{ reason.name }}</nb-option>
                                </nb-select>
                                <ng-container *ngIf="uasZoneVersionReason.invalid && uasZoneVersionReason.touched">
                                    <p class="error-message" *ngIf="uasZoneVersionReason.errors?.required">
                                        Lauks ir obligāts!
                                    </p>
                                </ng-container>
                            </div>

                            <div *ngIf="yesNo" class="form-control-group mt-1 col-sm-6">
                                <label class="label" for="uasZoneVersionRegulationExemptionId">Regulējuma izņēmuma gadījumi <span class="error-message required">*</span></label>
                                <nb-select
                                    [(ngModel)]="submission.uasZoneVersion.regulationExemptionId"
                                    #uasZoneVersionRegulationExemptionId="ngModel"
                                    name="uasZoneVersionRegulationExemptionId"
                                    fullWidth
                                    [required]="true"
                                    [status]="uasZoneVersionRegulationExemptionId.touched ? (uasZoneVersionRegulationExemptionId.invalid ? 'danger' : 'success') : 'primary'"
                                    [attr.aria-invalid]="uasZoneVersionRegulationExemptionId.invalid && uasZoneVersionRegulationExemptionId.touched ? true : null"
                                >
                                    <nb-option *ngFor="let opt of yesNo" [value]="opt.id">{{ opt.name }}</nb-option>
                                </nb-select>
                                <ng-container *ngIf="uasZoneVersionRegulationExemptionId.invalid && uasZoneVersionRegulationExemptionId.touched">
                                    <p class="error-message" *ngIf="uasZoneVersionRegulationExemptionId.errors?.required">
                                        Lauks ir obligāts!
                                    </p>
                                </ng-container>
                            </div>
                        </div>

                        <div *ngIf="otherReasonSelected" class="mt-1">
                            <label class="label" for="uasZoneVersionOtherReasonInfo"
                                >Papildus iemesla informācija (jāaizpilda, ja izvēlēts OTHER iemesls) <span class="error-message required">*</span></label
                            >
                            <textarea
                                nbInput
                                [(ngModel)]="submission.uasZoneVersion.otherReasonInfo"
                                #uasZoneVersionOtherReasonInfo="ngModel"
                                name="uasZoneVersionOtherReasonInfo"
                                fullWidth
                                [status]="uasZoneVersionOtherReasonInfo.touched ? (uasZoneVersionOtherReasonInfo.invalid ? 'danger' : 'success') : 'primary'"
                                [required]="true"
                                [maxlength]="200"
                                [attr.aria-invalid]="uasZoneVersionOtherReasonInfo.invalid && uasZoneVersionOtherReasonInfo.touched ? true : null"
                            >
                            </textarea>
                            <ng-container *ngIf="uasZoneVersionOtherReasonInfo.invalid && uasZoneVersionOtherReasonInfo.touched">
                                <p class="error-message" *ngIf="uasZoneVersionOtherReasonInfo.errors?.required">
                                    Lauks ir obligāts!
                                </p>
                            </ng-container>
                        </div>

                        <div class="row">
                            <div class="mt-3 col-sm-6 form-control-group">
                                <div class="inline-button-container">
                                    <h6>Ierobežojuma nosacījumi</h6>
                                    <button nbButton status="success" class="sm-button" (click)="addRestrictionConditionField()"><i class="fa fa-plus"></i></button>
                                </div>
                                <div *ngFor="let field of restrictionConditionsArray; let i = index">
                                    <div class="inline-button-container centered mt-2">
                                        <input
                                            nbInput
                                            type="text"
                                            [(ngModel)]="field.value"
                                            name="restrictionCondition{{ field.id }}"
                                            #restrictionCondition="ngModel"
                                            fullWidth
                                            [status]="restrictionCondition.touched ? (restrictionCondition.invalid ? 'danger' : 'success') : 'primary'"
                                            [required]="true"
                                            [maxlength]="100"
                                            [attr.aria-invalid]="restrictionCondition.invalid && restrictionCondition.touched ? true : null"
                                        />
                                        <button nbButton status="danger" class="sm-button" (click)="removeRestrictionConditionField(i)"><i class="fa fa-minus"></i></button>
                                    </div>
                                    <ng-container *ngIf="restrictionCondition.invalid && restrictionCondition.touched">
                                        <p class="error-message" *ngIf="restrictionCondition.errors?.required">
                                            Lauks ir obligāts!
                                        </p>
                                    </ng-container>
                                </div>
                            </div>
                        </div>

                        <div class="mt-1">
                            <label class="label" for="uasZoneVersionMessage">Paziņojums tālvadības pilotam latviešu valodā (ierobežojumu apraksts, ne vairāk kā 200 simboli)</label>
                            <textarea
                                nbInput
                                [(ngModel)]="submission.uasZoneVersion.message"
                                #uasZoneVersionMessage="ngModel"
                                name="uasZoneVersionMessage"
                                fullWidth
                                [status]="uasZoneVersionMessage.touched ? (uasZoneVersionMessage.invalid ? 'danger' : 'success') : 'primary'"
                                [maxlength]="200"
                                [attr.aria-invalid]="uasZoneVersionMessage.invalid && uasZoneVersionMessage.touched ? true : null"
                            >
                            </textarea>
                        </div>

                        <div class="mt-1">
                            <label class="label" for="uasZoneVersionMessageEng">Paziņojums tālvadības pilotam angļu valodā (ierobežojumu apraksts, ne vairāk kā 200 simboli)</label>
                            <textarea
                                nbInput
                                [(ngModel)]="submission.uasZoneVersion.messageEng"
                                #uasZoneVersionMessageEng="ngModel"
                                name="uasZoneVersionMessageEng"
                                fullWidth
                                [status]="uasZoneVersionMessageEng.touched ? (uasZoneVersionMessageEng.invalid ? 'danger' : 'success') : 'primary'"
                                [maxlength]="200"
                                [attr.aria-invalid]="uasZoneVersionMessageEng.invalid && uasZoneVersionMessageEng.touched ? true : null"
                            >
                            </textarea>
                        </div>

                        <div class="mt-1">
                            <label class="label" for="uasZoneVersionAdditionalProperties">Papildus nosacījumi</label>
                            <textarea
                                nbInput
                                [(ngModel)]="submission.uasZoneVersion.additionalProperties"
                                #uasZoneVersionAdditionalProperties="ngModel"
                                name="uasZoneVersionAdditionalProperties"
                                fullWidth
                                [status]="uasZoneVersionAdditionalProperties.touched ? (uasZoneVersionAdditionalProperties.invalid ? 'danger' : 'success') : 'primary'"
                                [maxlength]="1000"
                                [attr.aria-invalid]="uasZoneVersionAdditionalProperties.invalid && uasZoneVersionAdditionalProperties.touched ? true : null"
                            >
                            </textarea>
                        </div>

                        <div class="row">
                            <div class="form-control-group mt-1 col-sm-6" *ngIf="uomDimensions">
                                <label class="label" for="airspaceVolumeUomDimensions">Dimensijas (ierobežojuma) mērvienība vertikāla telpā <span class="error-message required">*</span> </label>
                                <nb-select
                                    [(ngModel)]="submission.uasZoneVersion.airspaceVolume.uomDimensionsId"
                                    #airspaceVolumeUomDimensions="ngModel"
                                    name="airspaceVolumeUomDimensions"
                                    fullWidth
                                    [status]="airspaceVolumeUomDimensions.touched ? (airspaceVolumeUomDimensions.invalid ? 'danger' : 'success') : 'primary'"
                                    [required]="true"
                                    [attr.aria-invalid]="airspaceVolumeUomDimensions.invalid && airspaceVolumeUomDimensions.touched ? true : null"
                                >
                                    <nb-option *ngFor="let dimension of uomDimensions" [value]="dimension.id">{{ dimension.name }}</nb-option>
                                </nb-select>
                                <ng-container *ngIf="airspaceVolumeUomDimensions.invalid && airspaceVolumeUomDimensions.touched">
                                    <p class="error-message" *ngIf="airspaceVolumeUomDimensions.errors?.required">
                                        Lauks ir obligāts!
                                    </p>
                                </ng-container>
                            </div>
                        </div>

                        <div class="row">
                            <div class="form-control-group mt-1 col-sm-6">
                                <label class="label" for="airspaceVolumeLowerLimit" *ngIf="submission.uasZoneVersion.airspaceVolume.uomDimensionsId == 1201"
                                    >Apakšējā robeža (M) <span class="error-message required">*</span></label
                                >
                                <label class="label" for="airspaceVolumeLowerLimit" *ngIf="submission.uasZoneVersion.airspaceVolume.uomDimensionsId == 1202"
                                    >Apakšējā robeža (FT) <span class="error-message required">*</span></label
                                >
                                <input
                                    type="number"
                                    nbInput
                                    [(ngModel)]="submission.uasZoneVersion.airspaceVolume.lowerLimit"
                                    #airspaceVolumeLowerLimit="ngModel"
                                    name="airspaceVolumeLowerLimit"
                                    fullWidth
                                    [status]="airspaceVolumeLowerLimit.touched ? (airspaceVolumeLowerLimit.invalid ? 'danger' : 'success') : 'primary'"
                                    [required]="true"
                                    min="0"
                                    [airspaceVolumeLimitsValidator]="getFormControlByName('airspaceVolumeUpperLimit')"
                                    [isLower]="true"
                                    pattern="^[0-9]*$"
                                    step="1"
                                    [attr.aria-invalid]="airspaceVolumeLowerLimit.invalid && airspaceVolumeLowerLimit.touched ? true : null"
                                />
                                <ng-container *ngIf="airspaceVolumeLowerLimit.invalid && airspaceVolumeLowerLimit.touched">
                                    <p class="error-message" *ngIf="airspaceVolumeLowerLimit?.errors?.required">
                                        Lauks ir obligāts!
                                    </p>
                                    <p class="error-message" *ngIf="airspaceVolumeLowerLimit.errors?.pattern">
                                        Atļauti tikai veseli skaitļi
                                    </p>
                                    <p class="error-message" *ngIf="airspaceVolumeLowerLimit.errors?.airspaceVolumeLimits">
                                        Apakšējai robežai jābūt mazākai par augšējo
                                    </p>
                                </ng-container>
                            </div>

                            <div class="form-control-group mt-1 col-sm-6" *ngIf="airspaceVolumeLimitVerticalReferences">
                                <label class="label" for="airspaceVolumeLowerVerticalReference" nbTooltip="7. Raksturojošie fiziskie parametri: Apakšējā robeža"
                                    >Apakšējās robežas atskaites sistēma <span class="error-message required">*</span></label
                                >
                                <nb-select
                                    [(ngModel)]="submission.uasZoneVersion.airspaceVolume.lowerVerticalReferenceId"
                                    #airspaceVolumeLowerVerticalReference="ngModel"
                                    name="airspaceVolumeLowerVerticalReference"
                                    fullWidth
                                    [status]="airspaceVolumeLowerVerticalReference.touched ? (airspaceVolumeLowerVerticalReference.invalid ? 'danger' : 'success') : 'primary'"
                                    [required]="true"
                                    [attr.aria-invalid]="airspaceVolumeLowerVerticalReference.invalid && airspaceVolumeLowerVerticalReference.touched ? true : null"
                                >
                                    <nb-option *ngFor="let verticalReference of airspaceVolumeLimitVerticalReferences" [value]="verticalReference.id">{{ verticalReference.name }}</nb-option>
                                </nb-select>
                                <ng-container *ngIf="airspaceVolumeLowerVerticalReference.invalid && airspaceVolumeLowerVerticalReference.touched">
                                    <p class="error-message" *ngIf="airspaceVolumeLowerVerticalReference.errors?.required">
                                        Lauks ir obligāts!
                                    </p>
                                </ng-container>
                            </div>
                        </div>

                        <div class="row">
                            <div class="form-control-group mt-1 col-sm-6">
                                <label
                                    class="label"
                                    for="airspaceVolumeUpperLimit"
                                    nbTooltip="7. Raksturojošie fiziskie parametri: Augšējā robeža"
                                    *ngIf="submission.uasZoneVersion.airspaceVolume.uomDimensionsId == 1201"
                                    >Augšējā robeža (M) <span class="error-message required">*</span></label
                                >
                                <label
                                    class="label"
                                    for="airspaceVolumeUpperLimit"
                                    nbTooltip="7. Raksturojošie fiziskie parametri: Augšējā robeža"
                                    *ngIf="submission.uasZoneVersion.airspaceVolume.uomDimensionsId == 1202"
                                    >Augšējā robeža (FT) <span class="error-message required">*</span></label
                                >
                                <input
                                    type="number"
                                    nbInput
                                    [(ngModel)]="submission.uasZoneVersion.airspaceVolume.upperLimit"
                                    #airspaceVolumeUpperLimit="ngModel"
                                    name="airspaceVolumeUpperLimit"
                                    fullWidth
                                    [status]="airspaceVolumeUpperLimit.touched ? (airspaceVolumeUpperLimit.invalid ? 'danger' : 'success') : 'primary'"
                                    [required]="true"
                                    pattern="^[0-9]*$"
                                    min="0"
                                    [airspaceVolumeLimitsValidator]="getFormControlByName('airspaceVolumeLowerLimit')"
                                    [isUpper]="true"
                                    step="1"
                                    [attr.aria-invalid]="airspaceVolumeUpperLimit.invalid && airspaceVolumeUpperLimit.touched ? true : null"
                                />
                                <ng-container *ngIf="airspaceVolumeUpperLimit.invalid && airspaceVolumeUpperLimit.touched">
                                    <p class="error-message" *ngIf="airspaceVolumeUpperLimit?.errors?.required">
                                        Lauks ir obligāts!
                                    </p>
                                    <p class="error-message" *ngIf="airspaceVolumeUpperLimit.errors?.pattern">
                                        Atļauti tikai veseli skaitļi
                                    </p>
                                    <p class="error-message" *ngIf="airspaceVolumeUpperLimit.errors?.airspaceVolumeLimits">
                                        Augšējai robežai ir jābūt lielākai par apakšējo
                                    </p>
                                </ng-container>
                            </div>

                            <div class="form-control-group mt-1 col-sm-6" *ngIf="airspaceVolumeLimitVerticalReferences">
                                <label class="label" for="airspaceVolumeUpperVerticalReference">Augšējās robežas atskaites sistēma <span class="error-message required">*</span></label>
                                <nb-select
                                    [(ngModel)]="submission.uasZoneVersion.airspaceVolume.upperVerticalReferenceId"
                                    #airspaceVolumeUpperVerticalReference="ngModel"
                                    name="airspaceVolumeUpperVerticalReference"
                                    fullWidth
                                    [status]="airspaceVolumeUpperVerticalReference.touched ? (airspaceVolumeUpperVerticalReference.invalid ? 'danger' : 'success') : 'primary'"
                                    [required]="true"
                                    [attr.aria-invalid]="airspaceVolumeUpperVerticalReference.invalid && airspaceVolumeUpperVerticalReference.touched ? true : null"
                                >
                                    <nb-option *ngFor="let verticalReference of airspaceVolumeLimitVerticalReferences" [value]="verticalReference.id">{{ verticalReference.name }}</nb-option>
                                </nb-select>
                                <ng-container *ngIf="airspaceVolumeUpperVerticalReference.invalid && airspaceVolumeUpperVerticalReference.touched">
                                    <p class="error-message" *ngIf="airspaceVolumeUpperVerticalReference.errors?.required">
                                        Lauks ir obligāts!
                                    </p>
                                </ng-container>
                            </div>
                        </div>

                        <div class="row">
                            <div *ngIf="!hasGtseUuid" class="form-control-group mt-1 col-sm-6">
                                <label class="label" for="airspaceVolumeHorizontalLimit" nbTooltip="7. Raksturojošie fiziskie parametri: Horizintālais limits"
                                    >Horizontālā robeža (M) (ģeogrāfiskā zona ap objektu) <span class="error-message required">*</span></label
                                >
                                <input
                                    type="number"
                                    nbInput
                                    [(ngModel)]="submission.uasZoneVersion.airspaceVolume.horizontalLimit"
                                    #airspaceVolumeHorizontalLimit="ngModel"
                                    name="airspaceVolumeHorizontalLimit"
                                    fullWidth
                                    pattern="^[0-9]*$"
                                    [status]="airspaceVolumeHorizontalLimit.touched ? (airspaceVolumeHorizontalLimit.invalid ? 'danger' : 'success') : 'primary'"
                                    [required]="true"
                                    [disabled]="editAfterApproval"
                                    min="0"
                                    step="1"
                                    max="900000"
                                    maxValue="{{ 900000 }}"
                                    [maxCanBeEqual]="true"
                                    [attr.aria-invalid]="airspaceVolumeHorizontalLimit.invalid && airspaceVolumeHorizontalLimit.touched ? true : null"
                                />
                                <ng-container *ngIf="airspaceVolumeHorizontalLimit.invalid && airspaceVolumeHorizontalLimit.touched">
                                    <p class="error-message" *ngIf="airspaceVolumeHorizontalLimit?.errors?.required">
                                        Lauks ir obligāts!
                                    </p>
                                    <p class="error-message" *ngIf="airspaceVolumeHorizontalLimit.errors?.pattern">
                                        Atļauti tikai veseli skaitļi
                                    </p>
                                    <p class="error-message" *ngIf="airspaceVolumeHorizontalLimit.errors?.maxValue">
                                        Horizontālā robeža nedrīkst būt lielāka par 900000m
                                    </p>
                                </ng-container>
                            </div>

                            <div class="form-control-group mt-1 col-sm-6" *ngIf="airspaceVolumeCoordinateSystems">
                                <label class="label" for="airspaceVolumeCoordinateSystem">Koordinātu sistēma <span class="error-message required">*</span></label>
                                <nb-select
                                    [(ngModel)]="submission.uasZoneVersion.airspaceVolume.coordinateSystemId"
                                    #airspaceVolumeCoordinateSystem="ngModel"
                                    name="airspaceVolumeCoordinateSystem"
                                    fullWidth
                                    disabled
                                    [status]="airspaceVolumeCoordinateSystem.touched ? (airspaceVolumeCoordinateSystem.invalid ? 'danger' : 'success') : 'primary'"
                                    [required]="true"
                                    [attr.aria-invalid]="airspaceVolumeCoordinateSystem.invalid && airspaceVolumeCoordinateSystem.touched ? true : null"
                                >
                                    <nb-option *ngFor="let coordinateSystem of airspaceVolumeCoordinateSystems" [value]="coordinateSystem.id">{{ coordinateSystem.name }}</nb-option>
                                </nb-select>
                                <ng-container *ngIf="airspaceVolumeCoordinateSystem.invalid && airspaceVolumeCoordinateSystem.touched">
                                    <p class="error-message" *ngIf="airspaceVolumeCoordinateSystem.errors?.required">
                                        Lauks ir obligāts!
                                    </p>
                                </ng-container>
                            </div>
                        </div>
                    </div>

                    <!-- MK26 INFORMATION COLUMN -->
                    <div class="col-md-6 col-lg-6">
                        <h6>MK 26 noteikumu 1. pielikuma lauki</h6>
                        <div class="row">
                            <div class="form-control-group mt-1 col-sm-6">
                                <label class="label" for="submissionReasonNr">Iesniedzēja lēmuma numurs <span class="error-message required">*</span></label>
                                <input
                                    nbInput
                                    [(ngModel)]="submission.reasonNr"
                                    #submissionReasonNr="ngModel"
                                    name="submissionReasonNr"
                                    fullWidth
                                    [status]="submissionReasonNr.touched ? (submissionReasonNr.invalid ? 'danger' : 'success') : 'primary'"
                                    [required]="true"
                                    [maxlength]="30"
                                    [attr.aria-invalid]="submissionReasonNr.invalid && submissionReasonNr.touched ? true : null"
                                />
                                <ng-container *ngIf="submissionReasonNr.invalid && submissionReasonNr.touched">
                                    <p class="error-message" *ngIf="submissionReasonNr.errors?.required">
                                        Lauks ir obligāts!
                                    </p>
                                </ng-container>
                            </div>

                            <div class="form-control-group mt-1 col-sm-6">
                                <label class="label" for="submissionReasonDate">Iesniedzēja lēmuma datums (UTC) <span class="error-message required">*</span></label>
                                <input
                                    nbInput
                                    [(ngModel)]="submission.reasonDate"
                                    #submissionReasonDate="ngModel"
                                    name="submissionReasonDate"
                                    fullWidth
                                    [status]="submissionReasonDate.touched ? (submissionReasonDate.invalid ? 'danger' : 'success') : 'primary'"
                                    [required]="true"
                                    [maxlength]="30"
                                    [nbDatepicker]="pickerReasonDate"
                                    [attr.aria-invalid]="submissionReasonDate.invalid && submissionReasonDate.touched ? true : null"
                                />
                                <nb-datepicker #pickerReasonDate format="yyyy-MM-dd"></nb-datepicker>
                                <ng-container *ngIf="submissionReasonDate.invalid && submissionReasonDate.touched">
                                    <p class="error-message" *ngIf="submissionReasonDate.errors?.required">
                                        Lauks ir obligāts!
                                    </p>
                                </ng-container>
                            </div>
                        </div>

                        <div class="row">
                            <div class="form-control-group mt-1 col-sm-6">
                                <label class="label" for="uaszoneVersionCadNr">Kadastra numurs/apzīmējums</label>
                                <input
                                    nbInput
                                    [(ngModel)]="submission.uasZoneVersion.cadNr"
                                    #uaszoneVersionCadNr="ngModel"
                                    name="uaszoneVersionCadNr"
                                    fullWidth
                                    [status]="uaszoneVersionCadNr.touched ? (uaszoneVersionCadNr.invalid ? 'danger' : 'success') : 'primary'"
                                    [maxlength]="255"
                                />
                            </div>

                            <div class="mt-1">
                                <label class="label" for="uasZoneVersionDescription">Objekta apraksts, adrese <span class="error-message required">*</span></label>
                                <textarea
                                    nbInput
                                    [(ngModel)]="submission.uasZoneVersion.description"
                                    #uasZoneVersionDescription="ngModel"
                                    name="uasZoneVersionDescription"
                                    fullWidth
                                    [status]="uasZoneVersionDescription.touched ? (uasZoneVersionDescription.invalid ? 'danger' : 'success') : 'primary'"
                                    [required]="true"
                                    [maxlength]="1000"
                                    [attr.aria-invalid]="uasZoneVersionDescription.invalid && uasZoneVersionDescription.touched ? true : null"
                                >
                                </textarea>
                                <ng-container *ngIf="uasZoneVersionDescription.invalid && uasZoneVersionDescription.touched">
                                    <p class="error-message" *ngIf="uasZoneVersionDescription.errors?.required">
                                        Lauks ir obligāts!
                                    </p>
                                </ng-container>
                            </div>
                        </div>

                        <div class="row">
                            <div class="form-control-group mt-1 col-sm-6" *ngIf="submissionPurposes">
                                <label class="label" for="submissionPuroseId" nbTooltip="4. Esošās situācijas apraksts, izmaiņu ieviešanas pamatojums un mērķis"
                                    >Nolūks <span class="error-message required">*</span></label
                                >
                                <nb-select
                                    [(ngModel)]="submission.purposeId"
                                    #submissionPuroseId="ngModel"
                                    name="submissionPuroseId"
                                    fullWidth
                                    [required]="true"
                                    [status]="submissionPuroseId.touched ? (submissionPuroseId.invalid ? 'danger' : 'success') : 'primary'"
                                    [attr.aria-invalid]="submissionPuroseId.invalid && submissionPuroseId.touched ? true : null"
                                >
                                    <nb-option *ngFor="let purpose of submissionPurposes" [value]="purpose.id">{{ purpose.name }}</nb-option>
                                </nb-select>
                                <ng-container *ngIf="submissionPuroseId.invalid && submissionPuroseId.touched">
                                    <p class="error-message" *ngIf="submissionPuroseId.errors?.required">
                                        Lauks ir obligāts!
                                    </p>
                                </ng-container>
                            </div>

                            <div class="form-control-group mt-1 col-sm-6" *ngIf="elements">
                                <label class="label" nbTooltip="5. Gaisa telpas struktūras elements, kurā ievieš izmaiņas">Gaisa telpas elements <span class="error-message required">*</span></label>
                                <nb-select
                                    [(ngModel)]="submission.elementId"
                                    #submissionElement="ngModel"
                                    name="submissionElement"
                                    fullWidth
                                    [status]="submissionElement.touched ? (submissionElement.invalid ? 'danger' : 'success') : 'primary'"
                                    [required]="true"
                                    [attr.aria-invalid]="submissionElement.invalid && submissionElement.touched ? true : null"
                                >
                                    <nb-option *ngFor="let element of elements" [value]="element.id">{{ element.name }}</nb-option>
                                </nb-select>
                                <ng-container *ngIf="submissionElement.invalid && submissionElement.touched">
                                    <p class="error-message" *ngIf="submissionElement.errors?.required">
                                        Lauks ir obligāts!
                                    </p>
                                </ng-container>
                            </div>
                        </div>

                        <div class="row">
                            <div *ngIf="yesNo" class="form-control-group mt-1 col-sm-6">
                                <label class="label" nbTooltip="6. Spēkā esošās procedūras gaisa telpas struktūras elementa izmantošanai"
                                    >Procedūras <span class="error-message required">*</span>
                                </label>
                                <nb-select
                                    [(ngModel)]="submission.flightProcedureExistsId"
                                    #flightProcedureExistsId="ngModel"
                                    name="flightProcedureExistsId"
                                    fullWidth
                                    [status]="flightProcedureExistsId.touched ? (flightProcedureExistsId.invalid ? 'danger' : 'success') : 'primary'"
                                    [required]="true"
                                    [attr.aria-invalid]="flightProcedureExistsId.invalid && flightProcedureExistsId.touched ? true : null"
                                >
                                    <nb-option *ngFor="let opt of yesNo" [value]="opt.id">{{ opt.name }}</nb-option>
                                </nb-select>
                                <ng-container *ngIf="flightProcedureExistsId.invalid && flightProcedureExistsId.touched">
                                    <p class="error-message" *ngIf="flightProcedureExistsId.errors?.required">
                                        Lauks ir obligāts!
                                    </p>
                                </ng-container>
                            </div>

                            <div class="form-control-group mt-1 col-sm-6" *ngIf="yesNo">
                                <label class="label" for="submissionConsultingId">9. Konsultācijas ar iesaistītajām pusēm <span class="error-message required">*</span></label>
                                <nb-select
                                    [(ngModel)]="submission.consultingId"
                                    #submissionConsultingId="ngModel"
                                    name="submissionConsultingId"
                                    fullWidth
                                    [status]="submissionConsultingId.touched ? (submissionConsultingId.invalid ? 'danger' : 'success') : 'primary'"
                                    [required]="true"
                                    [attr.aria-invalid]="submissionConsultingId.invalid && submissionConsultingId.touched ? true : null"
                                >
                                    <nb-option *ngFor="let opt of yesNo" [value]="opt.id">{{ opt.name }}</nb-option>
                                </nb-select>
                                <ng-container *ngIf="submissionConsultingId.invalid && submissionConsultingId.touched">
                                    <p class="error-message" *ngIf="submissionConsultingId.errors?.required">
                                        Lauks ir obligāts!
                                    </p>
                                </ng-container>
                            </div>
                        </div>

                        <div class="row">
                            <div class="form-control-group mt-1 self-end col-sm-6" *ngIf="yesNo">
                                <label class="label" for="safetyId">10. Veikts drošības novērtējums <span class="error-message required">*</span></label>
                                <nb-select
                                    [(ngModel)]="submission.safetyId"
                                    #safetyId="ngModel"
                                    name="safetyId"
                                    fullWidth
                                    [status]="safetyId.touched ? (safetyId.invalid ? 'danger' : 'success') : 'primary'"
                                    [required]="true"
                                    [attr.aria-invalid]="safetyId.invalid && safetyId.touched ? true : null"
                                >
                                    <nb-option *ngFor="let opt of yesNo" [value]="opt.id">{{ opt.name }}</nb-option>
                                </nb-select>
                                <ng-container *ngIf="safetyId.invalid && safetyId.touched">
                                    <p class="error-message" *ngIf="safetyId.errors?.required">
                                        Lauks ir obligāts!
                                    </p>
                                </ng-container>
                            </div>

                            <div class="form-control-group mt-1 col-sm-6" *ngIf="yesNo">
                                <label class="label" for="submissionAdditionalEquipmentId"
                                    >11. Izmaiņu ieviešana nodrošināta ar aeronavigācijas, komunikāciju un meteoroloģiskajiem līdzekļiem <span class="error-message required">*</span></label
                                >
                                <nb-select
                                    [(ngModel)]="submission.additionalEquipmentId"
                                    #submissionAdditionalEquipmentId="ngModel"
                                    name="submissionAdditionalEquipmentId"
                                    fullWidth
                                    [status]="submissionAdditionalEquipmentId.touched ? (submissionAdditionalEquipmentId.invalid ? 'danger' : 'success') : 'primary'"
                                    [required]="true"
                                    [attr.aria-invalid]="submissionAdditionalEquipmentId.invalid && submissionAdditionalEquipmentId.touched ? true : null"
                                >
                                    <nb-option *ngFor="let opt of yesNo" [value]="opt.id">{{ opt.name }}</nb-option>
                                </nb-select>
                                <ng-container *ngIf="submissionAdditionalEquipmentId.invalid && submissionAdditionalEquipmentId.touched">
                                    <p class="error-message" *ngIf="submissionAdditionalEquipmentId.errors?.required">
                                        Lauks ir obligāts!
                                    </p>
                                </ng-container>
                            </div>
                        </div>

                        <div class="mt-1">
                            <label class="label" for="uasZoneVersionFlightProcedure">Lidojuma saskaņošanas procedūra atbilstoši iesniegumu izskatīšanas kārtībai, ja nav noteikts savādāk</label>
                            <textarea
                                nbInput
                                [(ngModel)]="submission.uasZoneVersion.flightProcedure"
                                #uasZoneVersionFlightProcedure="ngModel"
                                name="uasZoneVersionFlightProcedure"
                                fullWidth
                                [status]="uasZoneVersionFlightProcedure.touched ? (uasZoneVersionFlightProcedure.invalid ? 'danger' : 'success') : 'primary'"
                                [maxlength]="1000"
                                [attr.aria-invalid]="uasZoneVersionFlightProcedure.invalid && uasZoneVersionFlightProcedure.touched ? true : null"
                            >
                            </textarea>
                        </div>

                        <div class="row">
                            <div class="form-control-group mt-1 col-sm-6" *ngIf="yesNo">
                                <label class="label" for="submissionUazImpactId">Izmaiņu ietekme uz gaisa satiksmi nekontrolējamā gaisa telpā <span class="error-message required">*</span> </label>
                                <nb-select
                                    [(ngModel)]="submission.uazImpactId"
                                    #submissionUazImpactId="ngModel"
                                    name="submissionUazImpactId"
                                    fullWidth
                                    [status]="submissionUazImpactId.touched ? (submissionUazImpactId.invalid ? 'danger' : 'success') : 'primary'"
                                    [required]="true"
                                    [attr.aria-invalid]="submissionUazImpactId.invalid && submissionUazImpactId.touched ? true : null"
                                >
                                    <nb-option *ngFor="let opt of yesNo" [value]="opt.id">{{ opt.name }}</nb-option>
                                </nb-select>
                                <ng-container *ngIf="submissionUazImpactId.invalid && submissionUazImpactId.touched">
                                    <p class="error-message" *ngIf="submissionUazImpactId.errors?.required">
                                        Lauks ir obligāts!
                                    </p>
                                </ng-container>
                            </div>

                            <div *ngIf="yesNo" class="form-control-group mt-1 col-sm-6">
                                <label class="label" for="submissionAdditionalElementId">13. Cita informācija par gaisa telpas struktūras elementu <span class="error-message required">*</span></label>
                                <nb-select
                                    [(ngModel)]="submission.additionalElementId"
                                    #submissionAdditionalElementId="ngModel"
                                    name="submissionAdditionalElementId"
                                    fullWidth
                                    [required]="true"
                                    [status]="submissionAdditionalElementId.touched ? (submissionAdditionalElementId.invalid ? 'danger' : 'success') : 'primary'"
                                    [attr.aria-invalid]="submissionAdditionalElementId.invalid && submissionAdditionalElementId.touched ? true : null"
                                >
                                    <nb-option *ngFor="let opt of yesNo" [value]="opt.id">{{ opt.name }}</nb-option>
                                </nb-select>
                                <ng-container *ngIf="submissionAdditionalElementId.invalid && submissionAdditionalElementId.touched">
                                    <p class="error-message" *ngIf="submissionAdditionalElementId.errors?.required">
                                        Lauks ir obligāts!
                                    </p>
                                </ng-container>
                            </div>
                        </div>

                        <div class="mt-1">
                            <label class="label" for="uasZoneVersionNotes">Piezīmes</label>
                            <textarea
                                nbInput
                                [(ngModel)]="submission.uasZoneVersion.notes"
                                #uasZoneVersionNotes="ngModel"
                                name="uasZoneVersionNotes"
                                fullWidth
                                rows="5"
                                [status]="uasZoneVersionNotes.touched ? (uasZoneVersionNotes.invalid ? 'danger' : 'success') : 'primary'"
                                [maxlength]="1000"
                                [attr.aria-invalid]="uasZoneVersionNotes.invalid && uasZoneVersionNotes.touched ? true : null"
                            >
                            </textarea>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12 col-lg-12">
                        <!-- TIME PERIODS -->
                        <div class="row">
                            <!-- Time periods header -->
                            <div class="inline-button-container mt-3">
                                <h6>Zonas darbības periodi</h6>
                                <button nbButton status="success" class="sm-button" (click)="addTimePeriod()"><i class="fa fa-plus"></i></button>
                            </div>

                            <!-- Time period container -->
                            <div *ngFor="let period of timePeriodArray; let timePeriodIndex = index" class="input-block-container">
                                <div class="row">
                                    <!-- Time period => Start date time input -->
                                    <div class="form-control-group mt-1 col-sm-3">
                                        <label class="label" for="startDateTime">Spēkā no (UTC) <span class="error-message required">*</span></label>
                                        <input
                                            nbInput
                                            [(ngModel)]="period.timePeriod.startDateTime"
                                            #startDateTime="ngModel"
                                            name="startDateTime{{ period.id }}"
                                            fullWidth
                                            [status]="startDateTime.touched ? (startDateTime.invalid ? 'danger' : 'success') : 'primary'"
                                            [required]="true"
                                            [datesValidator]="getFormControlByName('endDateTime' + period.id)"
                                            [isStartDate]="true"
                                            [nbDatepicker]="pickerFrom"
                                            [attr.aria-invalid]="startDateTime.invalid && startDateTime.touched ? true : null"
                                        />
                                        <nb-date-timepicker #pickerFrom withSeconds format="yyyy-MM-dd HH:mm:ss"></nb-date-timepicker>
                                        <ng-container *ngIf="startDateTime.invalid && startDateTime.touched">
                                            <p class="error-message" *ngIf="startDateTime.errors?.required">
                                                Lauks ir obligāts!
                                            </p>
                                            <p class="error-message" *ngIf="startDateTime.errors?.startDateGreaterThanEndDate">
                                                Sākuma datumam jābūt agrākam par beigu datumu!
                                            </p>
                                        </ng-container>
                                    </div>

                                    <!-- Time period => End date time input -->
                                    <div class="form-control-group mt-1 col-sm-3">
                                        <label class="label" for="endDateTime">Spēkā līdz (UTC) <span *ngIf="timePeriodEndDateTimeRequired" class="error-message required">*</span></label>
                                        <input
                                            nbInput
                                            [(ngModel)]="period.timePeriod.endDateTime"
                                            #endDateTime="ngModel"
                                            name="endDateTime{{ period.id }}"
                                            fullWidth
                                            [datesValidator]="getFormControlByName('startDateTime' + period.id)"
                                            [isEndDate]="true"
                                            [status]="endDateTime.touched ? (endDateTime.invalid ? 'danger' : 'success') : 'primary'"
                                            [required]="timePeriodEndDateTimeRequired"
                                            [nbDatepicker]="pickerTo"
                                            [attr.aria-invalid]="endDateTime.invalid && endDateTime.touched ? true : null"
                                        />
                                        <nb-date-timepicker #pickerTo withSeconds format="yyyy-MM-dd HH:mm:ss"></nb-date-timepicker>
                                        <ng-container *ngIf="endDateTime.invalid && endDateTime.touched">
                                            <p class="error-message" *ngIf="endDateTime.errors?.startDateGreaterThanEndDate">
                                                Beigu datumam jābūt vēlākam par sākuma datumu
                                            </p>
                                        </ng-container>
                                    </div>

                                    <!-- RestrictionArea => IsSunsetSunriseZone checkbox -->
                                    <div class="form-control-group mt-4 col-sm-3">
                                        <nb-checkbox [(ngModel)]="submission.isSunsetSunriseZone" status="primary" name="period{{ period.id }}sunsetSunrise" (checkedChange)="modifySchedules()"
                                            >Zona aktīva, atkarībā no saules gaismas vai krēslas</nb-checkbox
                                        >
                                    </div>

                                    <!-- Schedules -->
                                    <div class="schedules-container">
                                        <!-- Schedules header -->
                                        <div class="inline-button-container mt-3">
                                            <h6 class="sub-header">Darbības perioda grafiki</h6>
                                            <button nbButton status="success" class="sm-button" (click)="addSchedule(timePeriodIndex)">
                                                <i class="fa fa-plus"></i>
                                            </button>
                                        </div>

                                        <!-- Schedules container -->
                                        <div *ngFor="let periodSchedule of period.schedules; let scheduleIndex = index">
                                            <div class="row">
                                                <!-- Schedule => Days input -->
                                                <div class="form-control-group mt-3 col-sm-3" *ngIf="weekdays">
                                                    <label class="label" for="scheduleWeekdays">Dienas <span class="error-message required">*</span></label>
                                                    <nb-select
                                                        [(ngModel)]="periodSchedule.schedule.day"
                                                        #scheduleWeekdays="ngModel"
                                                        multiple
                                                        name="scheduleWeekdays{{ periodSchedule.id }}"
                                                        fullWidth
                                                        (selectedChange)="checkScheduleDays(timePeriodIndex)"
                                                        [status]="scheduleWeekdays.touched ? (scheduleWeekdays.invalid ? 'danger' : 'success') : 'primary'"
                                                        [required]="true"
                                                        [attr.aria-invalid]="scheduleWeekdays.invalid && scheduleWeekdays.touched ? true : null"
                                                    >
                                                        <nb-option *ngFor="let day of weekdays" [value]="day.name">{{ day.name }}</nb-option>
                                                    </nb-select>
                                                    <ng-container *ngIf="scheduleWeekdays.invalid && scheduleWeekdays.touched">
                                                        <p class="error-message" *ngIf="scheduleWeekdays.errors?.required">
                                                            Lauks ir obligāts!
                                                        </p>
                                                    </ng-container>
                                                    <p class="error-message" *ngIf="periodSchedule.schedule.day && periodSchedule.schedule.day.indexOf('ANY') > -1">
                                                        Grafika dienās ir izvēlēts ANY klasifikators. JSON eksportā tiks uzrādīts tikai tas!
                                                    </p>
                                                </div>

                                                <!-- Schedule => Start time / event input -->
                                                <!-- If zone isSunsetSunrise zone, then startTime input becomes startEvent input. -->
                                                <div class="form-control-group mt-3 col-sm-3" *ngIf="!submission.isSunsetSunriseZone">
                                                    <label class="label" for="scheduleStartTime">Sākuma laiks (UTC) <span class="error-message required">*</span></label>
                                                    <input
                                                        nbInput
                                                        type="text"
                                                        [(ngModel)]="periodSchedule.schedule.startTime"
                                                        #scheduleStartTime="ngModel"
                                                        name="scheduleStartTime{{ periodSchedule.id }}"
                                                        fullWidth
                                                        [required]="true"
                                                        [startEndTimeValidator]="getFormControlByName('scheduleEndTime' + periodSchedule.id)"
                                                        [isStartTime]="true"
                                                        [status]="scheduleStartTime.dirty ? (scheduleStartTime.invalid ? 'danger' : 'success') : 'primary'"
                                                        [nbTimepicker]="startTimePicker"
                                                        [attr.aria-invalid]="scheduleStartTime.invalid && scheduleStartTime.dirty ? true : null"
                                                    />
                                                    <nb-timepicker #startTimePicker format="HH:mm"></nb-timepicker>
                                                    <ng-container *ngIf="scheduleStartTime.invalid && scheduleStartTime.dirty">
                                                        <p class="error-message" *ngIf="scheduleStartTime.errors?.required">
                                                            Lauks ir obligāts!
                                                        </p>
                                                        <p class="error-message" *ngIf="scheduleStartTime.errors?.startEndTimeInvalid">
                                                            Sākuma laikam ir jābūt agrākam par beigu laiku!
                                                        </p>
                                                    </ng-container>
                                                </div>

                                                <div class="form-control-group mt-3 col-sm-3" *ngIf="submission.isSunsetSunriseZone">
                                                    <label class="label" for="scheduleStartEvent">Sākuma notikums</label>
                                                    <nb-select
                                                        [(ngModel)]="periodSchedule.schedule.startEvent"
                                                        #scheduleStartEvent="ngModel"
                                                        name="scheduleStartEvent{{ periodSchedule.id }}"
                                                        fullWidth
                                                        (selectedChange)="updateStartEndEvents(timePeriodIndex, scheduleIndex, true)"
                                                        [status]="scheduleStartEvent.touched ? (scheduleStartEvent.invalid ? 'danger' : 'success') : 'primary'"
                                                        [required]="true"
                                                        [startEndEventValidator]="getFormControlByName('scheduleEndEvent' + periodSchedule.id)"
                                                        [attr.aria-invalid]="scheduleStartEvent.invalid && scheduleStartEvent.touched ? true : null"
                                                    >
                                                        <nb-option *ngFor="let event of sunriseSunsetEvents" [value]="event.name">{{ event.name }}</nb-option>
                                                    </nb-select>
                                                    <ng-container *ngIf="scheduleStartEvent.invalid && scheduleStartEvent.touched">
                                                        <p class="error-message" *ngIf="scheduleStartEvent.errors?.required">
                                                            Lauks ir obligāts!
                                                        </p>
                                                        <p class="error-message" *ngIf="scheduleStartEvent.errors?.startEndEventInvalid">
                                                            Sākuma un beigu notikumi nedrīkst būt vienādi
                                                        </p>
                                                    </ng-container>
                                                </div>

                                                <!-- Schedule => End time / event input -->
                                                <!-- If zone isSunsetSunrise zone, then endTime input  becomes startEvent input -->

                                                <div class="form-control-group mt-3 col-sm-3" *ngIf="!submission.isSunsetSunriseZone">
                                                    <label class="label" for="scheduleEndTime">Beigu laiks (UTC) <span class="error-message required">*</span></label>
                                                    <input
                                                        nbInput
                                                        type="text"
                                                        [(ngModel)]="periodSchedule.schedule.endTime"
                                                        #scheduleEndTime="ngModel"
                                                        name="scheduleEndTime{{ periodSchedule.id }}"
                                                        fullWidth
                                                        [required]="true"
                                                        [startEndTimeValidator]="getFormControlByName('scheduleStartTime' + periodSchedule.id)"
                                                        [isEndTime]="true"
                                                        [status]="scheduleEndTime.dirty ? (scheduleEndTime.invalid ? 'danger' : 'success') : 'primary'"
                                                        [nbTimepicker]="endTimePicker"
                                                        [attr.aria-invalid]="scheduleEndTime.invalid && scheduleEndTime.dirty ? true : null"
                                                    />
                                                    <nb-timepicker #endTimePicker format="HH:mm"></nb-timepicker>
                                                    <ng-container *ngIf="scheduleEndTime.invalid && scheduleEndTime.dirty">
                                                        <p class="error-message" *ngIf="scheduleEndTime.errors?.required">
                                                            Lauks ir obligāts!
                                                        </p>
                                                        <p class="error-message" *ngIf="scheduleEndTime.errors?.startEndTimeInvalid">
                                                            Beigu laikam ir jābūt vēlākam par sākuma laiku!
                                                        </p>
                                                    </ng-container>
                                                </div>

                                                <div class="form-control-group mt-3 col-sm-3" *ngIf="submission.isSunsetSunriseZone">
                                                    <label class="label" for="scheduleEndEvent">Beigu notikums</label>
                                                    <nb-select
                                                        [(ngModel)]="periodSchedule.schedule.endEvent"
                                                        #scheduleEndEvent="ngModel"
                                                        name="scheduleEndEvent{{ periodSchedule.id }}"
                                                        fullWidth
                                                        (selectedChange)="updateStartEndEvents(timePeriodIndex, scheduleIndex, false)"
                                                        [status]="scheduleEndEvent.touched ? (scheduleEndEvent.invalid ? 'danger' : 'success') : 'primary'"
                                                        [required]="true"
                                                        [startEndEventValidator]="getFormControlByName('scheduleStartEvent' + periodSchedule.id)"
                                                        [attr.aria-invalid]="scheduleEndEvent.invalid && scheduleEndEvent.touched ? true : null"
                                                    >
                                                        <nb-option *ngFor="let event of sunriseSunsetEvents" [value]="event.name">{{ event.name }}</nb-option>
                                                    </nb-select>
                                                    <ng-container *ngIf="scheduleEndEvent.invalid && scheduleEndEvent.touched">
                                                        <p class="error-message" *ngIf="scheduleEndEvent.errors?.required">
                                                            Lauks ir obligāts!
                                                        </p>
                                                        <p class="error-message" *ngIf="scheduleEndEvent.errors?.startEndEventInvalid">
                                                            Sākuma un beigu notikumi nedrīkst būt vienādi
                                                        </p>
                                                    </ng-container>
                                                </div>
                                            </div>

                                            <button
                                                nbButton
                                                outline
                                                status="danger"
                                                class="float-left mt-3"
                                                (click)="removeSchedule(timePeriodIndex, scheduleIndex)"
                                                *ngIf="!submission.isSunsetSunriseZone || period.schedules.length > 1"
                                            >
                                                Noņemt grafiku
                                            </button>
                                        </div>
                                    </div>
                                    <ng-container *ngIf="period.scheduleDaysOverlap">
                                        <p class="error-message mt-3">
                                            Laika perioda grafikos pārklājas izvēlētās dienas. Spēkā būs abi nosacījumi!
                                        </p>
                                    </ng-container>

                                    <button *ngIf="timePeriodArray.length > 1" nbButton status="danger" size="medium" class="float-left mt-3 mb-1" (click)="removeTimePeriod(timePeriodIndex)">
                                        Noņemt darbības periodu
                                    </button>
                                </div>
                            </div>
                        </div>

                        <!-- TO FLY AUTHORITIES -->
                        <div class="row">
                            <!-- ToFlyAuthorities header -->
                            <div class="inline-button-container mt-1">
                                <h6>Zonas kontaktpersonas lidošanai</h6>
                                <button nbButton status="success" class="sm-button" (click)="addToFlyAuthority()"><i class="fa fa-plus"></i></button>
                            </div>

                            <!-- ToFlyAuthorities container -->
                            <div *ngFor="let authority of toFlyAuthorityArray; let toFlyAuthorityIndex = index" class="input-block-container">
                                <div class="row">
                                    <!-- ToFlyAuthorityId select -->
                                    <div class="form-control-group mt-4 col-sm-3" *ngIf="toFlyAuthorities">
                                        <label class="label" for="toFlyAuthorityId">Kontaktpersona <span class="error-message required">*</span></label>
                                        <nb-select
                                            [(ngModel)]="authority.toFlyAuthority.toFlyAuthorityId"
                                            #toFlyAuthorityId="ngModel"
                                            name="toFlyAuthorityId{{ authority.id }}"
                                            fullWidth
                                            [required]="true"
                                            [status]="toFlyAuthorityId.touched ? (toFlyAuthorityId.invalid ? 'danger' : 'success') : 'primary'"
                                            [attr.aria-invalid]="toFlyAuthorityId.invalid && toFlyAuthorityId.touched ? true : null"
                                        >
                                            <nb-option *ngFor="let auth of toFlyAuthorities" [value]="auth.id">{{ auth.contactName ? auth.contactName : auth.name + ' ' + auth.service }}</nb-option>
                                        </nb-select>
                                        <ng-container *ngIf="toFlyAuthorityId.invalid && toFlyAuthorityId.touched">
                                            <p class="error-message" *ngIf="toFlyAuthorityId.errors?.required">
                                                Lauks ir obligāts!
                                            </p>
                                        </ng-container>
                                    </div>

                                    <!-- ToFlyAuthorityPurposeId select -->
                                    <!-- First authority uses firstAuthorityPurposes array, where purposes are filtered by zones reason type -->
                                    <div *ngIf="toFlyAuthorityIndex == 0" class="col-sm-3">
                                        <div class="form-control-group mt-4 col-sm-12" *ngIf="firstAuthorityPurposes">
                                            <label class="label" for="toFlyAuthorityPurposeId">Kontaktpersonas loma <span class="error-message required">*</span></label>
                                            <nb-select
                                                [(ngModel)]="authority.toFlyAuthority.purposeId"
                                                (selectedChange)="updateConditionalReasonRestAuthorityPurposes()"
                                                #toFlyAuthorityPurposeId="ngModel"
                                                name="toFlyAuthorityPurposeId{{ authority.id }}"
                                                fullWidth
                                                [required]="true"
                                                [status]="toFlyAuthorityPurposeId.touched ? (toFlyAuthorityPurposeId.invalid ? 'danger' : 'success') : 'primary'"
                                                [attr.aria-invalid]="toFlyAuthorityPurposeId.invalid && toFlyAuthorityPurposeId.touched ? true : null"
                                            >
                                                <nb-option *ngFor="let purpose of firstAuthorityPurposes" [value]="purpose.id">{{ purpose.name }}</nb-option>
                                            </nb-select>
                                            <ng-container *ngIf="toFlyAuthorityPurposeId.invalid && toFlyAuthorityPurposeId.touched">
                                                <p class="error-message" *ngIf="toFlyAuthorityPurposeId.errors?.required">
                                                    Lauks ir obligāts!
                                                </p>
                                            </ng-container>
                                        </div>
                                    </div>

                                    <!-- Rest of the authorities use restAuthorityPurposes array, where purposes are filtered by zone reason type (and in case with CONDITIONAL- by first authorities purpose) -->
                                    <div *ngIf="toFlyAuthorityIndex > 0" class="col-sm-3">
                                        <div class="form-control-group mt-4 col-sm-12" *ngIf="restAuthorityPurposes">
                                            <label class="label" for="toFlyAuthorityPurposeId">Kontaktpersonas loma <span class="error-message required">*</span></label>
                                            <nb-select
                                                [(ngModel)]="authority.toFlyAuthority.purposeId"
                                                #toFlyAuthorityPurposeId="ngModel"
                                                name="toFlyAuthorityPurposeId{{ authority.id }}"
                                                fullWidth
                                                [required]="true"
                                                [status]="toFlyAuthorityPurposeId.touched ? (toFlyAuthorityPurposeId.invalid ? 'danger' : 'success') : 'primary'"
                                                [attr.aria-invalid]="toFlyAuthorityPurposeId.invalid && toFlyAuthorityPurposeId.touched ? true : null"
                                            >
                                                <nb-option *ngFor="let purpose of restAuthorityPurposes" [value]="purpose.id">{{ purpose.name }}</nb-option>
                                            </nb-select>
                                            <ng-container *ngIf="toFlyAuthorityPurposeId.invalid && toFlyAuthorityPurposeId.touched">
                                                <p class="error-message" *ngIf="toFlyAuthorityPurposeId.errors?.required">
                                                    Lauks ir obligāts!
                                                </p>
                                            </ng-container>
                                        </div>
                                    </div>

                                    <!-- ToFlyAuthority => Interval before inputs for days, hours and minutes -->
                                    <div class="form-control-group col-sm-3" *ngIf="authority.toFlyAuthority.purposeId != 1403">
                                        <label class="label">Laiks saskaņojuma vai paziņojuma nosūtīšanai pirms plānotā lidojuma</label>
                                        <div class="interval-container">
                                            <!-- Days input -->
                                            <div class="form-control-group mt-1 col-sm-3">
                                                <label for="intervalBeforeDays" class="label">Dienas <span class="error-message required">*</span></label>
                                                <input
                                                    type="number"
                                                    nbInput
                                                    [(ngModel)]="authority.days"
                                                    #intervalBeforeDays="ngModel"
                                                    name="intervalBeforeDays{{ authority.id }}"
                                                    fullWidth
                                                    [status]="intervalBeforeDays.touched ? (intervalBeforeDays.invalid ? 'danger' : 'success') : 'primary'"
                                                    [required]="true"
                                                    min="0"
                                                    max="200"
                                                    pattern="^[0-9]*$"
                                                    step="1"
                                                    [attr.aria-invalid]="intervalBeforeDays.invalid && intervalBeforeDays.touched ? true : null"
                                                />
                                                <ng-container *ngIf="intervalBeforeDays.invalid && intervalBeforeDays.touched">
                                                    <p class="error-message" *ngIf="intervalBeforeDays?.errors?.required">
                                                        Lauks ir obligāts!
                                                    </p>
                                                    <p class="error-message" *ngIf="intervalBeforeDays.errors?.pattern">
                                                        Atļauti tikai veseli skaitļi
                                                    </p>
                                                </ng-container>
                                            </div>

                                            <!-- Hours input -->
                                            <div class="form-control-group mt-1 col-sm-3">
                                                <label for="intervalBeforeHours" class="label">Stundas <span class="error-message required">*</span></label>
                                                <input
                                                    type="number"
                                                    nbInput
                                                    [(ngModel)]="authority.hours"
                                                    #intervalBeforeHours="ngModel"
                                                    name="intervalBeforeHours{{ authority.id }}"
                                                    fullWidth
                                                    [status]="intervalBeforeHours.touched ? (intervalBeforeHours.invalid ? 'danger' : 'success') : 'primary'"
                                                    [required]="true"
                                                    min="0"
                                                    max="23"
                                                    pattern="^[0-9]*$"
                                                    step="1"
                                                    [attr.aria-invalid]="intervalBeforeHours.invalid && intervalBeforeHours.touched ? true : null"
                                                />
                                                <ng-container *ngIf="intervalBeforeHours.invalid && intervalBeforeHours.touched">
                                                    <p class="error-message" *ngIf="intervalBeforeHours?.errors?.required">
                                                        Lauks ir obligāts!
                                                    </p>
                                                    <p class="error-message" *ngIf="intervalBeforeHours.errors?.pattern">
                                                        Atļauti tikai veseli skaitļi
                                                    </p>
                                                </ng-container>
                                            </div>

                                            <!-- Minutes input -->
                                            <div class="form-control-group mt-1 col-sm-3">
                                                <label for="intervalBeforeMinutes" class="label">Minūtes <span class="error-message required">*</span></label>
                                                <input
                                                    type="number"
                                                    nbInput
                                                    [(ngModel)]="authority.minutes"
                                                    #intervalBeforeMinutes="ngModel"
                                                    name="intervalBeforeMinutes{{ authority.id }}"
                                                    fullWidth
                                                    [status]="intervalBeforeMinutes.touched ? (intervalBeforeMinutes.invalid ? 'danger' : 'success') : 'primary'"
                                                    [required]="true"
                                                    min="0"
                                                    max="59"
                                                    pattern="^[0-9]*$"
                                                    step="1"
                                                    [attr.aria-invalid]="intervalBeforeMinutes.invalid && intervalBeforeMinutes.touched ? true : null"
                                                />
                                                <ng-container *ngIf="intervalBeforeMinutes.invalid && intervalBeforeMinutes.touched">
                                                    <p class="error-message" *ngIf="intervalBeforeMinutes?.errors?.required">
                                                        Lauks ir obligāts!
                                                    </p>
                                                    <p class="error-message" *ngIf="intervalBeforeMinutes.errors?.pattern">
                                                        Atļauti tikai veseli skaitļi
                                                    </p>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>

                                    <button
                                        *ngIf="toFlyAuthorityArray.length > 1"
                                        nbButton
                                        status="danger"
                                        size="medium"
                                        class="float-left mt-3 mb-1"
                                        (click)="removeToFlyAuthority(toFlyAuthorityIndex)"
                                    >
                                        Noņemt kontaktpersonu
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="edit && !allFields">
                <div class="row">
                    <!-- ToFlyAuthorities header -->
                    <div class="inline-button-container mt-1">
                        <h6>Zonas kontaktpersonas lidošanai</h6>
                        <button nbButton status="success" class="sm-button" (click)="addToFlyAuthority()"><i class="fa fa-plus"></i></button>
                    </div>

                    <!-- ToFlyAuthorities container -->
                    <div *ngFor="let authority of toFlyAuthorityArray; let toFlyAuthorityIndex = index" class="input-block-container">
                        <div class="row">
                            <!-- ToFlyAuthorityId select -->
                            <div class="form-control-group mt-4 col-sm-3" *ngIf="toFlyAuthorities">
                                <label class="label" for="toFlyAuthorityId">Kontaktpersona <span class="error-message required">*</span></label>
                                <nb-select
                                    [(ngModel)]="authority.toFlyAuthority.toFlyAuthorityId"
                                    #toFlyAuthorityId="ngModel"
                                    name="toFlyAuthorityId{{ authority.id }}"
                                    fullWidth
                                    [required]="true"
                                    [status]="toFlyAuthorityId.touched ? (toFlyAuthorityId.invalid ? 'danger' : 'success') : 'primary'"
                                    [attr.aria-invalid]="toFlyAuthorityId.invalid && toFlyAuthorityId.touched ? true : null"
                                >
                                    <nb-option *ngFor="let auth of toFlyAuthorities" [value]="auth.id">{{ auth.contactName ? auth.contactName : auth.name }}</nb-option>
                                </nb-select>
                                <ng-container *ngIf="toFlyAuthorityId.invalid && toFlyAuthorityId.touched">
                                    <p class="error-message" *ngIf="toFlyAuthorityId.errors?.required">
                                        Lauks ir obligāts!
                                    </p>
                                </ng-container>
                            </div>

                            <!-- ToFlyAuthorityPurposeId select -->
                            <!-- First authority uses firstAuthorityPurposes array, where purposes are filtered by zones reason type -->
                            <div *ngIf="toFlyAuthorityIndex == 0" class="col-sm-3">
                                <div class="form-control-group mt-4 col-sm-12" *ngIf="firstAuthorityPurposes">
                                    <label class="label" for="toFlyAuthorityPurposeId">Kontaktpersonas mērķis <span class="error-message required">*</span></label>
                                    <nb-select
                                        [(ngModel)]="authority.toFlyAuthority.purposeId"
                                        #toFlyAuthorityPurposeId="ngModel"
                                        name="toFlyAuthorityPurposeId{{ authority.id }}"
                                        fullWidth
                                        [required]="true"
                                        [status]="toFlyAuthorityPurposeId.touched ? (toFlyAuthorityPurposeId.invalid ? 'danger' : 'success') : 'primary'"
                                        [attr.aria-invalid]="toFlyAuthorityPurposeId.invalid && toFlyAuthorityPurposeId.touched ? true : null"
                                    >
                                        <nb-option *ngFor="let purpose of firstAuthorityPurposes" [value]="purpose.id">{{ purpose.name }}</nb-option>
                                    </nb-select>
                                    <ng-container *ngIf="toFlyAuthorityPurposeId.invalid && toFlyAuthorityPurposeId.touched">
                                        <p class="error-message" *ngIf="toFlyAuthorityPurposeId.errors?.required">
                                            Lauks ir obligāts!
                                        </p>
                                    </ng-container>
                                </div>
                            </div>

                            <!-- Rest of the authorities use restAuthorityPurposes array, where purposes are filtered by zone reason type (and in case with CONDITIONAL- by first authorities purpose) -->
                            <div *ngIf="toFlyAuthorityIndex > 0" class="col-sm-3">
                                <div class="form-control-group mt-4 col-sm-12" *ngIf="restAuthorityPurposes">
                                    <label class="label" for="toFlyAuthorityPurposeId">Kontaktpersonas mērķis <span class="error-message required">*</span></label>
                                    <nb-select
                                        [(ngModel)]="authority.toFlyAuthority.purposeId"
                                        #toFlyAuthorityPurposeId="ngModel"
                                        name="toFlyAuthorityPurposeId{{ authority.id }}"
                                        fullWidth
                                        [required]="true"
                                        [status]="toFlyAuthorityPurposeId.touched ? (toFlyAuthorityPurposeId.invalid ? 'danger' : 'success') : 'primary'"
                                        [attr.aria-invalid]="toFlyAuthorityPurposeId.invalid && toFlyAuthorityPurposeId.touched ? true : null"
                                    >
                                        <nb-option *ngFor="let purpose of restAuthorityPurposes" [value]="purpose.id">{{ purpose.name }}</nb-option>
                                    </nb-select>
                                    <ng-container *ngIf="toFlyAuthorityPurposeId.invalid && toFlyAuthorityPurposeId.touched">
                                        <p class="error-message" *ngIf="toFlyAuthorityPurposeId.errors?.required">
                                            Lauks ir obligāts!
                                        </p>
                                    </ng-container>
                                </div>
                            </div>

                            <!-- ToFlyAuthority => Interval before inputs for days, hours and minutes -->
                            <div class="form-control-group col-sm-3">
                                <label class="label">Sazināties pirms</label>
                                <div class="interval-container">
                                    <!-- Days input -->
                                    <div class="form-control-group mt-1 col-sm-3">
                                        <label for="intervalBeforeDays" class="label">Dienas <span class="error-message required">*</span></label>
                                        <input
                                            type="number"
                                            nbInput
                                            [(ngModel)]="authority.days"
                                            #intervalBeforeDays="ngModel"
                                            name="intervalBeforeDays{{ authority.id }}"
                                            fullWidth
                                            [status]="intervalBeforeDays.touched ? (intervalBeforeDays.invalid ? 'danger' : 'success') : 'primary'"
                                            [required]="true"
                                            min="0"
                                            max="200"
                                            pattern="^[0-9]*$"
                                            step="1"
                                            [attr.aria-invalid]="intervalBeforeDays.invalid && intervalBeforeDays.touched ? true : null"
                                        />
                                        <ng-container *ngIf="intervalBeforeDays.invalid && intervalBeforeDays.touched">
                                            <p class="error-message" *ngIf="intervalBeforeDays?.errors?.required">
                                                Lauks ir obligāts!
                                            </p>
                                            <p class="error-message" *ngIf="intervalBeforeDays.errors?.pattern">
                                                Atļauti tikai veseli skaitļi
                                            </p>
                                        </ng-container>
                                    </div>

                                    <!-- Hours input -->
                                    <div class="form-control-group mt-1 col-sm-3">
                                        <label for="intervalBeforeHours" class="label">Stundas <span class="error-message required">*</span></label>
                                        <input
                                            type="number"
                                            nbInput
                                            [(ngModel)]="authority.hours"
                                            #intervalBeforeHours="ngModel"
                                            name="intervalBeforeHours{{ authority.id }}"
                                            fullWidth
                                            [status]="intervalBeforeHours.touched ? (intervalBeforeHours.invalid ? 'danger' : 'success') : 'primary'"
                                            [required]="true"
                                            min="0"
                                            max="23"
                                            pattern="^[0-9]*$"
                                            step="1"
                                            [attr.aria-invalid]="intervalBeforeHours.invalid && intervalBeforeHours.touched ? true : null"
                                        />
                                        <ng-container *ngIf="intervalBeforeHours.invalid && intervalBeforeHours.touched">
                                            <p class="error-message" *ngIf="intervalBeforeHours?.errors?.required">
                                                Lauks ir obligāts!
                                            </p>
                                            <p class="error-message" *ngIf="intervalBeforeHours.errors?.pattern">
                                                Atļauti tikai veseli skaitļi
                                            </p>
                                        </ng-container>
                                    </div>

                                    <!-- Minutes input -->
                                    <div class="form-control-group mt-1 col-sm-3">
                                        <label for="intervalBeforeMinutes" class="label">Minūtes <span class="error-message required">*</span></label>
                                        <input
                                            type="number"
                                            nbInput
                                            [(ngModel)]="authority.minutes"
                                            #intervalBeforeMinutes="ngModel"
                                            name="intervalBeforeMinutes{{ authority.id }}"
                                            fullWidth
                                            [status]="intervalBeforeMinutes.touched ? (intervalBeforeMinutes.invalid ? 'danger' : 'success') : 'primary'"
                                            [required]="true"
                                            min="0"
                                            max="59"
                                            pattern="^[0-9]*$"
                                            step="1"
                                            [attr.aria-invalid]="intervalBeforeMinutes.invalid && intervalBeforeMinutes.touched ? true : null"
                                        />
                                        <ng-container *ngIf="intervalBeforeMinutes.invalid && intervalBeforeMinutes.touched">
                                            <p class="error-message" *ngIf="intervalBeforeMinutes?.errors?.required">
                                                Lauks ir obligāts!
                                            </p>
                                            <p class="error-message" *ngIf="intervalBeforeMinutes.errors?.pattern">
                                                Atļauti tikai veseli skaitļi
                                            </p>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>

                            <button *ngIf="toFlyAuthorityArray.length > 1" nbButton status="danger" size="medium" class="float-left mt-3 mb-1" (click)="removeToFlyAuthority(toFlyAuthorityIndex)">
                                Noņemt kontaktpersonu
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </nb-card-body>
    <nb-card-footer>
        <button nbButton hero status="primary" (click)="dismiss()">{{ edit ? 'Aizvērt' : 'Atcelt' }}</button>
        <button nbButton status="success" class="ms-3" [disabled]="!form.valid" (click)="submitSubmission()">Saglabāt</button>
    </nb-card-footer>
</nb-card>

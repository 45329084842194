import { Component } from '@angular/core';
import { NbIconLibraries } from '@nebular/theme';
import { SwUpdate } from '@angular/service-worker';
import { NbTokenService } from '@nebular/auth';
import { TokenExpirationAlertService } from './@core/services/token-expiration-alert.service';

@Component({
    selector: 'ngx-app',
    template: `<router-outlet></router-outlet>`
})
export class AppComponent {

    constructor(nbIconLibraries: NbIconLibraries,
        private swUpdate: SwUpdate,
        private tokenExpirationService: TokenExpirationAlertService) {
        nbIconLibraries.registerFontPack('font-awesome', {
            packClass: 'fa'
        });
        nbIconLibraries.setDefaultPack('font-awesome');

        if (this.swUpdate.isEnabled) {
            this.swUpdate.available.subscribe(() => {
                if (confirm('New version available. Load New Version?')) {
                    window.location.reload();
                }
            });
        }

        this.tokenExpirationService.start();
    }
}

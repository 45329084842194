import { Directive, Input } from '@angular/core';
import { AbstractControl, Validator, NG_VALIDATORS, ValidationErrors } from '@angular/forms';


@Directive({
  selector: '[dmsCoordValidator]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: DmsCoordValidatorDirective,
    multi: true
  }]
})
export class DmsCoordValidatorDirective implements Validator {

  @Input()
  longitude: boolean;

  validate(control: AbstractControl<any, any>): ValidationErrors {
    if(this.longitude) {
      var lon = this.getCoordsFromDms(control.value);
      if(lon < 18.5 || lon > 28.23333) {
        return { 'dmsCoordValidator': true };
      }
    } else {
      var lat = this.getCoordsFromDms(control.value);
      if(lat < 55.66667 || lat > 58.41667 ) {
        return { 'dmsCoordValidator': true };
      }
    }
    return null;
  }

  getCoordsFromDms(dms: number): number {
    var digits = (""+dms).split("");
    var dd = parseInt(digits[0]+digits[1], 10);
    var mm = parseInt(digits[2]+digits[3], 10);
    var ss = parseFloat(digits.splice(4).join(""));
    return dd + (mm / 60) + (ss / 3600);
  }

}

import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NbDateFnsDateModule } from '@nebular/date-fns';

import {
    NbActionsModule,
    NbCardModule,
    NbLayoutModule,
    NbMenuModule,
    // NbRouteTabsetModule,
    // NbSearchModule,
    NbSidebarModule,
    NbTabsetModule,
    NbThemeModule,
    NbUserModule,
    NbCheckboxModule,
    NbPopoverModule,
    NbContextMenuModule,
    // NbProgressBarModule,
    NbCalendarModule,
    NbDatepickerModule,
    // NbCalendarRangeModule,
    // NbStepperModule,
    NbButtonModule,
    NbInputModule,
    // NbAccordionModule,
    NbDialogModule,
    // NbWindowModule,
    NbListModule,
    NbToastrModule,
    NbAlertModule,
    NbSpinnerModule,
    NbRadioModule,
    NbSelectModule,
    NbTooltipModule,
    NbIconModule,
    NbTimepickerModule
} from '@nebular/theme';
import { NbSecurityModule } from '@nebular/security';

import {
    FooterComponent,
    HeaderComponent,
    LanguageSwitcherComponent,
    ToggleSettingsButtonComponent,
    SwitcherComponent
} from './components';
import {
    CapitalizePipe,
    PluralPipe,
    RoundPipe,
    TimingPipe,
    NumberWithCommasPipe,
} from './pipes';
import { DEFAULT_THEME } from './styles/theme.default';

const BASE_MODULES = [CommonModule, FormsModule, ReactiveFormsModule];

const NB_MODULES = [
    NbCardModule,
    NbLayoutModule,
    NbTabsetModule,
    // NbRouteTabsetModule,
    NbMenuModule,
    NbUserModule,
    NbActionsModule,
    // NbSearchModule,
    NbSidebarModule,
    NbCheckboxModule,
    NbPopoverModule,
    NbContextMenuModule,
    NbSecurityModule, // *nbIsGranted directive,
    // NbProgressBarModule,
    NbCalendarModule,
    NbDatepickerModule,
    NbTimepickerModule,
    // NbCalendarRangeModule,
    // NbStepperModule,
    NbButtonModule,
    NbListModule,
    NbToastrModule,
    NbInputModule,
    // NbAccordionModule,
    NbDialogModule,
    // NbWindowModule,
    NbAlertModule,
    NbSpinnerModule,
    NbRadioModule,
    NbSelectModule,
    NbTooltipModule,
    NbIconModule
];

const COMPONENTS = [
    HeaderComponent,
    FooterComponent,
    LanguageSwitcherComponent,
    ToggleSettingsButtonComponent,
    SwitcherComponent
];

const PIPES = [
    CapitalizePipe,
    PluralPipe,
    RoundPipe,
    TimingPipe,
    NumberWithCommasPipe
];

const NB_THEME_PROVIDERS = [
    ...NbThemeModule.forRoot(
        {
            name: 'default',
        },
        [DEFAULT_THEME],
    ).providers,
    ...NbSidebarModule.forRoot().providers,
    ...NbMenuModule.forRoot().providers,
    ...NbDialogModule.forRoot().providers,
    // ...NbWindowModule.forRoot().providers,
    ...NbToastrModule.forRoot().providers,
    ...NbDatepickerModule.forRoot().providers,
    ...NbTimepickerModule.forRoot().providers
];

@NgModule({
    imports: [...BASE_MODULES, ...NB_MODULES,
    NbDateFnsDateModule.forRoot(
        {
            format: 'yyyy-MM-dd',
            parseOptions: { awareOfUnicodeTokens: true },
            formatOptions: { awareOfUnicodeTokens: true }
        }
    )
    ],
    exports: [...NB_MODULES, ...COMPONENTS, ...PIPES, NbDateFnsDateModule],
    declarations: [...COMPONENTS, ...PIPES],
})
export class ThemeModule {
    static forRoot(): ModuleWithProviders<ThemeModule> {
        return <ModuleWithProviders<ThemeModule>>{
            ngModule: ThemeModule,
            providers: [...NB_THEME_PROVIDERS],
        };
    }
}

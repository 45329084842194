<nb-card [style.width.px]="600">
    <nb-card-header>{{ headerText }}</nb-card-header>
    <nb-card-body>
      {{ infoMessage1 }}
      <br><br>
      {{ infoMessage2 }}
      <br><br>
      {{ infoMessage3 }}
    </nb-card-body>
    <nb-card-footer>
      <button class="btn btn-info button-full"
              type="submit"
              (click)="dismiss()">Ok</button>
    </nb-card-footer>
  </nb-card>

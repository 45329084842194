<nb-card [style.width.px]="600">
    <nb-card-header>Turpmākās darbības</nb-card-header>
    <nb-card-body class="row">
        <span class="bold">Statuss: </span> <span>{{ status }}</span>
        <span class="mt-2 wrap">{{ actionsMsg }}</span>
    </nb-card-body>
    <nb-card-footer>
      <button class="btn btn-success button-full"
              type="submit"
              (click)="dismiss()">Turpināt</button>
    </nb-card-footer>
  </nb-card>

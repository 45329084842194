import { Component } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
import { Authority, Classifier } from '@amdb/drone/shared/models';
import { ToFlyAuthority } from '@amdb/drone/shared/models/to-fly-authority.model';

@Component({
  selector: 'app-to-fly-authority-modal',
  templateUrl: './to-fly-authority-modal.component.html',
  styleUrls: ['./to-fly-authority-modal.component.scss']
})
export class ToFlyAuthorityModalComponent {

  authority: Authority;
  toFlyAuthority: ToFlyAuthority = {} as ToFlyAuthority;
  id: number;
  isEditMode: boolean;
  authorityDataGroups: Classifier[];

  constructor(protected ref: NbDialogRef<ToFlyAuthorityModalComponent>) { }

  ngOnInit() {
    if(!this.isEditMode) {
      this.toFlyAuthority.dataGroupId = this.authority.dataGroupAuthorities[0].dataGroupId;
    }
  }

  submit(): void {
    this.ref.close(this.toFlyAuthority);
  }

  dismiss() {
    this.ref.close();
  }
}

<nb-card class="dialog-card">
  <nb-card-header>Apstiprināt</nb-card-header>
  <nb-card-body>
    {{ confirmationMsg }}
  </nb-card-body>
  <nb-card-footer>
    <button class="btn btn-danger button-full margin-right "
            type="submit"
            (click)="onYes()">Jā</button>
    <button class="btn btn-info button-full"
            type="submit"
            (click)="onNo()">Nē</button>
  </nb-card-footer>
</nb-card>
import { Component, OnInit } from '@angular/core';
import { Router, Event, NavigationStart } from '@angular/router';
import { NbSidebarService } from '@nebular/theme';

@Component({
  selector: 'ngx-toggle-settings-button',
  styleUrls: ['./toggle-settings-button.component.scss'],
  template: `
    <button class="toggle-settings"
            (click)="toggleSettings()"
            [class.expanded]="expanded"
            [class.sidebar-end]="false"
            [class.was-expanded]="wasExpanded">
      <nb-icon icon="fa-download"></nb-icon>
    </button>
  `,
})
export class ToggleSettingsButtonComponent implements OnInit {

  expanded = false;
  wasExpanded = false;

  constructor(private sidebarService: NbSidebarService,
    private router: Router) {
  }

  ngOnInit(): void {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart && this.expanded) {
        this.toggleSettings();
      }
    });
  }

  toggleSettings() {
    this.sidebarService.toggle(false, 'settings-sidebar');
    this.expanded = !this.expanded;
    this.wasExpanded = true;
  }
}

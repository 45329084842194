import { Component, EventEmitter, Input, Output } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { NbDialogService, NbToastrService } from '@nebular/theme';

import { ToFlyAuthority } from '../../models/to-fly-authority.model';
import { ToFlyAuthoritiesService } from './to-fly-authorities.service';
import { ToFlyAuthorityModalComponent } from '@amdb/drone/pages/authority-profile/to-fly-authority-modal/to-fly-authority-modal.component';
import { Authority, Classifier } from '../../models';
import { ToFlyAuthorityEditConfirmModalComponent } from '@amdb/drone/pages/authority-profile/to-fly-authority-edit-confirm-modal/to-fly-authority-edit-confirm-modal.component';

@Component({
  selector: 'amdb-authority-contacts',
  templateUrl: './authority-contacts.component.html',
  styleUrls: ['./authority-contacts.component.scss']
})
export class AuthorityContactsComponent {
  loading: boolean;

  @Input()
  toFlyAuthorities: ToFlyAuthority[];

  @Input()
  authority: Authority;

  @Input()
  authorityDataGroups: Classifier[];

  @Output()
  onToFlyAuthorityUpdateStart: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  onToFlyAuthorityUpdateEnd: EventEmitter<any> = new EventEmitter<any>();

  constructor(private toFlyAuthoritiesService: ToFlyAuthoritiesService,
              private toastrService: NbToastrService,
              private dialogService: NbDialogService) { }

  async editToFlyAuthority(toFlyAuthority: ToFlyAuthority) {
      const dialogRef = this.dialogService.open(ToFlyAuthorityModalComponent, { context: { isEditMode: true, toFlyAuthority: toFlyAuthority, authorityDataGroups: this.authorityDataGroups, authority: this.authority } });
      dialogRef.onClose.subscribe(async (toFlyAuthority: ToFlyAuthority) => {
        if(!toFlyAuthority){
          return;
        }
        const editConfirmDialogRef = this.dialogService.open(ToFlyAuthorityEditConfirmModalComponent);
        editConfirmDialogRef.onClose.subscribe(async (result: boolean) => {
          if(result) {
            this.onToFlyAuthorityUpdateStart.emit();
            try {
              await this.toFlyAuthoritiesService.editToFlyAuthority(toFlyAuthority);
              this.toFlyAuthoritiesService.getAllToFlyAuthorities(toFlyAuthority.authorityId).then(data => {
                this.toFlyAuthorities = data;
            });
            this.onToFlyAuthorityUpdateEnd.emit();
            this.toastrService.success('Kontaktinformācija veiksmīgi labota', 'Labots');
            } catch(errorResponse) {
              this.onToFlyAuthorityUpdateEnd.emit();
              if(errorResponse instanceof HttpErrorResponse) {
                if(typeof (errorResponse.error) === 'object') {
                    const msgs = [];
                    Object.keys(errorResponse.error).forEach(key => {
                        const val = errorResponse.error[key];
                        Array.isArray(val) ? msgs.push(val[0]) : msgs.push(val);
                    });
                    this.toastrService.danger(msgs, 'Kļūda');
                } else {
                    this.toastrService.danger(errorResponse.error, 'Kļūda');
                }
              }
            }
          } else {
            this.toastrService.warning('Forma tika aizvērta bez saglabāšanas', 'Brīdinājums');
            return;
          }
        });
      });
        
    }
    
}

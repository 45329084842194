import { Component } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'next-actions-modal',
  templateUrl: './next-actions-modal.component.html',
  styleUrls: ['./next-actions-modal.component.scss']
})
export class NextActionsModalComponent {

  status: string;
  actionsMsg: string;

  constructor(protected dialogRef: NbDialogRef<boolean>) {
  }

  dismiss() {
    this.dialogRef.close();
  }

}

<nb-card>
  <nb-card-header>Slāņa informācija</nb-card-header>
  <nb-card-body [nbSpinner]="loading"
                class="layer-details-card">
    <form (ngSubmit)="submitBaseLayer()"
          #form="ngForm"
          aria-labelledby="title">

      <div class="form-control-group mt-3 mb-3">
        <label class="label"
               for="name">Nosaukums</label>
        <input nbInput
               [(ngModel)]="baseLayer.name"
               #name="ngModel"
               id="name"
               name="name"
               rows="4"
               fullWidth
               [status]="name.touched ? (name.invalid  ? 'danger' : 'success') : ''"
               [required]="true"
               [attr.aria-invalid]="name.invalid && name.touched ? true : null">
        <ng-container *ngIf="name.invalid && name.touched">
          <p class="error-message"
             *ngIf="name?.errors?.required">
            Nosaukums jānorāda obligāti!
          </p>
        </ng-container>
      </div>

      <div class="form-control-group">
        <label class="label"
               for="input-sourcetype">Avota tips</label>
        <nb-select [(selected)]="baseLayer.sourceType"
                   placeholder="Avota tips"
                   fullWidth>
          <nb-option *ngFor="let c of availableSourceTypes"
                     [value]="c">{{ c }}</nb-option>
        </nb-select>
      </div>

      <div class="form-control-group mt-3 mb-3">
        <label class="label"
               for="sourceJson">Avots (JSON)</label>
        <input nbInput
               [(ngModel)]="baseLayer.sourceJson"
               #sourceJson="ngModel"
               id="sourceJson"
               name="sourceJson"
               rows="4"
               fullWidth
               [status]="sourceJson.touched ? (sourceJson.invalid  ? 'danger' : 'success') : ''"
               [required]="true"
               [attr.aria-invalid]="sourceJson.invalid && sourceJson.touched ? true : null">
        <ng-container *ngIf="sourceJson.invalid && sourceJson.touched">
          <p class="error-message"
             *ngIf="sourceJson?.errors?.required">
            JSON parametri jānorāda obligāti
          </p>
        </ng-container>
      </div>


      <div class="form-control-group col-lg-4 mt-4">
        <nb-checkbox [(ngModel)]="baseLayer.active"
                     name="active"
                     id="active">
          <span>Aktīvs</span>
        </nb-checkbox>
        <nb-checkbox [(ngModel)]="baseLayer.overlay"
                     name="overlay"
                     id="overlay">
          <span>Pārklājuma</span>
        </nb-checkbox>
      </div>

      <button nbButton
              fullWidth
              status="success"
              [disabled]="!form.valid">
        Saglabāt
      </button>
    </form>
  </nb-card-body>
  <nb-card-footer>
    <button nbButton
            hero
            status="primary"
            (click)="dismiss()">Aizvērt</button>
  </nb-card-footer>
</nb-card>
<div class="p-3">
    <form (ngSubmit)="upsertAuthority(form)" #form="ngForm" aria-labelledby="title">
        <div class="form-control-group" *ngIf="authorityRoles">
            <label class="label" for="input-authorityname">Institūcijas loma</label>
            <nb-select [(selected)]="authority.roleId" [disabled]="editMode" placeholder="Institūcijas loma" fullWidth>
                <nb-option *ngFor="let role of authorityRoles" [value]="role.id">{{ role.name }}</nb-option>
            </nb-select>
        </div>

        <div class="form-control-group mt-3">
            <label class="label" for="input-authorityname">Institūcijas nosaukums</label>
            <input
                nbInput
                [(ngModel)]="authority.name"
                #authorityName="ngModel"
                id="input-authorityname"
                name="authorityName"
                placeholder="Institūcijas nosaukums"
                fullWidth
                maxlength="200"
                [required]="true"
                [status]="authorityName.touched ? (authorityName.invalid ? 'danger' : 'success') : ''"
                [attr.aria-invalid]="authorityName.invalid && authorityName.touched ? true : null"
            />
            <ng-container *ngIf="authorityName.invalid && authorityName.touched">
                <p class="error-message" *ngIf="authorityName?.errors?.required">
                    Institūcijas nosaukums jānorāda obligāti!
                </p>
            </ng-container>
        </div>

        <div class="form-control-group mt-3">
            <label *ngIf="!editMode" class="label" for="input-contactName">Institūcijas administratora vārds</label>
            <label *ngIf="editMode" class="label" for="input-contactName">Institūcijas kontaktpersonas vārds</label>
            <input
                nbInput
                [(ngModel)]="authority.contactFirstName"
                #contactName="ngModel"
                id="input-contactName"
                name="contactName"
                placeholder="Kontaktpersona"
                fullWidth
                maxlength="200"
                [required]="true"
                [status]="contactName.touched ? (contactName.invalid ? 'danger' : 'success') : ''"
                [attr.aria-invalid]="contactName.invalid && contactName.touched ? true : null"
            />
            <ng-container *ngIf="contactName.invalid && contactName.touched">
                <p class="error-message" *ngIf="contactName?.errors?.required">
                    Kontaktpersonas vārds jānorāda obligāti!
                </p>
            </ng-container>
        </div>

        <div class="form-control-group mt-3">
            <label *ngIf="!editMode" class="label" for="input-contactLastName">Institūcijas administratora uzvārds</label>
            <label *ngIf="editMode" class="label" for="input-contactLastName">Institūcijas kontaktpersonas uzvārds</label>
            <input
                nbInput
                [(ngModel)]="authority.contactLastName"
                #contactLastName="ngModel"
                id="input-contactLastName"
                name="contactLastName"
                placeholder="Kontaktpersona"
                fullWidth
                maxlength="200"
                [required]="true"
                [status]="contactLastName.touched ? (contactLastName.invalid ? 'danger' : 'success') : ''"
                [attr.aria-invalid]="contactLastName.invalid && contactLastName.touched ? true : null"
            />
            <ng-container *ngIf="contactLastName.invalid && contactLastName.touched">
                <p class="error-message" *ngIf="contactLastName?.errors?.required">
                    Kontaktpersonas uzvārds jānorāda obligāti!
                </p>
            </ng-container>
        </div>

        <div class="form-control-group mt-3">
            <label class="label" for="input-email" *ngIf="!authority.isRegistrationConfirmed">Institūcijas administratora e-pasts, uz kuru tiks nosūtīta saite institūcijas aktivizācijai</label>
            <label class="label" for="input-email" *ngIf="authority.isRegistrationConfirmed">Institūcijas e-pasts</label>
            <input
                nbInput
                [(ngModel)]="authority.email"
                #email="ngModel"
                id="input-email"
                name="email"
                pattern=".+@.+..+"
                placeholder="E-pasts"
                fullWidth
                maxlength="200"
                [status]="email.touched ? (email.invalid ? 'danger' : 'success') : ''"
                [required]="true"
                [attr.aria-invalid]="email.invalid && email.touched ? true : null"
            />
            <ng-container *ngIf="email.invalid && email.touched">
                <p class="error-message" *ngIf="email?.errors?.required">
                    E-pasts jānorāda obligāti!
                </p>
                <p class="error-message" *ngIf="email?.errors?.pattern">
                    E-pasta adresei jābūt reālai!
                </p>
            </ng-container>
        </div>

        <div class="form-control-group mt-3">
            <label class="label" for="input-phone">Telefons</label>
            <input
                nbInput
                [(ngModel)]="authority.phone"
                #phone="ngModel"
                id="input-phone"
                name="phone"
                placeholder="Telefons"
                fullWidth
                maxlength="200"
                [required]="true"
                pattern="^((((\+371{1}((2{1})|(6{1}))[0-9]{7})|(((2{1})|(6{1}))[0-9]{7})))(\,)?|(\,\s)?)+$"
                [status]="phone.touched ? (phone.invalid ? 'danger' : 'success') : ''"
                [attr.aria-invalid]="phone.invalid && phone.touched ? true : null"
            />
            <ng-container *ngIf="phone.invalid && phone.touched">
                <p class="error-message" *ngIf="phone?.errors?.required">
                    Telefonu jānorāda obligāti!
                </p>
                <p class="error-message" *ngIf="phone?.errors?.pattern">
                    Telefona numuram jābūt reālam
                </p>
            </ng-container>
        </div>

        <div class="form-control-group mt-3">
            <label class="label" for="input-address">Adrese</label>
            <input
                nbInput
                [(ngModel)]="authority.address"
                #address="ngModel"
                id="input-address"
                name="address"
                placeholder="Adrese"
                fullWidth
                maxlength="200"
                [required]="true"
                [status]="address.touched ? (address.invalid ? 'danger' : 'success') : ''"
                [attr.aria-invalid]="address.invalid && address.touched ? true : null"
            />
            <ng-container *ngIf="address.invalid && address.touched">
                <p class="error-message" *ngIf="address?.errors?.required">
                    Adrese jānorāda obligāti!
                </p>
            </ng-container>
        </div>

        <div class="form-control-group mt-3">
            <label class="label" for="input-fax">Fakss</label>
            <input
                nbInput
                [(ngModel)]="authority.fax"
                #fax="ngModel"
                id="input-fax"
                name="fax"
                placeholder="Fakss"
                fullWidth
                maxlength="200"
                [status]="fax.touched ? (fax.invalid ? 'danger' : 'success') : ''"
                [attr.aria-invalid]="fax.invalid && fax.touched ? true : null"
            />
        </div>

        <div class="form-control-group mt-3">
            <label class="label" for="input-regNr">Reģistrācijas numurs</label>
            <input
                nbInput
                [(ngModel)]="authority.regNr"
                #regNr="ngModel"
                id="input-regNr"
                name="regNr"
                placeholder="Reģistrācijas numurs"
                fullWidth
                maxlength="200"
                [required]="true"
                [status]="regNr.touched ? (regNr.invalid ? 'danger' : 'success') : ''"
                [attr.aria-invalid]="regNr.invalid && regNr.touched ? true : null"
            />
            <ng-container *ngIf="regNr.invalid && regNr.touched">
                <p class="error-message" *ngIf="regNr?.errors?.required">
                    Reģistrācijas numuru jānorāda obligāti!
                </p>
            </ng-container>
        </div>

        <div class="form-control-group mt-3">
            <label class="label" for="input-service">Serviss</label>
            <input
                nbInput
                [(ngModel)]="authority.service"
                #service="ngModel"
                id="input-service"
                name="service"
                placeholder="Serviss"
                fullWidth
                maxlength="200"
                [status]="service.touched ? (service.invalid ? 'danger' : 'success') : ''"
                [attr.aria-invalid]="service.invalid && service.touched ? true : null"
            />
        </div>

        <div class="form-control-group mt-3">
            <label class="label" for="input-siteUrl">WEB adrese</label>
            <input
                nbInput
                [(ngModel)]="authority.siteUrl"
                #siteUrl="ngModel"
                id="input-siteUrl"
                name="siteUrl"
                placeholder="WEB adrese"
                fullWidth
                maxlength="200"
                [status]="siteUrl.touched ? (siteUrl.invalid ? 'danger' : 'success') : ''"
                [attr.aria-invalid]="siteUrl.invalid && siteUrl.touched ? true : null"
            />
        </div>

        <div class="flex-row">
            <div *ngIf="authority.roleId == AuthorityRole.Originator" class="form-control-group mt-3">
                <label class="label" for="toggle-isAutoSubmitter">Automātiskas zonas publicēšanas iespējas</label>
                <div class="switcher-container">
                    <ngx-switcher
                        class="flex-1"
                        [firstValue]="false"
                        [secondValue]="true"
                        [firstValueLabel]="switcherFirstValueLabel"
                        [secondValueLabel]="switcherSecondValueLabel"
                        [value]="authority.isAutoSubmitter"
                        (valueChange)="toggleIsAutoSubmitter()"
                    ></ngx-switcher>
                </div>
            </div>

            <div *ngIf="authority.roleId == AuthorityRole.Originator" class="form-control-group mt-3">
                <label class="label" for="toggle-isSubmitter">Ģeogrāfisko zonu iesniegšana</label>
                <div class="switcher-container">
                    <ngx-switcher
                        class="flex-1"
                        [firstValue]="false"
                        [secondValue]="true"
                        [firstValueLabel]="switcherFirstValueLabel"
                        [secondValueLabel]="switcherSecondValueLabel"
                        [value]="authority.isSubmitter"
                        (valueChange)="toggleIsSubmitter()"
                    ></ngx-switcher>
                </div>
            </div>

            <div class="form-control-group mt-3 float-right">
                <label class="label" for="toggle-exportHistoryViewer">JSON datņu arhīva skatīšanas iespējas</label>
                <div class="switcher-container">
                    <ngx-switcher
                        class="flex-1"
                        [firstValue]="false"
                        [secondValue]="true"
                        [firstValueLabel]="switcherFirstValueLabel"
                        [secondValueLabel]="switcherSecondValueLabel"
                        [value]="authority.exportHistoryViewer"
                        (valueChange)="toggleExportHistoryViewer()"
                    ></ngx-switcher>
                </div>
            </div>
        </div>

        <div class="form-control-group mt-3" *ngIf="authority.isAutoSubmitter && authority.roleId == AuthorityRole.Originator">
            <label class="label" for="input-authorityCaaPrefix">CAA prefikss</label>
            <input
                nbInput
                [(ngModel)]="authority.caaPrefix"
                #caaPrefix="ngModel"
                id="input-caaPrefix"
                name="caaPrefix"
                placeholder="CAA prefikss"
                fullWidth
                [required]="true"
                [status]="caaPrefix.touched ? (caaPrefix.invalid ? 'danger' : 'success') : ''"
                [attr.aria-invalid]="caaPrefix.invalid && caaPrefix.touched ? true : null"
            />
            <ng-container *ngIf="caaPrefix.invalid && caaPrefix.touched">
                <p class="error-message" *ngIf="caaPrefix?.errors?.required">
                    CAA prefikss jānorāda obligāti!
                </p>
            </ng-container>
        </div>

        <div class="form-control-group mt-3" *ngIf="(authority.isAutoSubmitter || authority.isSubmitter) && authority.roleId == AuthorityRole.Originator">
            <label class="label" for="select-authorityDataGroups">Datu grupas</label>
            <nb-select
                [(ngModel)]="authority.dataGroupIds"
                #authorityDataGroups="ngModel"
                multiple
                name="authorityDataGroups"
                fullWidth
                (selectedChange)="updateDataGroups()"
                [status]="authorityDataGroups.touched ? (authorityDataGroups.invalid ? 'danger' : 'success') : 'primary'"
                [required]="true"
                [attr.aria-invalid]="authorityDataGroups.invalid && authorityDataGroups.touched ? true : null"
            >
                <nb-option *ngFor="let dataGroup of dataGroups" [value]="dataGroup.id">{{ dataGroup.name }}</nb-option>
            </nb-select>
            <ng-container *ngIf="authorityDataGroups.invalid && authorityDataGroups.touched">
                <p class="error-message" *ngIf="authorityDataGroups?.errors?.required">
                    Institūcijas datu grupas ir jānorāda obligāti!
                </p>
            </ng-container>
        </div>

        <button nbButton fullWidth status="success" class="mt-3" [disabled]="submitted || !form.valid || !authority.roleId" [class.btn-pulse]="submitted">
            <span *ngIf="!editMode">Reģistrēt institūciju</span>
            <span *ngIf="editMode">Atjaunot institūcijas datus</span>
        </button>
    </form>
</div>

<nb-card [style.width.px]="600">
    <nb-card-header>
      <h6>Sesijas beigas. Atlikušais laiks: <span>{{ timerDisplay }}</span></h6>
    </nb-card-header>
    <nb-card-body>
        <span>
          Sistēmas izmantošanas drošības nolūkos, apstipriniet, vai turpināt sistēmas sesijas darbību vai pārtraukt to. 
          <br>
          Apstiprināt sistēmas sesijas darbības turpināšanu - spiediet "Jā", pārtraukt sistēmas darbības sesiju - spiediet "Nē".
          <br>
          <br>
          (Neapstiprinot šo darbību 5 minūšu laikā, Jūsu sistēmas darbības sesija tiks pārtraukta).
        </span>
    </nb-card-body>
    <nb-card-footer>
      <button class="btn btn-success button-full"
              (click)="refresh()">Jā</button>
      <button class="btn btn-danger button-full ms-2"
              (click)="dismissAndLogout()">Nē</button>
    </nb-card-footer>
  </nb-card>

import { Component, OnInit, Input } from '@angular/core';
import { GeometryPrimitiveValidation } from '@amdb/drone/shared/models/geometry-primitive-validation.model';
import { NbDialogRef } from '@nebular/theme';

@Component({
    templateUrl: './geometry-primitive-validation-result-list.component.html',
    styleUrls: ['./geometry-primitive-validation-result-list.component.scss']
})
export class GeometryPrimitiveValidationResultListComponent implements OnInit {

    @Input()
    source: GeometryPrimitiveValidation[];

    @Input()
    hasGtseUuid: boolean = false;

    @Input()
    fromShp: boolean = false;

    tableSettings: any;
    loading: boolean = true;

    validationFailed: boolean = false;

    constructor(protected ref: NbDialogRef<GeometryPrimitiveValidationResultListComponent>) { }

    ngOnInit() {
        this.tableSettings = {
            mode: 'external',
            actions: false,
            pager: {
                perPage: 10,
                display: true
            },
            columns: {
                ruleCode: {
                    title: 'AMDB nosacījuma kods',
                    filter: false
                },
                ruleDescription: {
                    title: 'Nosacījuma apraksts',
                    filter: false
                },
                rulePassed: {
                    title: 'Validācijas rezultāts',
                    filter: false,
                    type: 'html',
                    valuePrepareFunction: (rulePassed: boolean) => {
                        if (rulePassed === true) {
                            return `<i class="fa fa-check" aria-hidden="true"></i>`;
                        } else if (rulePassed === false) {
                            return `<i class="fa fa-times" aria-hidden="true"></i>`;
                        } else {
                            return `<span>Error</span>`;
                        }
                    }
                }
            }
        };

        this.loading = false;
        this.source.forEach((result) => {
            switch(result.ruleCode) {
                case 'AMDB-R303':
                case 'AMDB-R306':
                case 'AMDB-R309':
                case 'SYSTEM ERROR': {
                    if(!result.rulePassed) {
                        this.validationFailed = true;
                    }
                }
                break;
            }
        });
    }

    continue() {
        this.ref.close(!this.source.find(s => s.ruleCode === 'SYSTEM ERROR'));
    }

    dismiss() {
        this.ref.close();
    }
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'classifier',
})

export class ClassifierPipe implements PipeTransform {

    transform(classifierId: number | string): string {
        const translationMap = {

            101: 'Iesniedzējs',
            102: 'Importētājs',
            103: 'Publicētājs',

            201: 'COMMON',
            202: 'CUSTOMIZED',

            301: 'PROHIBITED',
            302: 'REQ_AUTHORISATION',
            303: 'CONDITIONAL',
            304: 'NO_RESTRICTION',

            401: 'Jā',
            402: 'Nē',

            'YES': 'Jā',
            'NO': 'Nē',

            501: 'AIR_TRAFFIC',
            502: 'SENSITIVE',
            503: 'PRIVACY',
            504: 'POPULATION',
            505: 'NATURE',
            506: 'NOISE',
            507: 'FOREIGN_TERRITORY',
            508: 'EMERGENCY',
            509: 'OTHER',

            601: 'AGL',
            602: 'AMSL',
            603: 'WGS84',

            701: 'LKS92',
            702: 'WGS84',

            801: 'Sagatavots',
            802: 'Iesniegts CAA',
            803: 'Sagatavots projekts',
            804: 'Iesniegums noraidīts',
            805: 'Apstiprināts',
            806: 'Noraidīts',
            807: 'Apstiprināts (Mainīti dati)',
            808: 'Ģenerēta ģeogrāfiskā zona',
            809: 'Ģeogrāfiskā zona koriģēta atbilstoši gaisa telpas robežām (Objekts daļēji gaisa telpas robežās)',
            810: 'Ģeogrāfiskā zona koriģēta atbilstoši gaisa telpas robežām (Objekts neatrodas gaisa telpas robežās)',
            811: 'Ģeogrāfiskā zona koriģēta atbilstoši gaisa telpas robežām',
            812: 'Ģeogrāfiskā zona nav izveidota, jo atrodas ārpus gaisa telpas robežām. Iesniegumumu nav iespējams iesniegt CAA',
            813: 'Mainīti dati',
            814: 'Mainīti dati',
            815: 'Zona ir mazāka par 200 kvadrātmetriem',
            816: 'Kādā no zonas stūriem ir leņķis, kas mazāks par 10 grādiem',
            817: 'Arhivēts',

            901: 'Civilām vajadzībām: Izveidošana',
            902: 'Civilām vajadzībām: Mainīšana',
            903: 'Civilām vajadzībām: Slēgšana',
            904: 'Militārām vajadzībām: Izveidošana',
            905: 'Militārām vajadzībām: Mainīšnana',
            906: 'Militārām vajadzībām: Slēgšana',

            1101: 'Gaisa trase',
            1102: 'Nosacījuma maršruts (CDR)',
            1103: 'Ilglaicīgā bīstamā zona (D)',
            1104: 'Īslaicīgā bīstamā zona (D)',
            1105: 'Ilglaicīgā ierobežoto lidojumu zona (R)',
            1106: 'Īslaicīgā ierobežoto lidojumu zona (R)',
            1107: 'Aizliegtā zona (P)',
            1108: 'Īslaicīgi rezervēta zona (TRA)',
            1109: 'Īslaicīgi norobežota zona (TSA)',
            1110: 'Pārrobežu zona (CBA)',
            1112: 'Gaisa satiksmes vadības rajons (CTA)',
            1113: 'Gaisa satiksmes vadības zona (CTR)',
            1114: 'Lidlauka gaisa satiksmes zona (ATZ)',
            1115: 'Satiksmes informācijas zona (TIZ)',
            1116: 'Satiksmes informācijas rajons (TIA)',
            1117: 'Lidlauka gaisa satiksmes vadības rajons (TMA)',
            1118: 'Lidojumu informācijas rajons (FIR)',
            1119: 'Cits',

            1201: 'Metri (M)',
            1202: 'Pēdas (FT)',

            1401: 'Lidojuma atļaujas izsniegšana',
            'AUTHORIZATION': 'Lidojuma atļaujas izsniegšana',
            1402: 'Paziņošana par lidojumu',
            'NOTIFICATION': 'Paziņošana par lidojumu',
            1403: 'Informēšana par lidojumu',
            'INFORMATION': 'Informēšana par lidojumu',

            1501: 'Pirmdiena',
            1502: 'Otrdiena',
            1503: 'Trešdiena',
            1504: 'Ceturtdiena',
            1505: 'Piektdiena',
            1506: 'Sestdiena',
            1507: 'Svētdiena',
            1508: 'Visas nedēļas dienas',

            'false': 'Nē',
            'true': 'Jā'
        };
        const translation = translationMap[classifierId];

        if (!translation) {
            if(!classifierId) {
                return;
            }
            return classifierId.toString();
        }
        return translation;
    }
}

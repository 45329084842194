<nb-card [nbSpinner]="loading">
  <nb-card-header>
    <span>ESRI SHP failu pievienošana - arhīva ZIP veidā. ESRI SHP ģeometrijas atribūti (META dati) ir aprakstiti
      lietotāja rokasgrāmatā!</span>
  </nb-card-header>
  <nb-card-body>
    <nb-alert outline="info"
              role="info">
      <span>
        Lūdzu izvēlieties SHP failu, kas ir saarhivēts ZIP arhīvā un satur tikai viena objekta datus
      </span>
    </nb-alert>
    <div class="d-flex">
      <div>
        <label class="label"
               for="shpArchiveSelector">Arhīva ievade</label>
        <input class="ms-4"
               #shpArchiveSelector
               type="file"
               accept=".zip"
               required>
        <button nbButton
                fullWidth
                status="success"
                class="mt-3"
                (click)="upload()">
          Augšupielādēt
        </button>
      </div>
      <nb-alert *ngIf="errors"
                outline="danger"
                role="alert"
                class="mt-3 flex">
        <ul class="alert-message-list">
          <li *ngIf="errors.length === 0">Sistēmas kļūda</li>
          <li *ngFor="let error of errors"
              class="alert-message">{{ error }}</li>
        </ul>
      </nb-alert>
    </div>
  </nb-card-body>
  <nb-card-footer>
    <button nbButton
            hero
            status="primary"
            (click)="dismiss()">Aizvērt</button>
  </nb-card-footer>
</nb-card>
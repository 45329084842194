import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import Map from 'ol/Map';
import View from 'ol/View';
import LayerSwitcher from 'ol-layerswitcher';
import * as Proj from 'ol/proj';
import proj4 from 'proj4';
import { register } from 'ol/proj/proj4.js';
import WKT from 'ol/format/WKT.js';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import olFeature from 'ol/Feature';

import { ScaleLine } from 'ol/control';

import { BaseLayersService } from '@amdb/drone/@core/services/base-layer.service';
import { Circle, Fill, Stroke, Style } from 'ol/style';

@Component({
    selector: 'amdb-map',
    templateUrl: './map.component.html',
    styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit {

    @Input()
    geometries: string[];

    @Input()
    reportMode: boolean;

    @Output()
    onCanvasRendered: EventEmitter<any> = new EventEmitter();

    map: Map;

    constructor(private baseLayersService: BaseLayersService) { }

    async ngOnInit(): Promise<void> {
        const baseLayers = await this.baseLayersService.getBaseLayerGroups();
        proj4.defs('EPSG:3857',
            '+proj=tmerc +lat_0=0 +lon_0=24 +k=0.9996 +x_0=500000 +y_0=-6000000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs');
        register(proj4);

        const projection = Proj.get('EPSG:3857');
        const defaultView = new View({
            center: Proj.fromLonLat([23.9890828, 56.9713962]),
            zoom: 10,
            projection: projection
        });

        const features: olFeature[] = [];
        this.geometries.forEach(geom => {
            if (!geom) {
                return;
            }
            const format = new WKT();

            const transformedGeom = format
                .readGeometry(geom)
                .transform('EPSG:4326', 'EPSG:3857');
            features.push(new olFeature({
                geometry: transformedGeom
            }));
        });

        const styles: Style[] = [
            new Style({ // polygon and line style
              fill: new Fill({
                  color: 'rgba(255, 0, 0, 0.3)'
              }),
              stroke: new Stroke({
                  color: 'black',
                  width: 3
              })
            }),
            new Style({
              image: new Circle({ // point style
                radius: 7,
                fill: new Fill({
                  color: 'rgba(255, 0, 0, 0.3)'
                }),
                stroke: new Stroke({
                  color: 'black',
                  width: 2,
                }),
              }),
              zIndex: Infinity,
            }),
          ];

        const vectorLayer = new VectorLayer({
            source: new VectorSource({
                features: features
            }),
            style: styles
        });
        const layerExtent = vectorLayer.getSource().getExtent();

        this.map = new Map({
            target: 'map',
            layers: baseLayers,
            view: defaultView
        });

        this.map.addLayer(vectorLayer);
        this.map.getView().fit(layerExtent, { size: this.map.getSize() });

        const layerSwitcher = new LayerSwitcher({ activationMode: 'click' });
        this.map.addControl(layerSwitcher);

        const scaleLine = new ScaleLine({
            units: 'metric',
            bar: true,
            steps: 5,
            text: true,
            minWidth: 120,
          });
          this.map.addControl(scaleLine);

        this.map.once('postrender',
            () => {
                this.map.updateSize();
                this.map.renderSync();
            }
        );

        this.map.on('rendercomplete',
            (event) => {
                const canvas = this.getMapCanvas();
                this.onCanvasRendered.emit(canvas.toDataURL('image/png'));
            });
    }

    private getMapCanvas(): any {
        const mapCanvas = document.createElement('canvas');
        const size = this.map.getSize();
        mapCanvas.width = size[0];
        mapCanvas.height = size[1];
        const mapContext = mapCanvas.getContext('2d');
        Array.prototype.forEach.call(
            document.querySelectorAll('.ol-layer canvas'),
            function (canvas) {
                if (canvas.width > 0) {
                    const opacity = canvas.parentNode.style.opacity;
                    mapContext.globalAlpha = opacity === '' ? 1 : Number(opacity);
                    const transform = canvas.style.transform;
                    // Get the transform parameters from the style's transform matrix
                    const matrix = transform
                        .match(/^matrix\(([^\(]*)\)$/)[1]
                        .split(',')
                        .map(Number);
                    // Apply the transform to the export map context
                    CanvasRenderingContext2D.prototype.setTransform.apply(
                        mapContext,
                        matrix
                    );
                    mapContext.drawImage(canvas, 0, 0);
                }
            }
        );
        return mapCanvas;
    }
}

import { Component, OnInit } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'info-dialog',
  templateUrl: './info-dialog.component.html',
  styleUrls: ['./info-dialog.component.scss']
})
export class InfoDialogComponent implements OnInit {

  infoMessage1: string;
  infoMessage2: string;
  infoMessage3: string;
  headerText: string;

  constructor(protected ref: NbDialogRef<void>) { }

  ngOnInit(): void {
  }

  dismiss() {
    this.ref.close();
  }

}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { ToFlyAuthority } from '../../models/to-fly-authority.model';

@Injectable({
  providedIn: 'root'
})
export class ToFlyAuthoritiesService {

  constructor(private http: HttpClient) { }

  getAllToFlyAuthorities(authorityId: number): Promise<ToFlyAuthority[]> {
    return this.http.get<ToFlyAuthority[]>('/api/v1/to-fly-authorities/' + authorityId).toPromise();
  }

  getAllToFlyAuthoritiesWithoutAuthorityId(): Promise<ToFlyAuthority[]> {
    return this.http.get<ToFlyAuthority[]>('/api/v1/to-fly-authorities/').toPromise();
  }

  addToFlyAuthority(toFlyAuthority: ToFlyAuthority): Promise<void> {
    return this.http.post<void>('api/v1/to-fly-authorities/', toFlyAuthority).toPromise();
  }

  editToFlyAuthority(toFlyAuthority: ToFlyAuthority): Promise<void> {
    return this.http.put<void>('api/v1/to-fly-authorities/' + toFlyAuthority.id, toFlyAuthority).toPromise();
  }
}

<nb-card size="medium">
  <nb-card-header>
    <span *ngIf="edit">Labot grupu</span>
    <span *ngIf="!edit">Pievienot grupu</span>
  </nb-card-header>
  <nb-card-body>

    <div>
      <form (ngSubmit)="submitGroup()"
            #form="ngForm"
            aria-labelledby="title">

        <div class="form-control-group">
          <label class="label">Nosaukums</label>
          <input nbInput
                 [(ngModel)]="group.name"
                 #groupName="ngModel"
                 name="groupName"
                 fullWidth
                 [status]="groupName.touched ? (groupName.invalid  ? 'danger' : 'success') : ''"
                 [required]="true"
                 [maxlength]="60"
                 [attr.aria-invalid]="groupName.invalid && groupName.touched ? true : null">
          <ng-container *ngIf="groupName.invalid && groupName.touched">
            <p class="error-message"
               *ngIf="groupName.errors?.required">
              Lauks ir obligāts!
            </p>
          </ng-container>
        </div>

        <div class="form-control-group">
          <label class="label">Apraksts</label>
          <textarea nbInput
                    [(ngModel)]="group.description"
                    #groupDescription="ngModel"
                    name="groupDescription"
                    fullWidth
                    [status]="groupDescription.touched ? (groupDescription.invalid  ? 'danger' : 'success') : ''"
                    [maxlength]="1000">
            </textarea>
        </div>

        <button nbButton
                fullWidth
                status="success"
                class="mt-3"
                [disabled]="!form.valid">
          Saglabāt
        </button>
      </form>
    </div>
  </nb-card-body>
  <nb-card-footer>
    <button nbButton
            hero
            status="primary"
            (click)="dismiss()">Aizvērt</button>
  </nb-card-footer>
</nb-card>
<table class="table table-light table-striped table-hover">
    <thead>
        <tr>
            <th>Nosaukums</th>
            <th>Serviss</th>
            <th>Kontaktpersona</th>
            <th>E-pasts</th>
            <th>Telefons</th>
            <th>Mājaslapa</th>
            <th *ngIf="authority.isAutoSubmitter">Datu grupa</th>
            <th>Darbības</th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let toFlyAuthority of toFlyAuthorities">
            <td>{{ toFlyAuthority.name }}</td>
            <td>{{ toFlyAuthority.service }}</td>
            <td>{{ toFlyAuthority.contactName }}</td>
            <td>{{ toFlyAuthority.email }}</td>
            <td>{{ toFlyAuthority.phone }}</td>
            <td>{{ toFlyAuthority.siteUrl }}</td>
            <td *ngIf="authority.isAutoSubmitter">{{ toFlyAuthority.dataGroupName }}</td>
            <td>
                <button *ngIf="toFlyAuthority.initiatingSystemId == 1701" class="btn btn-info btn-action p-2" nbTooltip="Labot kontaktinformāciju" (click)="editToFlyAuthority(toFlyAuthority)">
                    <i class="fa fa-edit"></i>
                </button>
            </td>
        </tr>
    </tbody>
</table>

import { Component, Input } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';
@Component({
  templateUrl: './map-modal.component.html',
  styleUrls: ['./map-modal.component.scss'],
})
export class MapModalComponent {

  @Input()
  geometries: string[];

  @Input()
  reportMode: boolean;

  constructor(protected dialogRef: NbDialogRef<MapModalComponent>) { }

  close(): void {
    this.dialogRef.close();
  }
}

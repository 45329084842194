<nb-card [style.width.px]="600">
    <nb-card-header>
        <h5 *ngIf="isEditMode">Labot kontaktinformāciju</h5>
        <h5 *ngIf="!isEditMode">Pievienot kontaktinformāciju</h5>
    </nb-card-header>
    <nb-card-body>
        <div>
            <form (ngSubmit)="submit()" #form="ngForm" aria-labelledby="title">
                <div class="form-control-group mt-3">
                    <label class="label" for="name">Nosaukums</label>
                    <input
                        nbInput
                        [(ngModel)]="toFlyAuthority.name"
                        #name="ngModel"
                        id="name"
                        name="name"
                        placeholder="Nosaukums"
                        fullWidth
                        maxlength="200"
                        [status]="name.touched ? (name.invalid ? 'danger' : 'success') : 'primary'"
                        [required]="true"
                        [attr.aria-invalid]="name.invalid && name.touched ? true : null"
                    />
                    <ng-container *ngIf="name.invalid && name.touched">
                        <p class="error-message" *ngIf="name.errors?.required">
                            Lauks ir obligāts!
                        </p>
                    </ng-container>
                </div>

                <div class="form-control-group mt-3">
                    <label class="label" for="service">Serviss</label>
                    <input
                        nbInput
                        [(ngModel)]="toFlyAuthority.service"
                        #service="ngModel"
                        id="service"
                        name="service"
                        placeholder="Serviss"
                        fullWidth
                        maxlength="200"
                        [status]="service.touched ? (service.invalid ? 'danger' : 'success') : 'primary'"
                        [attr.aria-invalid]="service.invalid && service.touched ? true : null"
                    />
                </div>

                <div class="form-control-group mt-3">
                    <label class="label" for="contactName">Kontaktpersona</label>
                    <input
                        nbInput
                        [(ngModel)]="toFlyAuthority.contactName"
                        #contactName="ngModel"
                        id="contactName"
                        name="contactName"
                        placeholder="Kontaktpersona"
                        fullWidth
                        maxlength="200"
                        [status]="contactName.touched ? (contactName.invalid ? 'danger' : 'success') : 'primary'"
                        [attr.aria-invalid]="contactName.invalid && contactName.touched ? true : null"
                    />
                    <ng-container *ngIf="contactName.invalid && contactName.touched">
                        <p class="error-message" *ngIf="contactName.errors?.required">
                            Lauks ir obligāts!
                        </p>
                    </ng-container>
                </div>

                <div class="form-control-group mt-3">
                    <label class="label" for="siteUrl">Mājaslapa</label>
                    <input
                        nbInput
                        [(ngModel)]="toFlyAuthority.siteUrl"
                        #siteUrl="ngModel"
                        id="siteUrl"
                        name="siteUrl"
                        placeholder="Mājaslapa"
                        fullWidth
                        maxlength="200"
                        [status]="siteUrl.touched ? (siteUrl.invalid ? 'danger' : 'success') : 'primary'"
                        [attr.aria-invalid]="siteUrl.invalid && siteUrl.touched ? true : null"
                    />
                </div>

                <div class="form-control-group mt-3">
                    <label class="label" for="email">E-pasts</label>
                    <input
                        nbInput
                        [(ngModel)]="toFlyAuthority.email"
                        #email="ngModel"
                        id="email"
                        name="email"
                        pattern=".+@.+..+"
                        placeholder="E-pasts"
                        fullWidth
                        maxlength="200"
                        [status]="email.touched ? (email.invalid ? 'danger' : 'success') : 'primary'"
                        [required]="true"
                        [attr.aria-invalid]="email.invalid && email.touched ? true : null"
                    />
                    <ng-container *ngIf="email.invalid && email.touched">
                        <p class="error-message" *ngIf="email?.errors?.required">
                            Lauks ir obligāts!
                        </p>
                        <p class="error-message" *ngIf="email?.errors?.pattern">
                            E-pasta adresei jābūt reālai!
                        </p>
                    </ng-container>
                </div>

                <div class="form-control-group mt-3">
                    <label class="label" for="phone">Telefons</label>
                    <input
                        nbInput
                        [(ngModel)]="toFlyAuthority.phone"
                        #phone="ngModel"
                        id="phone"
                        name="phone"
                        placeholder="Telefons"
                        fullWidth
                        maxlength="200"
                        [required]="true"
                        pattern="^((((\+371{1}((2{1})|(6{1}))[0-9]{7})|(((2{1})|(6{1}))[0-9]{7})))(\,)?|(\,\s)?)+$"
                        [status]="phone.touched ? (phone.invalid ? 'danger' : 'success') : 'primary'"
                        [attr.aria-invalid]="phone.invalid && phone.touched ? true : null"
                    />
                    <ng-container *ngIf="phone.invalid && phone.touched">
                        <p class="error-message" *ngIf="phone?.errors?.required">
                            Lauks ir obligāts!
                        </p>
                        <p class="error-message" *ngIf="phone?.errors?.pattern">
                            Telefona numuram jābūt reālam
                        </p>
                    </ng-container>
                </div>

                <div class="form-control-group mt-3" *ngIf="authority.isAutoSubmitter">
                    <label class="label" for="authorityDataGroup">Datu grupa</label>
                    <nb-select
                        [(ngModel)]="toFlyAuthority.dataGroupId"
                        #authorityDataGroup="ngModel"
                        status="primary"
                        fullWidth
                        name="authorityDataGroup"
                        [required]="true"
                        [status]="authorityDataGroup.touched ? (authorityDataGroup.invalid ? 'danger' : 'success') : 'primary'"
                        [attr.aria-invalid]="authorityDataGroup.invalid && authorityDataGroup.touched ? true : null"
                        >>
                        <nb-option *ngFor="let dataGroup of authorityDataGroups" [value]="dataGroup.id">{{ dataGroup.name }}</nb-option>
                    </nb-select>
                    <ng-container *ngIf="authorityDataGroup.invalid && authorityDataGroup.touched">
                        <p class="error-message" *ngIf="authorityDataGroup.errors?.required">
                            Lauks ir obligāts!
                        </p>
                    </ng-container>
                </div>

                <button nbButton fullWidth status="success" class="mt-3" [disabled]="!form.valid">
                    Saglabāt
                </button>
            </form>
        </div>
    </nb-card-body>
    <nb-card-footer>
        <button nbButton hero status="primary" (click)="dismiss()">Aizvērt</button>
    </nb-card-footer>
</nb-card>

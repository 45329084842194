import { Directive, Input } from '@angular/core';
import { AbstractControl, Validator, NG_VALIDATORS, ValidationErrors, FormControl } from '@angular/forms';

@Directive({
  selector: '[startEndTimeValidator]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: StartEndTimeValidatorDirective,
    multi: true
  }]
})
export class StartEndTimeValidatorDirective implements Validator {

  @Input('startEndTimeValidator')
  secondControl: AbstractControl;

  @Input()
  isStartTime: boolean;

  @Input()
  isEndTime: boolean;

  validate(control: AbstractControl<any, any>): ValidationErrors {
    if(control.value){
      if(this.isStartTime) {
        if(control.value >= this.secondControl.value) {
          return { 'startEndTimeInvalid': true };
        } else {
          if(this.secondControl.value) {
            this.secondControl.setErrors(null);
          }
        }
      }
  
      if(this.isEndTime) {
        if(control.value <= this.secondControl.value) {
          return { 'startEndTimeInvalid': true };
        } else {
          if(this.secondControl.value) {
            this.secondControl.setErrors(null);
          }
        }
      }
    }
    return null;
  }
}

import { Injectable } from '@angular/core';
import { MapModalComponent } from '@amdb/drone/shared/components/map-modal/map-modal.component';
import { NbDialogService } from '@nebular/theme';

@Injectable({
    providedIn: 'root'
})
export class MapModalService {

    constructor(private dialogService: NbDialogService) {
    }

    async open(...geometries: string[]): Promise<void> {
        const mapDialog = this.dialogService.open(MapModalComponent, { context: { geometries: geometries } });
        return await mapDialog.onClose.toPromise();
    }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SubmissionBufferGeom, SubmissionDetails } from '../../pages/submissions/submission.model';
import { saveAs } from 'file-saver';
import { SubmissionReportDetails } from '../../pages/submissions/submission-report.model';

import { XyCheckReport } from '../../pages/submissions/xy-check-report.model';
import { XyCheckResult } from '../../pages/submissions/xy-check-result.model';
import { XyCheck } from '../../pages/submissions/xy-check.model';
import { SaveDraftResult } from '../../pages/submissions/map/save-draft-result.model';

@Injectable({ providedIn: 'root' })
export class SubmissionsService {

    constructor(private httpClient: HttpClient) { }

    getDetails(submissionId: number): Promise<SubmissionDetails> {
        return this.httpClient.get<SubmissionDetails>(`/api/v1/submissions/${submissionId}/details`).toPromise();
    }

    // Commented out for now, might be needed back
    // getReportDetails(submissionId: number): Promise<SubmissionReportDetails> {
    //     return this.httpClient.get<SubmissionReportDetails>(`/api/v1/submissions/${submissionId}/report-details`).toPromise();
    // }

    uploadShpArchive(formData): Promise<any> {
        return this.httpClient.post('/api/v1/import', formData).toPromise();
    }

    uploadZoneFromMap(geomEwkt): Promise<any> {
        return this.httpClient.post('api/v1/import/from-map', geomEwkt).toPromise();
    }

    uploadZoneFromCoords(geomEwkt): Promise<any> {
        return this.httpClient.post('api/v1/import/from-coords', geomEwkt).toPromise();
    }

    saveDraft(submission: SubmissionDetails): Promise<SaveDraftResult> {
        return this.httpClient.post<SaveDraftResult>('/api/v1/submissions/new-draft', submission).toPromise();
    }

    deleteFailedDraftImportDirectory(directoryPath: string): Promise<void> {
        const replacedString = directoryPath.split('\\').join('&&');
        return this.httpClient.delete<void>(`/api/v1/import/failed/${replacedString}`).toPromise();
    }

    editDraft(submission: SubmissionDetails): Promise<void> {
        return this.httpClient.post<void>('/api/v1/submissions/edit-draft', submission).toPromise();
    }

    editSubmission(submission: SubmissionDetails): Promise<void> {
        return this.httpClient.post<void>('/api/v1/submissions/edit', submission).toPromise();
    }

    deleteDraft(submissionId: number): Promise<void> {
        return this.httpClient.delete<void>(`/api/v1/submissions/${submissionId}/draft`).toPromise();
    }

    changeStatus(action: string, submissionId: number, statusChange: FormData): Promise<void> {
        return this.httpClient.post<void>(`/api/v1/submissions/${submissionId}/${action}`, statusChange).toPromise();
    }

    downloadAttachment(submissionId: number, statusChangeHistoryId: number, attachmentName: string): Promise<void> {
        return this.httpClient.get<void>(`/api/v1/submissions/${submissionId}/${statusChangeHistoryId}/${attachmentName}`,
            { responseType: 'blob' as 'json', observe: 'response' })
            .toPromise()
            .then(res => {
                try {
                    const contentDisposition = res.headers.get('content-disposition');
                    const fileName = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
                    saveAs(res.body, fileName.replace(/^\"+|\"+$/g, ''));
                } catch {
                    console.error('Couldn\'t save file');
                }
            });
    }

    downloadOriginalfile(submissionId: number): Promise<void> {
        return this.httpClient.get<void>(`/api/v1/submissions/${submissionId}/original-file`,
            { responseType: 'blob' as 'json', observe: 'response' })
            .toPromise()
            .then(res => {
                try {
                    const contentDisposition = res.headers.get('content-disposition');
                    const fileName = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
                    saveAs(res.body, fileName.replace(/^\"+|\"+$/g, ''));
                } catch {
                    console.error('Couldn\'t save file');
                }
            });
    }

    exportGeoJson(approvedOnly: boolean, zones: boolean): Promise<void> {
        return this.httpClient.get<void>(`/api/v1/submissions/export-geojson/${approvedOnly}/${zones}`,
            { responseType: 'blob' as 'json', observe: 'response' })
            .toPromise()
            .then(res => {
                try {
                    const contentDisposition = res.headers.get('content-disposition');
                    const fileName = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
                    saveAs(res.body, fileName.replace(/^\"+|\"+$/g, ''));
                } catch {
                    console.error('Couldn\'t save file');
                }
            });
    }

    exportSubmissionList(onlyActive: boolean, disableableAuthorityId: number): Promise<void> {
        return this.httpClient.get(`/api/v1/submissions/submission-list-export/${onlyActive}/${disableableAuthorityId}`,
        { responseType: 'blob' as 'json', observe: 'response' })
        .toPromise()
        .then(res => {
            try {
                const contentDisposition = res.headers.get('content-disposition');
                const fileName = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
                saveAs(res.body, fileName.replace(/^\"+|\"+$/g, ''));
            } catch {
                console.error('Couldn\'t save file');
            }
        });
    }

    isOriginator(): Promise<boolean> {
        return this.httpClient.get<boolean>('/api/v1/submissions/is-originator').toPromise();
    }

    isPublisher(): Promise<boolean> {
        return this.httpClient.get<boolean>('/api/v1/submissions/is-publisher').toPromise();
    }

    hasToFlyAuthorities(): Promise<boolean> {
        return this.httpClient.get<boolean>('/api/v1/submissions/has-toFlyAuthorities').toPromise();
    }

    getGeoms(active: boolean, unapproved: boolean, inactive: boolean): Promise<SubmissionBufferGeom[]> {
        return this.httpClient.get<SubmissionBufferGeom[]>(`/api/v1/submissions/geoms/${active}/${unapproved}/${inactive}`).toPromise();
    }

    checkXy(xyCheckModels: XyCheck[]): Promise<XyCheckResult[]> {
        return this.httpClient.post<XyCheckResult[]>(`/api/v1/submissions/xy-check`, xyCheckModels).toPromise();
    }

    openXyCheckCsv(formData): Promise<XyCheck[]> {
        return this.httpClient.post<XyCheck[]>('/api/v1/submissions/xy-check/open-csv', formData).toPromise();
    }

    downloadPdf(reportData: XyCheckReport): Promise<void> {
        return this.httpClient.post<void>('/api/v1/submissions/xy-check/download-report', reportData, 
        { responseType: 'blob' as 'json', observe: 'response'})
        .toPromise()
        .then(res => {
            try {
                const contentDisposition = res.headers.get('content-disposition');
                const fileName = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
                saveAs(res.body, fileName.replace(/^\"+|\"+$/g, ''));
            } catch {
                console.error('Couldn\'t save file');
            }
        });
    }

    downloadCrc32QInputString(id: number): Promise<void> {
        return this.httpClient.get(`/api/v1/submissions/crc-input-string/${id}`,
        { responseType: 'blob' as 'json', observe: 'response' })
        .toPromise()
        .then(res => {
            try {
                const contentDisposition = res.headers.get('content-disposition');
                const fileName = contentDisposition.split(';')[1].split('filename')[1].split('=')[1].trim();
                saveAs(res.body, fileName.replace(/^\"+|\"+$/g, ''));
            } catch {
                console.error('Couldn\'t save file');
            }
        });
    }

    getAutoSubmissionCaaRACode(): Promise<any> {
        return this.httpClient.get('/api/v1/submissions/auto-ra-code').toPromise();
    }

    checkCaaaRestrictionAreaCode(caaRestrictionAreaCode: string): Promise<boolean> {
        return this.httpClient.post<boolean>(`/api/v1/submissions/ra-code-check`, { raCode: `${caaRestrictionAreaCode}`}).toPromise();
    }

    retrySendZoneToBgkis(submissionId: number): Promise<void> {
        return this.httpClient.post<void>(`/api/v1/submissions/retry-send/${submissionId}`, null).toPromise();
    }
}

<nb-card *ngIf="!reportMode"
         size="giant">
  <nb-card-header>Karte</nb-card-header>
  <nb-card-body>
    <amdb-map [reportMode]="reportMode"
              [geometries]="geometries"></amdb-map>
  </nb-card-body>
  <nb-card-footer>
    <button class="btn btn-success button-full"
            type="submit"
            (click)="close()">Aizvērt</button>
  </nb-card-footer>
</nb-card>
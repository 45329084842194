import { Directive, Input } from '@angular/core';
import { AbstractControl, Validator, NG_VALIDATORS, ValidationErrors } from '@angular/forms';

@Directive({
  selector: '[minValue]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: MinValueDirective,
    multi: true
  }]
})
export class MinValueDirective implements Validator {

  @Input('minValue')
  minValue: number;

  @Input()
  minCanBeEqual: boolean = false;

  validate(control: AbstractControl<any, any>): ValidationErrors {
    if(this.minCanBeEqual) {
      if(control.value < this.minValue) {
        return { 'minValue': true }
      } else {
        return null;
      }
    } else {
      if(control.value < this.minValue || control.value == this.minValue) {
        return { 'minValue': true }
      } else {
        return null;
      }
    }
  }
}
